/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { AppRepository } from "src/app/repositories/app.repository";
import { take } from "rxjs/operators";

@Pipe({
  name: "vpGetFullName",
  pure: false
})
export class GetFullNamePipe implements PipeTransform {
  constructor( private appRepository: AppRepository) {
  }

  transform(value: any): string {
    if (!value) {
      return "";
    }
    let fullName = value.split("@")[0];
    this.appRepository.getContactByEmail(value)
        .pipe(take(1)).subscribe(res => {
          if (res && res.name) {
            fullName = res.name;
          }
        });
    return fullName;
  }

}
