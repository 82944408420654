/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Store } from "@ngrx/store";
import { filter, take, takeUntil } from "rxjs/operators";
import { RootState } from "src/app/reducers";
import { SearchItem } from "../common/models/mail-models/search-item";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppRepository } from "../repositories/app.repository";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { AppService } from "src/app/services/app.service";
import { Subject } from "rxjs/internal/Subject";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MailUtils } from "src/app/common/utils/mail-utils";
import { environment } from "src/environments/environment";
// import { QuickPreviewComponent } from "../quick-preview/quick-preview.component";
// import { TaskPreviewComponent } from "../task-preview/task-preview.component";
// import { ChatPreviewComponent } from "../chat-preview/chat-preview.component";

@Component({
  selector: "vp-condensed-view",
  templateUrl: "./condensed-view.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CondensedViewComponent implements OnChanges, OnDestroy, OnInit {
  @Input() document: SearchItem;
  private isAlive$ = new Subject<boolean>();
  mailURL: string;
  serverURL: string;
  userFullName$: any;
  authorName: string;
  documentTitle: string;
  tagsCount: number;
  commentsCount: number;
  attachmentsCount: number;

  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef,
    private store: Store<RootState>,
    private matDialog: MatDialog,
    private appRepository: AppRepository,
    private broadcaster: Broadcaster,
    private appService: AppService,
    private snackBar: MatSnackBar) {
    this.tagsCount = 5;
    this.attachmentsCount = 6;
    this.commentsCount = 7;
  }

  ngOnInit() {
    this.broadcaster.on<any>("changestarvalue").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.changeDetectionRef.markForCheck();
    });
    this.appRepository.getFederatedApps().pipe(take(1)).subscribe(apps => {
      if (apps) {
        apps.forEach(app => {
          if (app.appKey === "mail") {
              const mail = app;
              if (!!mail) {
                  this.mailURL = mail.appUrl;
                  const isCordovaOrElectron = environment.isCordova || environment.isElectron;
                  if (this.mailURL.lastIndexOf("/") === this.mailURL.length - 1) {
                      this.serverURL = this.mailURL;
                  } else {
                      this.serverURL = this.mailURL + "/";
                  }
                  this.changeDetectionRef.markForCheck();
              }
          }
        });
      }
    });
  }

  ngOnChanges() {
    if (this.document) {
      this.authorName = this.document.from;
      this.documentTitle = this.document.title;

      if (this.document.type === "talk") {
        if (!this.userFullName$) {
          this.userFullName$ = this.appRepository.getContactByEmail(this.document.from)
            .pipe(filter(v => !!v), take(1)).subscribe(res => {
              if (res.name) {
                this.authorName = res.name;
                this.changeDetectionRef.markForCheck();
              }
            });
        }

        if (this.document.title.includes("@conference")) {
          const titleMinusProvider = this.document.title.split("@conference")[0];
          const titleMinusTalk = titleMinusProvider.split("_talk")[0];
          const titleMinusTalkToArray = titleMinusTalk.split("_");
          titleMinusTalkToArray.pop(); // Removes Unique Id
          this.documentTitle = titleMinusTalkToArray.join(" ");
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.userFullName$ && this.userFullName$.destroy) {
      this.userFullName$.destroy();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  openQuickPreview() {
 
    if (this.document.type === "talk") {
      if (this.document.chatType === "groupchat") {
        this.startConversation(this.document.title);
      } else {
        if (this.document.from !== this.appRepository.currentUser.defaultMail) {
          this.startConversation(this.document.from);
        } else {
          this.startConversation(this.document.to[0]);
        }
      }
    } else if (this.document.type === "mail") {
      this.quickPreview("view");
    } else if (this.document.type === "issue") {
      this.taskPreview();
    }
  }

  async startConversation(target) {
    const { ChatPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../chat-preview/chat-preview.component"
      );
    const dialogRef = this.matDialog.open(ChatPreviewComponent, {
      width: "660px",
      height: "625px",
      autoFocus: true,
      panelClass: "commander__dialog",
      data: target
    });
    dialogRef.afterClosed().subscribe((res: any) => {
     
    });
  }

  async quickPreview(action: string) {
    const { QuickPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../quick-preview/quick-preview.component"
      );
    const dialogRef = this.matDialog.open(QuickPreviewComponent, {
      width: "660px",
      height: "625px",
      autoFocus: true,
      panelClass: "quick_preview_dialog",
      data: { document: this.document, action: action }
    });
    const detectChange = this.broadcaster.on("documentChange").subscribe((document: any) => {
      this.document = document;
      this.changeDetectionRef.markForCheck();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      detectChange.unsubscribe();
   
    });
  }

  async taskPreview() {
    const { TaskPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../task-preview/task-preview.component"
      );
    const dialogRef = this.matDialog.open(TaskPreviewComponent, {
      width: "660px",
      height: "625px",
      autoFocus: true,
      panelClass: "quick_preview_dialog",
      data: { document: this.document }
    });
    const detectChange = this.broadcaster.on("documentChange").subscribe((document: any) => {
      this.document = document;
      this.changeDetectionRef.markForCheck();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      detectChange.unsubscribe();
     
    });
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  markAsRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "read" };
      this.appRepository.messageAction(body).subscribe(res => {
        this.document.unread = false;
        this.changeDetectionRef.markForCheck();
      });
    } else {
        this.underDevelopment();
      }
  }

  markAsUnRead() {
      if (this.document.type === "mail") {
        const body = { id: this.document.id, op: "!read" };
        this.appRepository.messageAction(body).subscribe(res => {
          this.document.unread = true;
          this.changeDetectionRef.markForCheck();
        });
      } else {
          this.underDevelopment();
      }
  }

  addStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  removeStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "!flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  filePreview(attachment) {
    let link = this.serverURL + "api/getAttachment/?" + "id=" + this.document.id + "&part=" + attachment.part;
    link = CommonUtils.addTokenToLink(link);
    if (environment.isElectron) {
      // ElectronService.downloadFile(link, attachment.part);
    } else {
      window.open(link, "_blank");
    }
  }

  downloadAllAttachments(attachmentList: any[]) {
    let attachmentsId = MailUtils.getAttachmentIds(attachmentList).toString();
    let link = this.serverURL + "api/downloadAttachments/?" + "id=" + this.document.id
    + "&part=" + attachmentsId + "&filename=" + this.document.title;
    link = CommonUtils.addTokenToLink(link);
    if (environment.isElectron) {
      // ElectronService.downloadFile(link, fileName);
    } else {
      window.open(link, "_system");
    }
  }

  renderContent(content) {
    return wdtEmojiBundle.render(content).replace(/\r?\n/g, "\\n")
      .replace(/\\n/g, "<br />").replace(/[<]br[^>]*[>]/gi, "");
  }
}
