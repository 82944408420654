<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="filters-sidebar">
<!--    <div class="filters-sidebar__header">-->
<!--        <div class="search-heading">-->
<!--            <button mat-button (click)="$event.stopPropagation();underDevelopment()">-->
<!--                <mat-icon fontSet="mdi" fontIcon="mdi-content-save"></mat-icon>-->
<!--                <span>{{'SAVE_SEARCH' | translate}}</span>-->
<!--            </button>-->
<!--            <button *ngIf="searchMode === 'basic'" mat-button (click)="$event.stopPropagation();toggleSearch('advanced')">-->
<!--                <span>{{'BASIC_SEARCH' | translate}}</span>-->
<!--            </button>-->
<!--            <button *ngIf="searchMode === 'advanced'" mat-button (click)="$event.stopPropagation();toggleSearch('basic')">-->
<!--                <span>{{'ADVANCED_SEARCH' | translate}}</span>-->
<!--            </button>-->
<!--        </div>       -->
<!--    </div>-->
    <div class="filters-sidebar__body">
      <div #advancedForm class="advanced-search-form">
        <button mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
        <input matInput type="text" [formControl]="searchKeyword" (keyup.enter)="search()"
               placeholder="{{'HEADER_SEARCH_PLACEHOLDER' | translate}}" />
        <button (click)="$event.stopPropagation();clearSearch()" mat-icon-button>
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div class="search-options">
        <div class="search-group">
          <div class="search-group__heading">{{'FILTERS' | translate}}</div>

          <div class="filter-option">
            <label>{{ 'GLOBAL' | translate }}</label>
            <mat-checkbox [formControl]="showOnlyUnreadCtrl">
              {{'SHOW_ONLY_UNREAD' | translate}}
            </mat-checkbox>
<!--            <mat-checkbox [formControl]="starredOnlyCtrl">-->
<!--              {{'STARRED_ONLY' | translate}}-->
<!--            </mat-checkbox>-->
<!--            <mat-checkbox [formControl]="hasAttachmentsCtrl">-->
<!--              {{'HAS_ATTACHMENTS' | translate}}-->
<!--            </mat-checkbox>-->
          </div>

          <div class="filter-option tags">
            <label>{{ 'TAGS' | translate }}</label>
            <mat-form-field floatLabel=never>
              <mat-label>{{ 'SELECT_TAG' | translate }}</mat-label>
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let tag of tags"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="tagRemoved(tag)">
                  <mat-icon fontSet = "mdi" fontIcon = "mdi-tag"
                            [style.color]="tag.color || '#607d8b'"></mat-icon>
                  {{tag.name}}
                  <mat-icon matChipRemove>clear</mat-icon>
                </mat-chip>
                <input
                  #tagInput
                  [formControl]="tagCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
              </mat-chip-list>
              <mat-autocomplete class="advanced-search-dialog__tags" autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
                <mat-option *ngFor="let tag of filteredTags" [value]="tag">
                  <mat-icon fontSet = "mdi" fontIcon = "mdi-tag"
                            [style.color]="tag.color || '#607d8b'"></mat-icon>
                  {{tag.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="filter-option">
            <label>{{ 'DATE_RANGE' | translate }}</label>
            <vp-datepicker
              [lang]="lang"
              [selected]="selected"
              [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="true"
              [showClearButton]="true"
              [showCancel]="true"
              [linkedCalendars]="true"
              [keepCalendarOpeningWithRange]="true"
              [showRangeLabelOnInput]="true"
              [showAllAsDefault]="showAllAsDefault"
              [placeholder]="'SHOW_ALL' | translate"
              (datesUpdatedEvent)="datesUpdated($event)"
              (resetDateFilterEvent)="resetDateFilter()"
            ></vp-datepicker>
          </div>
        </div>


        <div class="search-group">
          <div class="search-group__heading">{{'ADVANCED_SEARCH' | translate}}</div>
          <div fxLayout="row" fxLayoutGap="20px">
            <div class="search-group_apps">
              <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="talkFiltersOpen = true"
                                     [ngClass]="isTalkFiltersSelected() ? 'selected' : ''"
                                     (closed)="talkFiltersOpen = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'VNCTALK' | translate}}
                    </mat-panel-title>

                    <button *ngIf="talkFiltersOpen && isTalkFiltersChange" mat-button class="clear-button"
                            (click)="$event.stopPropagation();this.resetTalkFilters()">
                      <mat-icon> clear </mat-icon>
                      <span>{{'CLEAR' | translate}}</span>
                    </button>

                    <mat-icon *ngIf="!talkFiltersOpen" fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
                    <mat-icon *ngIf="talkFiltersOpen" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
                  </mat-expansion-panel-header>

                  <form [formGroup]="talkFilters">
                    <div class="expanded__app__filters">
                      <mat-checkbox formControlName="insideAttachments" class="expanded__app__filters__option">
                        {{'INSIDE_ATTACHEMENTS' | translate}}
                      </mat-checkbox>
<!--                      <mat-checkbox formControlName="insideTickets" class="expanded__app__filters__option">-->
<!--                        {{'INSIDE_TICKETS' | translate}}-->
<!--                      </mat-checkbox>-->

                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'SENDER' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="contacts"
                            [allValueLabel]="'ALL_USERS'"
                            [multiCtrl]="talkFilters.controls['sender']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('talk', 'sender')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="taskFiltersOpen = true"
                                     [ngClass]="isTaskFiltersSelected() ? 'selected' : ''"
                                     (closed)="taskFiltersOpen = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'VNCTASK' | translate}}
                    </mat-panel-title>

                    <button *ngIf="taskFiltersOpen && isTaskFiltersChange" mat-button class="clear-button"
                            (click)="$event.stopPropagation();this.resetTaskFilters()">
                      <mat-icon> clear </mat-icon>
                      <span>{{'CLEAR' | translate}}</span>
                    </button>

                    <mat-icon *ngIf="!taskFiltersOpen" fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
                    <mat-icon *ngIf="taskFiltersOpen" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
                  </mat-expansion-panel-header>

                  <form [formGroup]="taskFilters">
                    <div class="expanded__app__filters">
                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'ASSIGNED_TO' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="contacts"
                            [allValueLabel]="'ALL_USERS'"
                            [multiCtrl]="taskFilters.controls['assignedTo']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('task', 'assignedTo')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>

<!--                      <div class="expanded__app__filters__option select-multiple">-->
<!--                        <label>{{ 'PROJECT' | translate }}</label>-->
<!--                        <div class="expanded__app__filters__option__wrapper">-->
<!--                          <vp-multi-filter-with-search-->
<!--                            [options]="projects"-->
<!--                            [allValueLabel]="'ALL_PROJECTS'"-->
<!--                            [multiCtrl]="taskFilters.controls['project']">-->
<!--                          </vp-multi-filter-with-search>-->
<!--                          <button mat-icon-button class="clear-button"-->
<!--                                  (click)="$event.stopPropagation();clearField('task', 'project')">-->
<!--                            <mat-icon> clear </mat-icon>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->

                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'STATUS' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="statuses"
                            [allValueLabel]="'ANY_STATUS'"
                            [multiCtrl]="taskFilters.controls['status']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('task', 'status')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>

                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'PRIORITY' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="priorities"
                            [allValueLabel]="'ANY_PRIORITY'"
                            [multiCtrl]="taskFilters.controls['priority']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('task', 'priority')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-expansion-panel>
              </mat-accordion>

              <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="mailFiltersOpen = true"
                                     [ngClass]="isMailFiltersSelected() ? 'selected' : ''"
                                     (closed)="mailFiltersOpen = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'VNCMAIL' | translate}}
                    </mat-panel-title>

                    <button *ngIf="mailFiltersOpen && isMailFiltersChange" mat-button class="clear-button"
                            (click)="$event.stopPropagation();this.resetMailFilters()">
                      <mat-icon> clear </mat-icon>
                      <span>{{'CLEAR' | translate}}</span>
                    </button>

                    <mat-icon *ngIf="!mailFiltersOpen" fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
                    <mat-icon *ngIf="mailFiltersOpen" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
                  </mat-expansion-panel-header>

                  <form [formGroup]="mailFilters">
                    <div class="expanded__app__filters">
                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'RECEIVED_FROM' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="contacts"
                            [allValueLabel]="'ALL_USERS'"
                            [multiCtrl]="mailFilters.controls['receivedFrom']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('mail', 'receivedFrom')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>

                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'SENT_TO' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="contacts"
                            [allValueLabel]="'ALL_USERS'"
                            [multiCtrl]="mailFilters.controls['sentTo']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('mail', 'sentTo')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>

<!--                      <div class="expanded__app__filters__option select-multiple">-->
<!--                        <label>{{ 'ATTACHMENT' | translate }}</label>-->
<!--                        <div class="expanded__app__filters__option__wrapper">-->
<!--                          <vp-multi-filter-with-search-->
<!--                            [options]="attachmentTypes"-->
<!--                            [allValueLabel]="'ALL_TYPES'"-->
<!--                            [multiCtrl]="mailFilters.controls['attachmentType']">-->
<!--                          </vp-multi-filter-with-search>-->
<!--                          <button mat-icon-button class="clear-button"-->
<!--                                  (click)="$event.stopPropagation();clearField('mail', 'attachmentType')">-->
<!--                            <mat-icon> clear </mat-icon>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->

                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'STATUS' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="mailStatuses"
                            [allValueLabel]="'ANY_STATUS'"
                            [multiCtrl]="mailFilters.controls['status']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('mail', 'status')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>

                      <div class="expanded__app__filters__option select-multiple">
                        <label>{{ 'FOLDER' | translate }}</label>
                        <div class="expanded__app__filters__option__wrapper">
                          <vp-multi-filter-with-search
                            [options]="mailFolders"
                            [allValueLabel]="'ALL_FOLDERS'"
                            [multiCtrl]="mailFilters.controls['folder']">
                          </vp-multi-filter-with-search>
                          <button mat-icon-button class="clear-button"
                                  (click)="$event.stopPropagation();clearField('mail', 'folder')">
                            <mat-icon> clear </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filters-sidebar__footer has-buttons">
        <div class="footer-actions">
            <button mat-button (click)="setDefaultOptions()">{{ 'CANCEL' | translate }}</button>
            <button mat-button (click)="search()">{{ 'SEARCH' | translate }}</button>
        </div>
    </div>
</div>
