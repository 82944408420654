
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export interface Message {
  id: string; // Unique identifier
  isForwarded?: boolean; // uncertain for future use.

  timestamp: number;
  delay?: any;
  body: string;
  messagetext?: string;
  target?: string;
  attachment?: {
    fileName: string,
    fileSize: number,
    fileType: string,
    url: string,
  };
  location?: {
    lat: string,
    lng: number,
    label: string,
    address: string,
  };
  replace?: {
    id: string
  };
  muc?: any;
  vncTalkConference?: VNCTalkConference;
  vncTalkMuc?: VNCTalkMuc;
  originalMessage?: OriginalMessage;
  forwardMessage?: ForwardMessage;
  startFile?: {
    type: string
  };
  mucInvite?: any;
  carbon?: boolean;
  type?: string; // "chat"/"groupchat"/"CHAT.JOIN"/"CHAT.KICK"/"CHAT.LEAVE"
  from?: any;
  fromJid?: string;
  to?: any;
  nick?: string; // TODO: remove later
  isDeleted?: boolean;
  isStarred?: boolean;
  file?: any;
  fileNameOnDisk?: any;
  uploadFileInProgress?: boolean;
  chatState?: string; // "inactive", "paused", "composing"
  pendingIn?: string; // conversationTarget. appending by frontend to maintain pending messages in store
  tags?: string[];
  // appending by frontend. true indicates that message has been delivered. If null treat status as DELIVERED
  status?: MessageStatus;
  receipt?: string;
  pendingMessage?: any; // conversationTarget. appending by frontend to maintain pending messages in store
  requestReceipt?: boolean;
  checkedVideo?: boolean;
  sendingAt?: number;
  references?: {
    begin?: string,
    end?: string,
    type: string,
    uri: string
  }[];
  vncTalkBroadcast?: VNCTalkBroadcast;
  broadcast?: string;
  sort_id?: number;
  broadcast_id?: string;
  broadcast_title?: string;
  broadcast_owner?: string;
  broadcastSender?: string;
  renderedContent?: any;
  renderedContentWithHighlight?: any;
  cachedContent?: string;
  htmlBody?: string;
  html?: {
    body: string
  };
  urlPreviewData?: {
    background?: string,
    title?: string,
    description?: string,
  };
  data?: any;
}

export interface VNCTalkConference {
  id?: string;
  from: string;
  to: string;
  conferenceId: string;
  oldConferenceId?: string;
  jitsiRoom?: string;
  jitsiXmppUrl?: string;
  jitsiXmppPort?: string;
  jitsiURL?: string;
  reason?: string;
  conferenceType: string;
  duration?: string;
  eventType: string;
  timestamp?: string;
}

export interface VNCTalkBroadcast {
  to: string[];
  roster?: string[];
  title?: string;
  origtarget?: string;
  broadcastAudienceId?: string;
}

export interface VNCTalkMuc {
  id?: string;
  from: string;
  to: string;
  conferenceId: string;
  eventType: string;
}

export interface OriginalMessage {
  id: string;
  from: string;
  body: string;
  htmlBody?: string;
  attachment?: any;
  location?: string;
  replyMessage: string;
  broadcast_title?: string;
  broadcast_owner?: string;
  broadcast_id?: string;
  timestamp?: number;
}

export interface ForwardMessage {
  id?: string;
  from: string;
  timestamp?: number;
}
export enum MessageStatus {
  PENDING,
  SENDING,
  SENT,
  DELIVERED,
  FAILED
}
