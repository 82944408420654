/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";

const FILE_SIZE_UNITS = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
const FILE_SIZE_UNITS_LONG = ["Bytes", "Kilobytes", "Megabytes", "Gigabytes", "Pettabytes", "Exabytes", "Zettabytes", "Yottabytes"];

@Pipe({
  name: "vpFormatFileSize"
})
export class FormatFileSizePipe implements PipeTransform {

  transform(sizeInBytes: number, longForm: boolean): string {
    if (sizeInBytes === 0) {
      return "0 B";
    }
    const decimals = 2;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
    return Math.round(parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(dm))) + " " + sizes[i];
  }

}
