/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { Group } from "../common/models/ldap-contact.model";

export class GroupActionTypes {
  static GROUP_LOAD_REQUEST = "[GROUP] Load Request";
  static GROUP_LOAD_SUCCESS = "[GROUP] Load Success";

  static GROUP_ADD = "[GROUP] Add";
  static GROUP_DELETE = "[GROUP] Delete";
  static GROUP_UPDATE = "[GROUP] Update";
  static GROUP_BULK_ADD = "[GROUP] Bulk Add";
}

export class GroupLoadRequest implements Action {
  readonly type = GroupActionTypes.GROUP_LOAD_REQUEST;
}

export class GroupLoadSuccess implements Action {
  readonly type = GroupActionTypes.GROUP_LOAD_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class GroupAdd implements Action {
  readonly type = GroupActionTypes.GROUP_ADD;

  constructor(public payload: Group) {
  }
}

export class GroupUpdate implements Action {
  readonly type = GroupActionTypes.GROUP_UPDATE;

  constructor(public payload: Group) {
  }
}

export class GroupDelete implements Action {
  readonly type = GroupActionTypes.GROUP_DELETE;

  constructor(public payload: number) {
  }
}

export class GroupBulkAdd implements Action {
  readonly type = GroupActionTypes.GROUP_BULK_ADD;

  constructor(public payload: Group[]) {
  }
}
