
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Moment } from "moment";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";

@Component({
  selector: "vp-datepicker",
  templateUrl: "./datepicker.html"
})
export class VNCDatePickerComponent implements OnInit {
  private _lang = "en";

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  @Input() set lang(lang: string) {
    this._lang = lang;
    moment.locale(lang);
    this.setRanges();
    this.setLocale();
    let start = this.selected?.startDate;
    let end = this.selected?.endDate;
    setTimeout(() => {
      this.selected = {
        startDate: start,
        endDate: end
      };
    }, 300);
  }

  get lang() {
    return this._lang;
  }

  private _selected: {startDate: Moment, endDate: Moment} = null;

  @Input() set selected(selected: {startDate: Moment, endDate: Moment}) {
    if (!!selected?.startDate || !!selected?.endDate) {
      this._selected = selected;
      this.checkIfDefaultDate(selected);
    }
  }
  get selected() {
    return this._selected;
  }

  @Input() showCustomRangeLabel = true;
  @Input() alwaysShowCalendars: boolean;
  @Input() showClearButton = true;
  @Input() showCancel = true;
  @Input() linkedCalendars = true;
  // @Input() isTooltipDate: any;
  // @Input() isInvalidDate: any;
  @Input() keepCalendarOpeningWithRange: boolean;
  @Input() showRangeLabelOnInput: boolean;
  @Input() placeholder: string;
  @Input() set showAllAsDefault(showAllAsDefault: boolean) {
    
    if (showAllAsDefault) {
      setTimeout(() => {
        this.pickerDirective.clear();
      }, 1000);

    }
  }

  @Output() datesUpdatedEvent = new EventEmitter<any>();
  @Output() resetDateFilterEvent = new EventEmitter<any>();

  ranges: any;
  locale: any;
  isDefaultDate: boolean;
  tooltips = [
    {date: moment(), text: "Today is just unselectable"},
    {date:  moment().add(2, "days"), text: "Yeeeees!!!"}
  ];
  invalidDates: any[] = [];
  showAllActivated = false;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
  }

  resetDateFilter() {
    this.isDefaultDate = true;
    this.resetDateFilterEvent.emit();
    this._selected = null;
  }

  datesUpdated(range: any) {
    if (!range.startDate && !range.endDate) {
      if (this.showAllActivated) {
        this.checkIfDefaultDate(range);
        this.datesUpdatedEvent.emit(range);
      } else {
        this.showAllActivated = true;
      }
    } else {
      this.checkIfDefaultDate(range);
      this.datesUpdatedEvent.emit(range);
    }
  }

  checkIfDefaultDate(range: any) {
   
    if (!range || (!range?.startDate && !range?.endDate)) {
      this.isDefaultDate = true;
      return true;
    } else {
      this.isDefaultDate = false;
    }

  }

  setLocale() {
    this.translate.get(["CUSTOM_RANGE"])
      .pipe(take(1)).subscribe((res: any) => {
      this.locale = {
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData().firstDayOfWeek(),
        applyLabel: "",
        cancelLabel: "",
        clearLabel: "",
        customRangeLabel: res.CUSTOM_RANGE,
        displayFormat: "DD. MMM",
        weekLabel: "W"
      };
    });
  }

  setRanges() {
    this.translate.get(["TODAY", "YESTERDAY", "THIS_WEEK", "LAST_WEEK", "THIS_MONTH", "LAST_MONTH", "LAST_THREE_MONTH", "CUSTOM_RANGE"])
      .pipe(take(1)).subscribe((res: any) => {
      
      this.ranges = {};
      this.ranges[res.TODAY] = [moment(), moment()];
      this.ranges[res.YESTERDAY] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
      this.ranges[res.THIS_WEEK] = [moment().subtract(6, "days"), moment()];
      this.ranges[res.THIS_MONTH] = [moment().startOf("month"), moment().endOf("month")];
      this.ranges[res.LAST_MONTH] = [moment().subtract(30, "days"), moment()];
      this.ranges[res.LAST_THREE_MONTH] = [moment().subtract(90, "days"), moment()];
    });
  }

  isInvalidDate(m: any) {
    if (!this.invalidDates) {
      return false;
    }
    return this.invalidDates.some(d => d.isSame(m, "day") );
  }

  isTooltipDate(m: any) {
    if (!this.tooltips) {
      return false;
    }
    const tooltip = this.tooltips.find(tt => tt.date.isSame(m, "day"));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  }

}
