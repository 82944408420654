
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {Component, OnInit, NgZone, OnDestroy, ChangeDetectorRef} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ConfigService } from "src/app/config.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MobileHelpDialogComponent } from "src/app/shared/components/mobile-help-dialog/mobile-help-dialog.component";
import {AppRepository} from "../../../repositories/app.repository";

@Component({
  selector: "vp-mobile-searches-dialog",
  templateUrl: "./mobile-searches-dialog.component.html"
})
export class MobileSearchesDialogComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  hideFAQMenu: boolean = false;
  hideServiceDeskMenu: boolean = false;
  hideLegalNoticeMenu: boolean = false;
  serviceDeskMenuItemName: string = "VNC Service Desk";

  savedSearches: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<MobileSearchesDialogComponent>,
    private matDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private appRepository: AppRepository,
    private ngZone: NgZone,
  ) {
    // this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ABOUT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
    //   this.ngZone.run(() => {
    //     this.close();
    //   });
    // });
  }

  ngOnInit() {
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }

    this.appRepository.getSavedSearches().subscribe(
      res => {
        this.savedSearches = res;
        this.changeDetectorRef.markForCheck();
       
      }
    );
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  applySearch(search) {
    
    this.appRepository.underDevelopment();
  }

  // profileDialog(): void {
  //   // this.matDialog.open(AboutDialogComponent, {
  //   //   maxWidth: "100%",
  //   //   autoFocus: false,
  //   //   panelClass: "commander__dialog"
  //   // });
  // }
  //
  // generalSettingsDialog(): void {
  // }
  //
  // preferencesDialog(): void {
  // }
  //
  // helpDialog(): void {
  //   this.matDialog.open(MobileHelpDialogComponent, {
  //     maxWidth: "100%",
  //     autoFocus: false,
  //     panelClass: "commander__dialog"
  //   });
  // }
}
