
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="commander_autocomplete">
  <mat-form-field class="commander-chip-list">
    <mat-chip-list #chipList>
      <mat-chip cdkDropList *ngFor="let emailItem of emails" [selectable]="selectable" [removable]="removable"
        (removed)="remove(emailItem)">
        <div class="autocomplete-participant">
          <vp-avatar [type]="'mail-chip-avatar'" [user]="{email: emailItem.email}">
          </vp-avatar>
          <span class="participant-name">{{emailItem.name}}</span>
          <mat-icon matChipRemove *ngIf="removable" class="disable-select">close</mat-icon>
        </div>
      </mat-chip>
      <input matInput placeholder="{{ 'ENTER_CONTACT_OR_EMAIL' | translate }}" #emailInput [formControl]="emailCtrl"
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur" (focus)="onFocusEvent()" (matChipInputTokenEnd)="add($event)"
        (input)="onInputEvent($event)" (keydown)="onKeyDown($event)" id="email-add-input">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="commander_autocomplete_panel"
      [autoActiveFirstOption]=true>
      <mat-option *ngFor="let emailItem of filteredEmails" [value]="emailItem">
        <div class="suggestion-item">
          <vp-avatar [type]="'autocomplete-avatar'" [user]="{email: emailItem.email}">
          </vp-avatar>
          <div class="email_name_division">
            <h3 class="disable-select">{{emailItem.name}}</h3>
            <p class="disable-select">{{emailItem.email}}</p>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>