/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MessageActionTypes } from "../actions/message";
import { Message, MessageStatus } from "../common/models/message.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ConversationActionTypes } from "../actions/conversation";
import { AppActionTypes } from "../actions/app";
import { Action } from "../actions";

export interface MessageState extends EntityState<Message> {
  messageToReply: Message;
}

export const messageAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  selectId: (message: Message) => message.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(message1: Message, message2: Message): number {
  return message1.timestamp - message2.timestamp;
}

export const initialState: MessageState = messageAdapter.getInitialState({
  messageToReply: null
});

export function messageReducer(state: MessageState = initialState, action: Action): MessageState {
  switch (action.type) {

    case MessageActionTypes.MESSAGE_ADD: {
      const message = action.payload.message;
      const newState = messageAdapter.addOne(message, state);
      return messageAdapter.updateOne({ id: message.id, changes: message }, newState);
    }

    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD: {
      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const message = item.message;
        localState = messageAdapter.addOne(message, localState);
        localState = messageAdapter.updateOne({ id: message.id, changes: message }, localState);

      });

      return localState;
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND: {
      const messages = action.payload.messages
        .map(message => {
          return {
            ...message
          };
        });
      return messageAdapter.addMany(messages, state);
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let combinedMessages = [];
      data.forEach(item => {
        const messages = item.messages
          .map(message => {
            return {
              ...message
            };
          });
        combinedMessages.push(...messages);
      });

      return messageAdapter.addMany(combinedMessages, state);

    }

    case MessageActionTypes.MESSAGE_DELETE_ACTION: {
      return messageAdapter.removeOne(action.payload, state);
    }

    case MessageActionTypes.MESSAGE_STATUS_UPDATE: {
      return messageAdapter.updateOne({ id: action.payload.id, changes: { status: action.payload.status } }, state);
    }

    case MessageActionTypes.MESSAGE_UPDATE: {
      return messageAdapter.updateOne({ id: action.payload.id, changes: action.payload.changes }, state);
    }

    case MessageActionTypes.MESSAGE_STATUS_UPDATE_DELIVERED: {
      const ids = action.payload;
      let localState = { ...state };
      ids.forEach(id => {
        localState = messageAdapter.updateOne({
          id: id,
          changes: { status: MessageStatus.DELIVERED }
        }, localState);
      });
      return localState;
    }

    case MessageActionTypes.MESSAGE_DELETED_STATUS_UPDATE: {
      return messageAdapter.updateOne({
        id: action.payload.id,
        changes: { isDeleted: action.payload.isDeleted, body: null }
      }, state);
    }

    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_DELETED_STATUS_UPDATE: {

      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        localState = messageAdapter.updateOne({
          id: item.id,
          changes: { isDeleted: item.isDeleted, body: null }
        }, localState);
      });
      return localState;
    }

    case MessageActionTypes.MESSAGE_FAVORITE_UPDATE: {
      return messageAdapter.updateOne({
        id: action.payload.id,
        changes: { isStarred: action.payload.isStarred }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_DELETE: {
      const ids = messageAdapter.getSelectors().selectAll(state)
        .filter(m => m.status === MessageStatus.PENDING && m.pendingIn === action.payload)
        .map(m => m.id);
      return messageAdapter.removeMany(ids, state);
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.messageState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case MessageActionTypes.MESSAGE_BULK_DELETE: {
      return messageAdapter.removeMany(action.payload.messageIds, state);
    }

    default: {
      return state;
    }
  }
}
