/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { MailUtils } from "src/app/common/utils/mail-utils";
import { environment } from "src/environments/environment";

// import { MailService } from "../shared/services/mail-service";

@Component({
  selector: "vp-mail-attachment",
  templateUrl: "./mail-attachment.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailAttachmentComponent implements OnInit {

  @Input() attachmentList: any[];
  @Input() msgId: string;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    // private mailService: MailService
  ) { }

  ngOnInit() {
  }

  getFileTypeExtension(contentType: string): string {
    return MailUtils.getFileTypeExtension(contentType);
  }

  removeAttachment(index: number): void {
    this.attachmentList.splice(index, 1);
    this.changeDetectionRef.markForCheck();
  }

  trackByIndex(index: number, value: any) {
    return index;
  }

  openAttachment(attachment: any): void {
    if (attachment && attachment.ct === "message/rfc822" && !environment.isCordova && !environment.isElectron) {
        this.openSeprateWindow(attachment, this.msgId);
    } else {
        // this.mailService.previewAttachment(this.msgId, attachment.part);
    }
}

private openSeprateWindow(attachment: any, id: string): void {
    window.open(
        window.location.origin + "/mail/launchNewDetailWindow/" + id + "/" + attachment.part,
        "",
        "toolbar=no,scrollbars=yes,resizable=yes,left=500,width=" + (window.innerWidth - 250) + ",height=600"
    );
}

}
