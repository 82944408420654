/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { CalendarEvent, MonthViewDay } from "calendar-utils";
import { DateAdapter } from "../date-adapters/date-adapter";
import { trackByEventId } from "../common/util";
import { PlacementArray } from "positioning";
import * as moment from "moment-timezone";

@Component({
  selector: "vp-calendar-mini-cell",
  template: `
      <ng-template
        #defaultTemplate
        let-day="day"
        let-openDay="openDay"
        let-locale="locale"
        let-tooltipPlacement="tooltipPlacement"
        let-highlightDay="highlightDay"
        let-unhighlightDay="unhighlightDay"
        let-eventClicked="eventClicked"
        let-tooltipTemplate="tooltipTemplate"
        let-tooltipAppendToBody="tooltipAppendToBody"
        let-tooltipDelay="tooltipDelay"
        let-trackByEventId="trackByEventId"
      >
        <div class="cal-cell-top">
          <span class="cal-day-number-bg"></span>
          <div class="cal-day-number">{{
            day.date | vpCalendarDate: "miniViewDayNumber":locale
          }}</div>
        </div>
      </ng-template>
      <ng-template
        [ngTemplateOutlet]="customTemplate || defaultTemplate"
        [ngTemplateOutletContext]="{
          day: day,
          openDay: openDay,
          locale: locale,
          tooltipPlacement: tooltipPlacement,
          highlightDay: highlightDay,
          unhighlightDay: unhighlightDay,
          eventClicked: eventClicked,
          tooltipTemplate: tooltipTemplate,
          tooltipAppendToBody: tooltipAppendToBody,
          tooltipDelay: tooltipDelay,
          trackByEventId: trackByEventId
        }">
      </ng-template>
    `
})
export class CalendarMiniCellComponent implements OnInit, OnDestroy {
  @HostBinding("class.cal-cell") defaultCallCellClass: boolean = true;
  @HostBinding("class.cal-day-cell") defaultCallDayCellClass: boolean = true;
  @HostBinding("class.cal-past") defaultCallPastClass: boolean = false;
  @HostBinding("class.cal-today") defaultCallTodayClass: boolean = false;
  @HostBinding("class.cal-future") defaultCallFutureClass: boolean = false;
  @HostBinding("class.cal-weekend") defaultCallWeekendClass: boolean = false;
  @HostBinding("class.cal-in-month") defaultCallInMonthClass: boolean = false;
  @HostBinding("class.cal-out-month") defaultCallOutMonthClass: boolean = false;
  @HostBinding("class.cal-has-events") defaultCallHasEventsClass: boolean = false;
  @HostBinding("class.cal-open") defaultCallOpenClass: boolean = false;
  @HostBinding("class.cal-selected") defaultCallSelectedClass: boolean = false;
  @HostBinding("class.cal-event-highlight") defaultCallEventHighlightClass: boolean = false;
  @Input() day: MonthViewDay;
  @Input() openDay: MonthViewDay;
  @Input() locale: string;
  @Input() tooltipPlacement: PlacementArray;
  @Input() tooltipAppendToBody: boolean;
  @Input() customTemplate: TemplateRef<any>;
  @Input() refresh: Subject<any>;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipDelay: number | null;
  @Output() highlightDay: EventEmitter<any> = new EventEmitter();
  @Output() unhighlightDay: EventEmitter<any> = new EventEmitter();
  @Output()
  eventClicked: EventEmitter<{ event: CalendarEvent }> = new EventEmitter<{
    event: CalendarEvent;
  }>();

  refreshSubscription: Subscription;
  trackByEventId = trackByEventId;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected dateAdapter: DateAdapter) {
  }

  ngOnInit(): void {
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe((selectedDate) => {
        if (this.dateAdapter.isSameDay(this.day.date, selectedDate)) {
          this.defaultCallSelectedClass = true;
        } else {
          this.defaultCallSelectedClass = false;
        }
        if (moment(this.day.date).isSame(moment(), "day")) {
          this.defaultCallTodayClass = true;
        } else {
          this.defaultCallTodayClass = false;
        }
      });
    }

    this.refreshAll();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  refreshAll() {
    this.defaultCallPastClass = this.day.isPast;
    this.defaultCallTodayClass = this.day.isToday;
    this.defaultCallFutureClass = this.day.isFuture;
    this.defaultCallWeekendClass = this.day.isWeekend;
    this.defaultCallInMonthClass = this.day.inMonth;
    this.defaultCallOutMonthClass = !this.day.inMonth;
    this.defaultCallHasEventsClass = this.day.events.length > 0;
    this.defaultCallOpenClass = this.day === this.openDay;
    this.defaultCallSelectedClass = this.day["isSelected"] === true;
    this.defaultCallEventHighlightClass = !!this.day.backgroundColor;
  }
}
