<main class="doc-preview tile-view" (click) = "openQuickPreview()">
    <header class="tile-header">
      <section class="flex-cove" style="height: 45px;">
        <div class="flex-child tile-avatar-img talk-ava">
          <div class="status-indicator"></div>
          <vp-avatar [type] = "'article-avatar'" [chatType]="document?.chatType" [user] = "{'email': document?.from }">
          </vp-avatar>
          <img class="app-logo-mini" src="assets/icon/new-icons/vnctalk.svg"/>
        </div>
        <div class="flex-child">
          <h5 class="tile-header-title">{{documentTitle}}</h5>
        </div>
        <div class="flex-child" *ngIf = "document.metadata && !document.metadata.isStarred">
          <vnc-icon type='product' name='favorites-new' width='12px' height='12px' (click) = "$event.stopPropagation();addStar(document)" class="icon-style"></vnc-icon>
        </div>
        <div class="flex-child" *ngIf = "document.metadata && document.metadata.isStarred">
          <vnc-icon type='product' name='favorites-filled-new' width='12px' height='12px' (click) = "$event.stopPropagation();removeStar(document)" style="color: #ffa800;" class="icon-style"></vnc-icon>
        </div>          
        <div class="flex-child">        
          <button (click) = "$event.stopPropagation();" mat-icon-button class = "more-action" [matMenuTriggerFor] = "talkOptionMenu" >
            <vnc-icon type='product' name='more' width='18px' height='18px' class="icon-style" title="testing"></vnc-icon>
          </button>  
  
          <mat-menu #talkOptionMenu = "matMenu" class="mat-menu-cover">
            <button (click) = "$event.stopPropagation();underDevelopment()" mat-menu-item>
              <vnc-icon type='product' name='open-in-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'OPEN_IN_APP' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='info-outline' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'CHAT_INFO' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();forwardMessage()">
              <vnc-icon type='product' name='forward' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'SEND_TO' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='stickynote-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'STICKY_NOTE' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='mail-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'EMAIL_TO_FRIEND' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();copyToClipboard()">
              <vnc-icon type='product' name='content-copy' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'COPY_TO_CLIPBOARD' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='task-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'CREATE_A_TASK' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='print' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'PRINT' | translate}}</span>
            </button>             
          </mat-menu>  
        </div>  
      </section>
      <section class="flex-cove">
        <div class="head-details flex-cove tile-header-title">
          <div class="user-txt"*ngIf="documentTitle && authorName">{{authorName}}</div>
          <div class="user-txt"*ngIf="documentTitle && !authorName">{{document.from}}</div>
          <div class="head-dt">{{ document?.createdDt | vpDisplayTime : "MMM DD, YYYY hh:mm" }}</div>
        </div>
      </section>
    </header>
  
    <section class="tile-body">
      <div [innerHTML] = "document.parsedContent | vpSafeHtml" [ngClass]="document.type === 'talk' ? 'show-less' : ''"></div>
    </section>
  
    <footer class="tile-footer">
      <div class="flex-cove">
        <vnc-icon type='product' name='tag-new' width='18px' height='18px' style="margin-top: 7px; color: #606b76;"></vnc-icon>
        <vnc-chips size="s" type="static" text="VNCtalk" class="chip-pos"></vnc-chips>
      </div>
      <section class="tile-icon-container">
        <div class="flex-cove user-ac" (click) = "$event.stopPropagation();underDevelopment()">
          <vnc-icon type='product' name='markasread-new' width='18px' height='18px' class="act-icon"></vnc-icon> <p class="icon-wrap">mark as read</p>
        </div>
        <div class="flex-cove user-ac" title="reply" (click) = "$event.stopPropagation();replyMessage()">
          <vnc-icon type='product' name='reply-new' width='18px' height='18px' class="act-icon"></vnc-icon> <p class="icon-wrap">reply</p>  
        </div>
        <div class="flex-cove user-ac" title="call" (click) = "$event.stopPropagation();underDevelopment()">
          <vnc-icon type='product' name='phone-new' width='18px' height='18px' class="act-icon"></vnc-icon><p class="icon-wrap">call</p>
        </div>
      </section>
    </footer>
  </main>