
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";


@Component({
  selector: "vp-input",
  templateUrl: "./input.component.html"
})
export class VNCInputComponent implements OnInit {

  @Input() type: "email" | "password" | "text" = "text"; // Optional
  @Input() size: "small" | "medium" | "large" = "large"; // Optional
  @Input() control: UntypedFormControl;

  @Input() name: string; // Optional
  @Input() required: boolean; // Optional
  @Input() placeholder: string; // Optional
  @Input() error: string; // Optional
  @Input() valid: boolean; // Optional
  @Input() label: string; // Optional
  @Input() helperText: string; // Optional
  @Input() leadingIcon: string; // Optional
  @Input() trailingIcon: string; // Optional
  @Input() trailingText: string; // Optional

  @Output() onTrailingIconClick = new EventEmitter();


  constructor() {}

  ngOnInit() {}

  public get classes(): string[] {
    const hasLeadingIcon = this.leadingIcon ? "hasLeadingIcon" : "";
    const hasTrailingIcon = this.trailingIcon ? "hasTrailingIcon" : "";
    const hasError = this.error ? "error" : "";
    const isValid = this.valid ? "success" : "";
    return ["vnc-input", `${this.size}`, hasLeadingIcon, hasTrailingIcon, hasError, isValid];
  }

}
