/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { filter, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { AppRepository } from "../../repositories/app.repository";
import { AppService } from "src/app/services/app.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { Subject } from "rxjs";
// import { TaskPreviewComponent } from "../../task-preview/task-preview.component";
// import { ComposeTaskComponent } from "../../task-compose";
import { SearchItem } from "src/app/common/models/mail-models/search-item";
import { CommonUtils } from "../../common/utils/common-util";
import { MailUtils } from "../../common/utils/mail-utils";

@Component({
  selector: "vp-doc-task-preview",
  templateUrl: "./doc-task-preview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocTaskPreviewComponent implements OnChanges, OnDestroy, OnInit {
  @Input() document: SearchItem;
  showMore: boolean = false;
  limitedLength = 500;
  private isAlive$ = new Subject<boolean>();
  userFullName$: any;
  authorName: string;
  mailURL: string;
  serverURL: string;
  isLoading: boolean = false;
  showReplies: boolean = false;
  commentsCount: number;
  documentTitle: string;
  tags = [];

  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private appRepository: AppRepository,
    private broadcaster: Broadcaster,
    private appService: AppService) {
  }

  ngOnInit() {
    if (!!this.document.tags) {
      this.tags = this.document.tags.map(v => ({name: v}));
    }

    this.appRepository.getFederatedApps().pipe(take(1)).subscribe(apps => {
   
      const mail = apps.find(app => app.appKey === "mail");
      if (!!mail) {
          this.mailURL = mail.appUrl;
          const isCordovaOrElectron = environment.isCordova || environment.isElectron;
          if (this.mailURL.lastIndexOf("/") === this.mailURL.length - 1) {
              this.serverURL = this.mailURL;
          } else {
              this.serverURL = this.mailURL + "/";
          }
          this.changeDetectionRef.markForCheck();
      }
    });
    this.broadcaster.on<any>("changestarvalue").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.changeDetectionRef.markForCheck();
    });

    this.appRepository.getHideAllComments().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      if (!!val) {
        this.showReplies = false;
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  ngOnChanges() {
    if (this.document) {
      // this.authorName = this.document.from;
      this.documentTitle = this.document.title;

      if (!this.userFullName$) {
        this.userFullName$ = this.appRepository.getContactByEmail(this.document.from)
        .pipe(filter(v => !!v), take(1)).subscribe(res => {
          if (res.name) {
            this.authorName = res.name;
            this.changeDetectionRef.markForCheck();
          }
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.userFullName$ && this.userFullName$.destroy) {
      this.userFullName$.destroy();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  async taskPreview() {
    const { TaskPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../../task-preview/task-preview.component"
      );
    const dialogRef = this.matDialog.open(TaskPreviewComponent, {
      autoFocus: true,
      panelClass: "task_preview",
      data: { document: this.document }
    });
    const detectChange = this.broadcaster.on("documentChange").subscribe((document: any) => {
      this.document = document;
      this.changeDetectionRef.markForCheck();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      detectChange.unsubscribe();
     
    });
  }

  addStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  removeStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "!flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  markAsRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "read" };
      this.appService.messageAction(body).subscribe(res => {
        this.document.unread = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  markAsUnRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "!read" };
      this.appService.messageAction(body).subscribe(res => {
        this.document.unread = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  renderContent(content) {
    return wdtEmojiBundle.render(content).replace(/\r?\n/g, "\\n")
    .replace(/\\n/g, "<br />").replace(/[<]br[^>]*[>]/gi, "");
  }

  hasMoreLines() {
    return this.document && this.document.rawTxt.split(/\r?\n/g).length > 5;
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  filePreview(attachment) {
    let link = this.serverURL + "api/getAttachment/?" + "id=" + this.document.id + "&part=" + attachment.part;
    link = CommonUtils.addTokenToLink(link);
    if (environment.isElectron) {
      // ElectronService.downloadFile(link, attachment.part);
    } else {
      window.open(link, "_blank");
    }
  }

  downloadAllAttachments(attachmentList: any[]) {
    let attachmentsId = MailUtils.getAttachmentIds(attachmentList).toString();
    let link = this.serverURL + "api/downloadAttachments/?" + "id=" + this.document.id
      + "&part=" + attachmentsId + "&filename=" + this.document.title;
    link = CommonUtils.addTokenToLink(link);
    if (environment.isElectron) {
      // ElectronService.downloadFile(link, fileName);
    } else {
      window.open(link, "_system");
    }
  }

  completeTask() {
    this.appRepository.changeTaskCompletedStatus(+this.document.id, true).subscribe(
      res => {
        
      }
    );
  }

  async duplicateTask() {
   
    const { ComposeTaskComponent } = await import(
      /* webpackPrefetch: true */
      "../../task-compose"
      );
    const dialogRef = this.matDialog.open(ComposeTaskComponent, {
      width: "80%",
      height: "600px",
      autoFocus: true,
      panelClass: "quick_preview_dialog",
      data: { document: this.document, action: "duplicateTask" }
    });
    const detectChange = this.broadcaster.on("documentChange").subscribe((document: any) => {
      this.document = document;
      this.changeDetectionRef.markForCheck();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      detectChange.unsubscribe();
     
    });
  }

  deleteTask() {
    this.appRepository.deleteTask(+this.document.id).subscribe(
      res => {
        this.broadcaster.broadcast("deleteDocument", this.document);
        
      }
    );
  }

  async editTask() {
   
    const { ComposeTaskComponent } = await import(
      /* webpackPrefetch: true */
      "../../task-compose"
      );
    const dialogRef = this.matDialog.open(ComposeTaskComponent, {
      width: "80%",
      height: "600px",
      autoFocus: true,
      panelClass: "quick_preview_dialog",
      data: { document: this.document, action: "editTask" }
    });
    const detectChange = this.broadcaster.on("documentChange").subscribe((document: any) => {
      this.document = document;
      this.changeDetectionRef.markForCheck();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      detectChange.unsubscribe();
      
    });
  }

  setCommentsCount(count: number) {
    this.commentsCount = count;
    this.changeDetectionRef.markForCheck();
  }

  toggleShowReplies() {
    this.showReplies = !this.showReplies;

    if (this.showReplies) {
      this.appRepository.setHideAllComments(false);
    }
    this.changeDetectionRef.markForCheck();
  }

  async openUpdateTagsDialog() {
    const { DocAddTagComponent } = await import(
      /* webpackPrefetch: true */
      "../doc-add-tag/doc-add-tag.component"
      );
    const dialogRef = this.matDialog.open(DocAddTagComponent, {
      autoFocus: true,
      panelClass: "add__tags__dialog",
      data: {tags: [...this.tags], solrId: this.document.solrId}
    });
    dialogRef.afterClosed().subscribe( res => {
      if (!!res?.updatedTags) {
        this.tags = res.updatedTags;
        this.changeDetectionRef.markForCheck();
      }
    });
  }
}
