/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { RootState } from "../reducers";
import { Store } from "@ngrx/store";
import { StopProcessing } from "../actions/app";
import { environment } from "src/environments/environment";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store<RootState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.isCordova || environment.isElectron) {
            return next.handle(request);
        }
        return next.handle(request).pipe(catchError(err => {
            this.store.dispatch(new StopProcessing());
            // console.info("[ErrorInterceptor]", err);
            if (err.status === 401 && !request.url.startsWith("/api/profile")
            && !environment.isCordova && !environment.isElectron) {
                // auto logout if 401 response returned from api
                // console.error("[ErrorInterceptor] call logout");
                // this.store.dispatch(new Logout());
                window.location.reload();
                return of(null);
            }

            let error = err.statusText;
            if (err.error) {
                error = err.error.message;
            }
            // console.error("[ErrorInterceptor]", error);
            return throwError(error);
        }));
    }
}
