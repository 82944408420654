
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mobile_settings_dialog commander-dialog">
  <div class="commander__dialog-header mobile_header">
    <div>
      <button id="mobile_sidebar_settings_backbtn" mat-button (click)="close()">
        <mat-icon class="disable-select">arrow_back</mat-icon>
      </button>
    </div>
    <div id="mobile_sidebar_settings_header" class="header_lbl disable-select">
      {{ 'SAVED_SEARCH_LBL' | translate }}
    </div>
    <div class="submit"></div>
  </div>
  <div class="commander__dialog-header desktop_view">
    <div>
      <button mat-button>
        <mat-icon class="disable-select">info</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{ 'SAVED_SEARCH_LBL' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="commander__dialog-body commander__dialog-body--pad">
    <div class="content">
      <mat-list>
        <mat-list-item *ngFor="let search of savedSearches" (click)="applySearch(search)">
          <mat-icon class="search-icon">search</mat-icon>
          <span class="search-label">{{ search.name }}</span>
        </mat-list-item>



<!--        <mat-list-item id="mobile_sidebar_settings_settings" (click)="generalSettingsDialog()">-->
<!--          <mat-icon class="disable-select">settings</mat-icon>-->
<!--          <span class="disable-select">{{ 'GENERAL_SETTINGS' | translate }}</span>-->
<!--        </mat-list-item>-->
<!--        <mat-list-item id="mobile_sidebar_settings_preferences" (click)="preferencesDialog()">-->
<!--          <mat-icon class="disable-select">settings_applications</mat-icon>-->
<!--          <span class="disable-select">{{ 'PREFERENCES' | translate }}</span>-->
<!--        </mat-list-item>-->
<!--        <mat-list-item id="mobile_sidebar_settings_help" (click)="helpDialog()">-->
<!--          <mat-icon class="disable-select">help_outline</mat-icon>-->
<!--          <span class="disable-select">{{ 'HELP' | translate }}</span>-->
<!--        </mat-list-item>-->
      </mat-list>
    </div>
  </div>
</div>
