import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { SearchItem } from "../../common/models/mail-models/search-item";
import { ConfigService } from "src/app/config.service";
import { filter, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MatDialog } from "@angular/material/dialog";
import { AppRepository } from "../../repositories/app.repository";
import { AppService } from "src/app/services/app.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "vp-tile-view-talk",
  templateUrl: "./tile-view-talk.component.html",
  styleUrls: ["./tile-view-talk.component.scss"]
})
export class TileViewTalkComponent implements OnInit, OnChanges {
  @Input() document: SearchItem;
  showMore: boolean = false;
  limitedLength = 500;
  private isAlive$ = new Subject<boolean>();
  userFullName$: any;
  authorName: string;
  documentTitle: string;
  mailURL: string;
  serverURL: string;
  isLoading: boolean = false;
  showReplies: boolean = false;
  commentsCount: number;
  hasMoreLines: boolean = false;

  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private appRepository: AppRepository,
    private broadcaster: Broadcaster,
    private appService: AppService) { }

  ngOnInit(): void {
    this.appRepository.getFederatedApps().pipe(take(1)).subscribe(apps => {

      const mail = apps.find(app => app.appKey === "mail");
      if (!!mail) {
          this.mailURL = mail.appUrl;
          const isCordovaOrElectron = environment.isCordova || environment.isElectron;
          if (this.mailURL.lastIndexOf("/") === this.mailURL.length - 1) {
              this.serverURL = this.mailURL;
          } else {
              this.serverURL = this.mailURL + "/";
          }
          this.changeDetectionRef.markForCheck();
      }
    });
    this.broadcaster.on<any>("changestarvalue").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.changeDetectionRef.markForCheck();
    });

    this.appRepository.getHideAllComments().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      if (!!val) {
        this.showReplies = false;
      }
      this.changeDetectionRef.markForCheck();
    });
    this.hasMoreLines = (this.document.parsedContent.match(/<div class="one-message">/g) || []).length > 3;
  }

  ngOnChanges() {
    if (this.document) {
      if (!this.userFullName$) {
        this.userFullName$ = this.appRepository.getContactByEmail(this.document.from)
        .pipe(filter(v => !!v), take(1)).subscribe(res => {
          if (res.name) {
            this.authorName = res.name;
            this.changeDetectionRef.markForCheck();
          }
        });
      }

      this.documentTitle = this.document.title;
      if (this.document.title.includes("@conference")) {
        this.documentTitle = this.getTitle(this.document.title);
      }
    }
  }

  getTitle(title) {
    const titleMinusProvider = title.split("@conference")[0];
    let titleNameArray = titleMinusProvider.split("__");
    let titleName: string;
    const lastIndex = titleNameArray.length - 1;
    const lastName = titleNameArray[lastIndex].split("_")[0];
    titleNameArray = [...titleNameArray].filter((a, index) => index !== lastIndex);
    titleNameArray.push(lastName);
    titleName = titleNameArray.join(" ");
    return titleName;
  }


  markAsRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "read" };
      this.appService.messageAction(body).subscribe(res => {
        this.document.unread = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  markAsUnRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "!read" };
      this.appService.messageAction(body).subscribe(res => {
        this.document.unread = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  addStar(doc) {
    if (doc.type === "talk") {
      const body = { id: doc.id, op: "flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  removeStar(doc) {
    if (doc.type === "talk") {
      const body = { id: doc.id, op: "!flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  checkInstalledApp(uri: string, appName: string): Observable<boolean> {
    const subject = new BehaviorSubject<boolean>(false);
    subject.next(true);
    return subject.asObservable();
  }

  openApp(): void {
    this.underDevelopment();
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  async openReplyMessage() {
    const { MailOperationComposeComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/mail-operation-compose/mail-operation-compose.component"
      );
    const dialogRef = this.matDialog.open(MailOperationComposeComponent, {
      autoFocus: true,
      data: { document: this.document, action: MailConstants.MAIL_COMPOSE_REPLY },
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {});
    this.changeDetectionRef.markForCheck();
  }

  async openReplyAllMessage() {
    const { MailOperationComposeComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/mail-operation-compose/mail-operation-compose.component"
      );
    const dialogRef = this.matDialog.open(MailOperationComposeComponent, {
      autoFocus: true,
      data: { document: this.document, action: MailConstants.MAIL_COMPOSE_REPLY_ALL },
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {
    });
    this.changeDetectionRef.markForCheck();
  }

  async openForwardMessage() {
    const { MailOperationComposeComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/mail-operation-compose/mail-operation-compose.component"
      );
    const dialogRef = this.matDialog.open(MailOperationComposeComponent, {
      autoFocus: true,
      data: { document: this.document, action: MailConstants.MAIL_COMPOSE_FORWARD },
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });
    this.changeDetectionRef.markForCheck();
  }

  forwardMessage() {
    const id = this.document.id.replace("." + this.document.owner, "")
    .replace(`talk.${this.document.talkId}.`, "").replace(`talk.`, "");
    const target = this.document.chatType === "chat" ? this.document.to[0] : this.document.title;
    this.appRepository.getAroundMessages({id, target, rows: 4}).subscribe(async (messages) => {

      const message = messages.find(v => v.id === id);
      if (message) {
        const { ForwardMessageComponent } = await import("../../forward-message/forward-message.component");

        const dialogRef = this.matDialog.open(ForwardMessageComponent, {
          width: "340px",
          height: "450px",
          autoFocus: true,
          panelClass: "commander__dialog",
          data: message
        });
        dialogRef.afterClosed().subscribe((res: any) => {
          if (!!res && res.target) {
            this.startConversation(res.target);
          }
        });
      }
    });
  }

  replyMessage() {
    const id = this.document.id.replace("." + this.document.owner, "")
    .replace(`talk.${this.document.talkId}.`, "").replace(`talk.`, "");
    const target = this.document.chatType === "chat" ? this.document.to[0] : this.document.title;
    this.appRepository.getAroundMessages({id, target, rows: 4}).subscribe(messages => {

      const message = messages.find(v => v.id === id);
      if (message) {
        this.appRepository.setMessageToReply(message);
        this.openQuickPreview();
      }
    });
  }

  copyToClipboard() {
    CommonUtils.copyToClipboard([this.document.parsedContent]);
    this.translate.get("COPIED_TO_CLIPBOARD").pipe(take(1)).subscribe(text => {
      this.snackBar.open(text, "", {duration: 2000});
    });
  }

  openQuickPreview() {
    if (this.document.chatType === "groupchat") {
      this.startConversation(this.document.title);
    } else {
      if (this.document.from !== this.appRepository.currentUser.defaultMail) {
        this.startConversation(this.document.from);
      } else {
        this.startConversation(this.document.to[0]);
      }
    }
  }

  async startConversation(target) {
    const { ChatPreviewComponent } = await import("../../chat-preview/chat-preview.component");
    const dialogRef = this.matDialog.open(ChatPreviewComponent, {
      width: "660px",
      height: "625px",
      autoFocus: true,
      panelClass: "commander__dialog",
      data: target
    });
    dialogRef.afterClosed().subscribe((res: any) => {});
  }
}
