/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Store } from "@ngrx/store";
import { filter, take, takeUntil } from "rxjs/operators";
import { getOnlineStatus, getUserProfile, RootState } from "src/app/reducers";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import * as moment from "moment";
import { MetaComment } from "../../common/models/comment.model";
import { AppRepository } from "../../repositories/app.repository";
import { SearchItem } from "src/app/common/models/mail-models/search-item";

@Component({
  selector: "vp-doc-tags-tooltip",
  templateUrl: "./doc-tags-tooltip.component.html",
})
export class DocTagsTooltipComponent {
  @Input() tags: {name: string, color?: string}[];

  constructor() {}
}
