
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

/**
  * Usage: dateString | vpLocaleDate:'format'
 **/

import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { LocaleService } from "../../common/providers/locale.service";

@Pipe({
    name: "vpLocaleDate"
})
export class LocaleDatePipe implements PipeTransform {

    constructor(private session: LocaleService) { }

    transform(value: any, format: string) {

        if (!value) { return ""; }
        if (!format) { format = "shortDate"; }

      const datePipe: DatePipe = new DatePipe(this.session.locale);
      return datePipe.transform(value, format);
    }
}
