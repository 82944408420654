
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export enum BroadcastKeys {
    HIDE_GENERAL_SETTINGS = "HIDE_GENERAL_SETTINGS",
    DETECT_CHANGES = "DETECT_CHANGES",
    HIDE_CONFIRM_MAIN_DIALOG = "HIDE_MAIN_CONFIRM_DIALOG",
    HIDE_PROFILE_DIALOG = "HIDE_PROFILE_DIALOG",
    HIDE_AVATAR_CROPPER_DIALOG = "HIDE_AVATAR_CROPPER_DIALOG",
    HIDE_APP_SWITCHER = "HIDE_APP_SWITCHER",
    HANDLE_BACK_BUTTON = "HANDLE_BACK_BUTTON",
    HIDE_SEARCH_FOLDER_FORM = "HIDE_SEARCH_FOLDER_FORM",
    HIDE_APP_DIALOG = "HIDE_APP_DIALOG",
    HIDE_SIDEBAR_DRAWER = "HIDE_SIDEBAR_DRAWER",
    OPEN_SIBAR_DRAWER = "OPEN_SIBAR_DRAWER",
    HANDLE_MAIL_LIST_SCROLL = "HANDLE_MAIL_LIST_SCROLL",
    HIDE_CHANGE_PASSWORD_DIALOG = "HIDE_CHANGE_PASSWORD_DIALOG",
    HIDE_VERSION_DIALOG = "HIDE_VERSION_DIALOG"
  }
