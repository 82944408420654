/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "vpDisplayTime",
  pure: false
})
export class DisplayTimePipe implements PipeTransform {
constructor(private translateService: TranslateService) {
}
  transform(timestamp: any, format: string = "LT"): string {
    if (format === "MMM DD" && this.translateService.currentLang === "de") {
        format = "DD. MMM.";
    } else if (format === "MMM DD, YYYY LT" && this.translateService.currentLang === "de") {
        format = "DD. MMM YYYY LT";
    }
    let time = moment(timestamp, undefined, this.translateService.currentLang).format(format);
    if (format === "MMM DD") {
      time = time.replace("Juni", "Jun");
    } else if (format === "MM/DD/YYYY") {
      if (this.translateService.currentLang === "de") {
        format = "DD.MM.YYYY";
      }
      time = moment(new Date(timestamp), undefined, this.translateService.currentLang).format(format);
    } else if (format === "MM/DD/YYYY hh:mm") {
      if (this.translateService.currentLang === "de") {
        format = "DD.MM.YYYY LT";
      }
      time = moment(new Date(timestamp), undefined, this.translateService.currentLang).format(format);
    } else if (format === "MM/DD hh:mm") {
      if (this.translateService.currentLang === "de") {
        format = "DD.MM LT";
      }
      time = moment(new Date(timestamp), undefined, this.translateService.currentLang).format(format);
    } else if (format === "MMM DD, YYYY hh:mm") { // Wed.16 Sep 2019. 16:10
      format = "ddd.D MMM YYYY. hh:mm";
      time = moment(new Date(timestamp), undefined, this.translateService.currentLang).format(format);
    }


    return time;
  }

}
