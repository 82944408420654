/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { CalendarDateFormatter } from "./calendar-date-formatter.provider";
import { ElectronService } from "src/app/services/electron.service";

/**
 * This pipe is primarily for rendering the current view title. Example usage:
 * ```typescript
 * // where `viewDate` is a `Date` and view is `"month" | "week" | "day"`
 * {{ viewDate | vpCalendarDate:(view + "ViewTitle"):"en" }}
 * ```
 */
@Pipe({
  name: "vpCalendarDate"
})
export class CalendarDatePipe implements PipeTransform {
  constructor(
    private dateFormatter: CalendarDateFormatter,
    @Inject(LOCALE_ID) private locale: string,
    private electronService: ElectronService
  ) {}

  transform(
    date: Date,
    method: string,
    locale: string = this.locale,
    weekStartsOn: number = 0,
    excludeDays: number[] = [],
    daysInWeek?: number
  ): string {
    const language =  "en";
    // this.electronService.isElectron
    //   ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (!!language && language !== null) {
      locale = language;
    } else {
      locale = this.locale;
    }
    if (typeof this.dateFormatter[method] === "undefined") {
      const allowedMethods = Object.getOwnPropertyNames(
        Object.getPrototypeOf(CalendarDateFormatter.prototype)
      ).filter(iMethod => iMethod !== "constructor");
      throw new Error(
        `${method} is not a valid date formatter. Can only be one of ${allowedMethods.join(
          ", "
        )}`
      );
    }
    return this.dateFormatter[method]({
      date,
      locale,
      weekStartsOn,
      excludeDays,
      daysInWeek
    });
  }
}
