/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ENTER, FF_SEMICOLON, SEMICOLON } from "@angular/cdk/keycodes";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, filter, switchMap, take, takeUntil } from "rxjs/operators";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { of, Subject } from "rxjs";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { AppRepository } from "src/app/repositories/app.repository";
import { Store } from "@ngrx/store";
import { getUserProfile, RootState } from "src/app/reducers";

@Component({
  selector: "vp-talk-autocomplete",
  templateUrl: "./autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TalkAutocompleteComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, SEMICOLON, FF_SEMICOLON];
  emailCtrl = new UntypedFormControl();
  filteredEmails: string[];
  searchedUsers: any[] = [];
  isMobile: boolean = CommonUtils.isMobileDevice();
  private isAlive$ = new Subject<boolean>();

  @Input() placeholder: string;
  @Input() formId: string;
  @Input() isFocused: false;
  @Input() emails: any[] = [];
  @Output() fieldIsFocused = new EventEmitter<any>();
  @ViewChild("emailInput") emailInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  isOnMobileDevice: boolean = CommonUtils.isOnMobileDevice();
  currentUser: any;
  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private appRepository: AppRepository,
    private store: Store<RootState>,
    private broadcaster: Broadcaster
  ) {
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(profile => {
      this.currentUser = profile;
      this.changeDetectionRef.markForCheck();
    });
    this.emailCtrl.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.isAlive$),
      switchMap(value => {
        if (!!value && value.length > 1) {
          return this.appRepository.searchLDAP(value);
        } else {
          return of([]);
        }
      }))
      .subscribe(contacts => {
        
        this.searchedUsers = contacts.filter(u => u.email !== this.currentUser.defaultMail).map(u => {
          const recipient = { name: u.name || u.email.split("@")[0], email: u.email };
          return recipient;
        });
        this.loadEmailSuggestion();
      });
  }

  loadEmailSuggestion() {
    const selectedMail = this.emails.map(v => v.email);
    this.filteredEmails = this.searchedUsers.filter(user => !selectedMail.includes(user.email));
    this.changeDetectionRef.markForCheck();
  }

  onFocusEvent() {
    this.fieldIsFocused.emit(this.formId);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      if (CommonUtils.validateEmail(value)) {
        const item = { title: value, name: value, email: value, image: "", checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.changeDetectionRef.markForCheck();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.isEmailExists(event.option.value.email)) {
      this.emails.push(event.option.value);
    }
    this.emailInput.nativeElement.value = "";
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.emailInput) {
        this.emailInput.nativeElement.focus();
      }
    }, 500);
  }

  resetEmail(): void {
    this.emails = [];
    this.changeDetectionRef.markForCheck();
  }

  onInputEvent(ev): void {
    if (typeof cordova !== "undefined") {
      if (ev.data === ";") {
        const input = ev.target;
        const value = ev.target.value.replace(/;/g, "");
        this.addEmailToChips(input, value);
      }
    }
  }

  addEmailToChips(input: any, value: string) {
    if ((value || "").trim()) {
      if (CommonUtils.validateEmail(value)) {
        const item = { name: value, email: value };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  onKeyDown(ev) {
    if (typeof cordova !== "undefined") {
      if (ev.key === "Backspace" && ev.target.value === "") {
        this.emails.pop();
        setTimeout(() => {
          if (this.emailInput) {
            this.emailInput.nativeElement.focus();
          }
          this.autocompleteTrigger.closePanel();
        }, 50);
      }
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  isEmailExists(value: string): boolean {
    const existsEmail = this.emails.filter(item => item.email === value);
    return existsEmail.length > 0 ? true : false;
  }

  setValue(value) {
    if (CommonUtils.validateEmail(value)) {
      const item = { name: value, email: value, image: "", checked: false };
      if (!this.isEmailExists(value)) {
        this.emails.push(item);
      }
      this.autocompleteTrigger.closePanel();
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }
}
