/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { Conversation } from "../common/models/conversation.model";

export class ConversationActionTypes {
  static CONVERSATION_LOAD_REQUEST = "[CONVERSATION] Load Request";
  static CONVERSATION_LOAD_SUCCESS = "[CONVERSATION] Load Success";

  static CONVERSATION_NEXT_LOAD_REQUEST = "[CONVERSATION] Next Load Request";
  static CONVERSATION_NEXT_LOAD_SUCCESS = "[CONVERSATION] Next Load Success";

  static CONVERSATION_CREATE = "[CONVERSATION] Create";
  static CONVERSATION_UPDATE_SUBJECT = "[CONVERSATION] Update Subject";
  static INCOMING_CONFERENCE = "[CONVERSATION] Call Invitation";

  static CONVERSATION_SET_CONFIG = "[CONVERSATION] Set Config";

  static CONVERSATION_INITIAL_ACTIVE = "[CONVERSATION] Initial Ative";
  static CONVERSATION_RESET_ALL_ACTIVATED = "[CONVERSATION] Reset All Ativated";
  static CONVERSATION_SELECT = "[CONVERSATION] Select";
  static CONVERSATION_DE_SELECT = "[CONVERSATION] De Select";

  static CONVERSATION_DELETE = "[CONVERSATION] Delete";
  static CONVERSATION_MULTIPLE_DELETE = "[CONVERSATION] Multiple Delete";
  static CONVERSATION_INACTIVE = "[CONVERSATION] InActive";

  static CONVERSATION_UPDATE_NOTIFICATION_SETTING = "[CONVERSATION] Update notification settings";

  static CONVERSATION_UPDATE_SOUND_SETTING = "[CONVERSATION] Update Sound settings";

  static CONVERSATION_BLOCK_LIST_INDEX = "[CONVERSATION] Block List Index";
  static CONVERSATION_BLOCK_LIST_ADD = "[CONVERSATION] Block List Add";
  static CONVERSATIONS_BLOCK_LIST_ADD = "[CONVERSATION] Blocks List Add";
  static CONVERSATIONS_BLOCK_LIST_REMOVE = "[CONVERSATION] Block List Remove";
  static CONVERSATION_BLOCK_LIST_REMOVE = "[CONVERSATION] Block List Remove";

  static CONVERSATION_SHOW_DETAILS = "[CONVERSATION] Show Details";
  static CONVERSATION_HIDE_DETAILS = "[CONVERSATION] Hide Details";

  static CONVERSATION_SET_TEXT = "[CONVERSATION] Set Text";

  static CONVERSATION_UPDATE_PARTICIPANTS = "[CONVERSATION] Update Participants";
  static CONVERSATION_UPDATE_MEMBERS = "[CONVERSATION] Update Members";
  static CONVERSATION_UPDATE_OWNER = "[CONVERSATION] Update Owner";
  static MULTI_CONVERSATION_UPDATE_MEMBERS = "[CONVERSATION] Multi Conversation Update Members";
  static MULTI_CONVERSATION_UPDATE_OWNER = "[CONVERSATION] Multi Conversation Update Owner";
  static CONVERSATION_REMOVE_MEMBER = "[CONVERSATION] Remove Member";
  static CONVERSATION_REMOVE_MEMBERS = "[CONVERSATION] Remove Members";
  static CONVERSATION_APPEND_PARTICIPANTS = "[CONVERSATION] Append Participants";
  static CONVERSATION_BULK_APPEND_PARTICIPANTS = "[CONVERSATION] Bulk Append Participants";
  static CONVERSATION_REMOVE_PARTICIPANT = "[CONVERSATION] Remove Participant";
  static CONVERSATION_UPDATE_PARTICIPANT_ROLE = "[CONVERSATION] Update Participant Role";
  static CONVERSATION_UNREAD_COUNT_INCREMENT = "[CONVERSATION] Unread Count Increment";
  static CONVERSATION_SET_UNREAD_COUNT = "[CONVERSATION] Set Unread Count";
  static CONVERSATION_BULK_UNREAD_COUNT_INCREMENT = "[CONVERSATION] Bulk Unread Count Increment";
  static START_CONFERENCE = "[CONVERSATION] Start Conference";
  static LEAVE_CONFERENCE = "[CONVERSATION] Leave Conference";
  static UPDATE_ACTIVE_CONFERENCE = "[CONVERSATION] Update Conference";
  static SET_JITSI_ROOM = "[CONVERSATION] Set Jitsiroom";
  static START_WHITEBOARD = "[CONVERSATION] Start Whiteboard";
  static CONVERSATION_RESET_UNREAD = "[CONVERSATION] Reset Unread";

  static RESET_UNREAD_MESSAGE_COUNT = "[CONVERSATION] Reset Unread Message Count";
  static RESET_UNREAD_MESSAGE_COUNT_FORCE = "[CONVERSATION] Reset Unread Message Count Force";
  static RESET_LAST_PAGE_LOADED = "[CONVERSATION] Reset Last Page Loaded";


  static ARCHIVE_CONVERSATION = "[CONVERSATION] Archive Conversation";
  static UPDATE_CONVERSATION = "[CONVERSATION] Update Conversation";
  static UNARCHIVE_CONVERSATION = "[CONVERSATION] Unarchive Conversation";
  static CONVERSATION_UPDATE_UPLOADING_FILE = "[CONVERSATION] Update uploading file";
  static CONVERSATION_FILE_UPLOADED = "[CONVERSATION] Remove uploaded file";
  static CONVERSATION_UPDATE_LAST_ACTIVITY = "[CONVERSATION] Update last activity";


  static CONVERSATION_LAST_ACTIVITY = "[CONVERSATION] last activity";
  static CONVERSATION_LAST_TEXT = "[CONVERSATION] last text";
  static CONVERSATION_UNREAD_COUNTS = "[CONVERSATION] unread ids";
  static SET_ACTIVE_CONVERSATION = "[CONVERSATION] Set Active Conversation";
  static CONVERSATION_UPDATE_JITSI_ROOM = "[CONVERSATION] Update jitsi room";
  static CONVERSATION_ROOM_IDS = "[CONVERSATION] Update jitsi room";
  static CONVERSATION_UPDATE_BROADCAST_AUDIENCE = "[CONVERSATION] Update broadcast audience";
  static CONVERSATION_UPDATE_BROADCAST_TITLE = "[CONVERSATION] Update broadcast title";
  static CONVERSATION_UPDATE_BROADCAST_DATA = "[CONVERSATION] Update broadcast data";

}

export class ConversationLoadRequest implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LOAD_REQUEST;
}

export class ConversationLoadSuccess implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LOAD_SUCCESS;

  constructor(public payload: Conversation[]) {
  }
}

export class ConversationNextLoadRequest implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_NEXT_LOAD_REQUEST;
}

export class ConversationNextLoadSuccess implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_NEXT_LOAD_SUCCESS;

  constructor(public payload: { conversations: Conversation[] }) {
  }
}

export class ConversationCreate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_CREATE;

  constructor(public payload: Conversation) {
  }
}

export class ConversationInActive implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_INACTIVE;

  constructor(public payload: { target: string }) {
  }
}

export class ConversationSubjectUpdate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_SUBJECT;

  constructor(public payload: { target: string, subject: string }) {
  }
}

export class ConversationUpdate implements Action {
  readonly type = ConversationActionTypes.UPDATE_CONVERSATION;

  constructor(public payload: { target: string, changes: any }) {
  }
}

export class IncomingConference implements Action {
  readonly type = ConversationActionTypes.INCOMING_CONFERENCE;

  constructor(public payload: any) {
  }
}

export class ConversationInitialActive implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_INITIAL_ACTIVE;

  constructor(public payload: string) {
  }
}

export class ConversationResetAllActivated implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_RESET_ALL_ACTIVATED;
}

export class ConversationSelect implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SELECT;

  constructor(public payload: string) {
  }
}

export class ConversationDeSelect implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_DE_SELECT;
}

export class ConversationDelete implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_DELETE;

  constructor(public payload: string) {
  }
}



export class UpdateNotificationSetting implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_NOTIFICATION_SETTING;

  constructor(public payload: { conversationTarget: string, type: number }) {
  }
}


export class UpdateSoundSetting implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_SOUND_SETTING;

  constructor(public payload: { conversationTarget: string, type: number }) {
  }
}


export class ConversationBlockListIndex implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BLOCK_LIST_INDEX;

  constructor(public payload: string[]) {
  }
}


export class ConversationBlockListAdd implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BLOCK_LIST_ADD;

  constructor(public payload: string) {
  }
}

export class ConversationsBlockListAdd implements Action {
  readonly type = ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_ADD;

  constructor(public payload: string[]) {
  }
}

export class ConversationsBlockListRemove implements Action {
  readonly type = ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_REMOVE;

  constructor(public payload: string[]) {
  }
}


export class ConversationBlockListRemove implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BLOCK_LIST_REMOVE;

  constructor(public payload: string) {
  }
}

export class ConversationShowDetails implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SHOW_DETAILS;

  constructor(public payload: string) {
  }
}

export class ConversationHideDetails implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_HIDE_DETAILS;

  constructor(public payload: string) {
  }
}

export class ConversationUpdateBroadcastAudience implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_AUDIENCE;
  constructor(public payload: { conversationTarget: string, audience: string[] }) {
  }
}

export class ConversationUpdateBroadcastTitle implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_TITLE;
  constructor(public payload: { conversationTarget: string, title: string }) {
  }
}

export class ConversationUpdateBroadcastData implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_DATA;
  constructor(public payload: { conversationTarget: string, changes: any }) {
  }
}

export class ConversationUpdateMembers implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_MEMBERS;


  constructor(public payload: { conversationTarget: string, members: string[] }) {
  }
}

export class ConversationUpdateOwner implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_OWNER;


  constructor(public payload: { conversationTarget: string, owner: string }) {
  }
}


export class ConversationResetUnread implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_RESET_UNREAD;


  constructor(public payload: string) {
  }

}

export class MultiConversationUpdateMembers implements Action {
  readonly type = ConversationActionTypes.MULTI_CONVERSATION_UPDATE_MEMBERS;


  constructor(public payload: { conversationTarget: string, members: string[] }[]) {
  }

}

export class MultiConversationUpdateOwner implements Action {
  readonly type = ConversationActionTypes.MULTI_CONVERSATION_UPDATE_OWNER;


  constructor(public payload: { conversationTarget: string, owner: string }[]) {
  }

}

export class ConversationRemoveMember implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_REMOVE_MEMBER;


  constructor(public payload: { conversationTarget: string, member: string }) {
  }

}

export class ConversationRemoveMembers implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_REMOVE_MEMBERS;


  constructor(public payload: { conversationTarget: string, members: string[] }) {
  }

}

export class ConversationUpdateParticipantRole implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_PARTICIPANT_ROLE;


  constructor(public payload: { conversationTarget: string, bare: string, role: string }) {
  }
}

export class ConversationUpdateLastActivity implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_LAST_ACTIVITY;


  constructor(public payload: { target: string, seconds: number }) {
  }
}

export class ConversationMultipleDelete implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_MULTIPLE_DELETE;


  constructor(public payload: string[]) {
  }
}

export class StartConference implements Action {
  readonly type = ConversationActionTypes.START_CONFERENCE;

  constructor(public payload: { conversationTarget: string, conferenceType: string, startFromInvitation?: boolean }) {
  }
}

export class SetJitsiRoom implements Action {
  readonly type = ConversationActionTypes.SET_JITSI_ROOM;

  constructor(public payload: { conversationTarget: string, jitsiRoom: string }) {
  }
}

export class StartWhiteboard implements Action {
  readonly type = ConversationActionTypes.START_WHITEBOARD;

  constructor(public payload: { conversationTarget: string, conferenceType: string, startFromInvitation?: boolean }) {
  }
}

export class LeaveConference implements Action {
  readonly type = ConversationActionTypes.LEAVE_CONFERENCE;

  constructor(public payload: { conversationTarget: string }) {
  }
}

export class UpdateActiveConference implements Action {
  readonly type = ConversationActionTypes.UPDATE_ACTIVE_CONFERENCE;

  constructor(public payload: { active: any }) {
  }
}

export class UnreadCountIncrement implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UNREAD_COUNT_INCREMENT;

  constructor(public payload: { conversationTarget: string, messageIds: string[] }) {
  }
}

export class SetUnreadCount implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_UNREAD_COUNT;

  constructor(public payload: { conversationTarget: string, messageIds: string[] }) {
  }
}

export class BulkUnreadCountIncrement implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BULK_UNREAD_COUNT_INCREMENT;

  constructor(public payload: { conversationTarget: string, messageIds: string[] }[]) {
  }
}


export class SetConversationText implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_TEXT;

  constructor(public payload: { conversationTarget: string, text: string }) {
  }
}

export class ResetUnreadMessageCount implements Action {
  readonly type = ConversationActionTypes.RESET_UNREAD_MESSAGE_COUNT;

  constructor(public payload: string) {

  }
}

export class ResetUnreadMessageCountForce implements Action {
  readonly type = ConversationActionTypes.RESET_UNREAD_MESSAGE_COUNT_FORCE;

  constructor(public payload: string) {

  }
}

export class ArchiveConversation implements Action {
  readonly type = ConversationActionTypes.ARCHIVE_CONVERSATION;

  constructor(public payload: { target: string, timestamp: number }) {

  }
}

export class UnArchiveConversation implements Action {
  readonly type = ConversationActionTypes.UNARCHIVE_CONVERSATION;

  constructor(public payload: { target: string, timestamp: number }) {
  }
}

export class UpdateFileInProgress implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_UPLOADING_FILE;

  constructor(public payload: {
    messageId: string, progress: {
      loaded: number, total: number
    }
  }) {
  }
}

export class RemoveUploadedFile implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_FILE_UPLOADED;

  constructor(public payload: { messageId: string }) {
  }
}

export class ResetLastPageLoaded implements Action {
  readonly type = ConversationActionTypes.RESET_LAST_PAGE_LOADED;

  constructor(public payload: string) {
  }
}

export class RestoreLastActivity implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LAST_ACTIVITY;

  constructor(public payload) {
  }
}

export class RestoreRoomIds implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_ROOM_IDS;

  constructor(public payload) {
  }
}

export class RestoreLastText implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LAST_TEXT;

  constructor(public payload) {
  }
}


export class RestoreUnreadIds implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UNREAD_COUNTS;

  constructor(public payload) {
  }
}


export class SetActiveConversation implements Action {
  readonly type = ConversationActionTypes.SET_ACTIVE_CONVERSATION;

  constructor(public payload: Conversation) {
  }
}

export class UpdateConversationRoomId implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_JITSI_ROOM;

  constructor(public payload: { conversationTarget: string, option: any }) {
  }
}
