/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { environment } from "../../..//environments/environment";
import { ConfigService } from "src/app/config.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/reducers/app";
import { Logout } from "src/app/actions/app";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { take } from "rxjs/operators";
import { of } from "rxjs";
@Injectable()
export class AuthService {

  private AUTH_TOKEN_KEY: string;
  userRequest: any = null;
  redirectTo: string = null;
  isProfileUpdated$: Subject<boolean> = new Subject<boolean>();
  checkAuthenticationInterval: any;

  authUserChanged$ = new Subject<boolean>();

  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private config: ConfigService,
    private http: HttpClient,
    private store: Store<AppState>) {
    this.AUTH_TOKEN_KEY = this.config.get("AUTH_TOKEN_KEY");
  }

  public getProfile(): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    
      return this.http.get(this.config.API_URL + "/api/profile", { headers: headers });
   
  }

  public getToken(): string {
    return localStorage.getItem("token");
  }

  logout() {
    localStorage.removeItem("pendingMessages");
    return this.http.get(this.config.API_URL + "/api/logout/callback", {});
  }

  cordovaLogout(): Observable<any> {
    localStorage.removeItem("pendingMessages");
    return this.http.get(this.config.API_URL + `/api/cordova-logout`);
  }


  handleErrorObservable(error: Response | any) {
    return throwError(error);
  }

  logoutWebAndApp() {
    localStorage.removeItem("pendingMessages");
    this.store.dispatch(new Logout());
    if (this.isCordovaOrElectron) {
      this.cordovaLogout().pipe(take(1)).subscribe();
      localStorage.removeItem("token");
      this.loginIframe();
    } else {
      window.location.href = "/api/logout/callback";
    }
    localStorage.clear();
  }

  loginIframe() {
   
    let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
    initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref.split("/index.html")[0];
    const loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
 
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();
    }
    if (!this.config.selectedServer) {
      return;
    }

    const iframe = document.createElement("iframe");

    if (iframe) {
      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "1111";
      iframe.style.border = "none";
      iframe.style.border = "none";

      iframe.onload = () => {
        iframe.contentWindow.document.querySelector("html").style.height = "100%";
      
      };

      if (document.querySelector("vp-app") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("vp-app").appendChild(iframe);
      } else {
        if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
          document.querySelector("body").appendChild(iframe);
        }
      }
    } else {
      
    }
  }

  logoutIframe() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    const iframe = document.createElement("iframe");
    if (iframe) {
      iframe.id = "logoutIframe";
      iframe.setAttribute("src", this.config.API_URL + "/api/logout/callback");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      if (document.querySelector("body") !== null && document.querySelector("#logoutIframe") === null) {
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        document.querySelector("body").appendChild(iframe);
      }
      if (document.querySelector("vp-commander") !== null) {
        const commander = <HTMLElement>document.querySelector("vp-commander");
        commander.style.display = "none";
      }
    }
  }
}
