/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { adapterFactory as baseAdapterFactory } from "calendar-utils/date-adapters/date-fns";
import * as addWeeks from "date-fns/add_weeks/index";
import * as addMonths from "date-fns/add_months/index";
import * as addYears from "date-fns/add_years/index";
import * as subDays from "date-fns/sub_days/index";
import * as subWeeks from "date-fns/sub_weeks/index";
import * as subMonths from "date-fns/sub_months/index";
import * as subYears from "date-fns/sub_years/index";
import * as getISOWeek from "date-fns/get_iso_week/index";
import * as setDate from "date-fns/set_date/index";
import * as setMonth from "date-fns/set_month/index";
import * as setYear from "date-fns/set_year/index";
import * as getDate from "date-fns/get_date/index";
import * as getYear from "date-fns/get_year/index";
import * as isSameYear from "date-fns/is_same_year/index";
import * as isEqual from "date-fns/is_equal/index";
import { DateAdapter } from "../date-adapter";

export function adapterFactory(): DateAdapter {
  return {
    ...baseAdapterFactory(),
    addWeeks,
    addMonths,
    addYears,
    subDays,
    subWeeks,
    subMonths,
    subYears,
    getISOWeek,
    setDate,
    setMonth,
    setYear,
    getDate,
    getYear,
    isSameYear,
    isEqual,
  };
}
