
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: "vp-button",
  templateUrl: "./button.html"
})
export class VNCButtonComponent implements OnInit {
  @Input() shape: "circle" | "rectangle" = "circle";

  @Input() roundButtonType: "blue" | "green" |"lightgreen" |"pink" |"neutral";
  @Input() diameter: "small" | "medium" | "large" | "xl";

  @Input() type: "primary" | "secondary" | "link" | "disabled" = "primary";
  @Input() height: "small" | "medium" | "large" | "xl";
  @Input() padding: "large" | "xl" | "xxl" | "xxx";

  @Input() isFixedSize = false;
  @Input() fixedSize: 80 | 96 | 120 | 144 | 160 | 200 | 240;

  circularButtonDiameter = {
    small: "36px",
    medium: "48px",
    large: "56px",
    xl: "72px",
  };

  buttonHeight = {
    small: "24px",
    medium: "32px",
    large: "36px",
    xl: "48px",
  };

  buttonPadding = {
    large: "16px",
    xl: "24px",
    xxl: "32px",
    xxx: "48px",
  };

  constructor() {
  }

  ngOnInit() {}

}
