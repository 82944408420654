/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Store } from "@ngrx/store";
import { filter, take, takeUntil } from "rxjs/operators";
import { RootState } from "src/app/reducers";
import { SearchItem } from "../common/models/mail-models/search-item";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppRepository } from "../repositories/app.repository";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { AppService } from "src/app/services/app.service";
import { Subject } from "rxjs/internal/Subject";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MailUtils } from "src/app/common/utils/mail-utils";
import { environment } from "src/environments/environment";

@Component({
  selector: "vp-tile-view",
  templateUrl: "./tile-view.component.html",
  styleUrls: ["./tile-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileViewComponent implements OnChanges, OnDestroy, OnInit {
  @Input() document: SearchItem;
  private isAlive$ = new Subject<boolean>();
  mailURL: string;
  serverURL: string;
  userFullName$: any;
  authorName: string;
  documentTitle: string;
  tagsCount: number;
  commentsCount: number;
  attachmentsCount: number;
  showLogo: boolean = true;

  constructor() { }

  ngOnInit(): void {
    // console.log(this.document)
  }

  ngOnChanges() {
  }

  ngOnDestroy() {
  }
}
