
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="comment-component">
  <div *ngIf="!isCollapsed" class="comment-content-wrapper">
    <ul class="comments-list" *ngIf="!!comments && comments.length > 0">
      <li class="comment-item level1" *ngFor="let commentLevel1 of comments">
        <ng-container *ngTemplateOutlet="commentTemplate;context:{comment: commentLevel1}">
        </ng-container>
        <ul *ngIf="!isReplyCollapsed[commentLevel1.id] && commentLevel1.replies && commentLevel1.replies.length > 0">
          <li class="comment-item level2" *ngFor="let commentLevel2 of commentLevel1.replies">
            <ng-container *ngTemplateOutlet="commentTemplate;context:{comment: commentLevel2, parent: commentLevel1}">
            </ng-container>
            <ul *ngIf="commentLevel2.replies && commentLevel2.replies.length > 0">
              <li class="comment-item level3" *ngFor="let commentLevel3 of commentLevel2.replies">
                <ng-container *ngTemplateOutlet="commentTemplate;context:{comment: commentLevel3, parent: commentLevel2, hideReply: true}">
                </ng-container>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

  </div>
  <div *ngIf="!isCollapsed" class="comment-footer">
    <div class="comment-author">
      <mat-icon *ngIf="!currentUser" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
      <vp-avatar *ngIf="currentUser" [type]="'comment-avatar'" [user]="{'email': currentUser?.defaultMail }">
      </vp-avatar>
    </div>
    <div class="comment-input">
      <textarea #commentInput 
      (focusout)="commentToReply=null;parentComment=null"
      [formControl]="commentControl"
      name="commentInput" 
      (keydown.enter)="handleEnterOnMessage($event)"
      (keydown.meta.enter)="handleEnterOnMessage($event)"
      (keydown.control.enter)="handleEnterOnMessage($event)" cols="30" rows="1" placeholder="{{ 'TYPE_A_MESSAGE' | translate }}"></textarea>
    </div>
  </div>
</div>

<ng-template #commentTemplate let-comment="comment" let-parent="parent" let-hideReply="hideReply">
  <div class="comment-item-wrapper" (click)="commentToReply=null;parentComment=null" [class.selected-comment]="commentToReply && comment.id === commentToReply.id">
    <div class="comment-author">
      <vp-avatar *ngIf="currentUser" [type]="'comment-avatar'" [user]="{'email': comment.author.jid }">
      </vp-avatar>
    </div>
    <div class="comment-bubble" [class.selected-comment]="commentToReply && comment.id === commentToReply.id">
      <div class="comment-text" [innerHTML]="getHTML(comment.comments)"></div>
      <div class="comment-metadata">
        <div class="comment-left-side">
          <div class="comment-author-name">{{ comment.author.name }}</div>
          <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-blank-circle" class="comment-separator"></mat-icon>
          <div class="comment-datetime">{{ comment.updated_on | vpDisplayTime : "MM/DD/YYYY hh:mm" }}</div>
          <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-blank-circle" class="comment-separator"></mat-icon>
          <mat-icon fontSet="mdi" (click)="$event.stopPropagation();underDevelopment()" fontIcon="mdi-emoticon-outline" class="comment-emoji"></mat-icon>

          <div class="comment-actions" *ngIf="!hideReply">
            <a href="javascript:void(0)" (click)="$event.stopPropagation();reply(comment, parent)" mat-button>{{ 'REPLY' | translate}}</a>
          </div>
        </div>
        <span></span>
        <ng-container *ngIf="!parent && comment.replies.length > 0">
          <div class="comment-replies-count" *ngIf="comment.replies && comment.replies.length !== 1">
            {{ 'REPLIES_COUNT' | translate : {total: comment.replies.length } }}</div>
          <div class="comment-replies-count" *ngIf="comment.replies && comment.replies.length === 1">
            {{ 'REPLY_COUNT' | translate : {total: comment.replies.length } }}</div>
            <mat-icon *ngIf="!isReplyCollapsed[comment.id]" (click)="isReplyCollapsed[comment.id]=true" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
            <mat-icon *ngIf="isReplyCollapsed[comment.id]" (click)="isReplyCollapsed[comment.id]=false" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </ng-container>
        
      </div>
    </div>
  </div>
</ng-template>
