/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-general-confirmation-dialog",
    templateUrl: "./general-confirmation-dialog.component.html"
})
export class GeneralConfirmationDialogComponent implements OnInit, OnDestroy {

    message: string = "";

    constructor(
        public dialogRef: MatDialogRef<GeneralConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.message = this.data.message;
    }

    ngOnInit() { }

    ngOnDestroy() { }

    close(value: string): void {
        this.dialogRef.close({ value: value });
    }
}
