/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppRepository } from "../repositories/app.repository";
import { Store } from "@ngrx/store";
import { getOnlineStatus, getUserProfile, RootState } from "../reducers";
import { filter, take, takeUntil } from "rxjs/operators";
import { UserProfile } from "../common/models/mail-models";
// import { StartChatComponent } from "../start-chat/start-chat.component";
// import { StartGroupChatComponent } from "../start-group-chat/start-group-chat.component";

@Component({
  selector: "vp-select-activity-dialog",
  templateUrl: "./select-activity-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectActivityDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  isAppOnline: boolean;
  currentUser: UserProfile;
  private isAlive$ = new Subject<boolean>();
  isMobileScreen: boolean;
  constructor(private dialogRef: MatDialogRef<SelectActivityDialogComponent>,
    public appRepository: AppRepository,
    private matDialog: MatDialog,
    private store: Store<RootState>,
    private changeDetectorRef: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(profile => {
      this.currentUser = profile;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnInit() {
    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe((v: boolean) => this.isAppOnline = v);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    window.scrollTo(0, 0);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  async startChat() {
    this.closeDialog();
    let style: any = {
      width: "520px",
      height: "560px"
    };
    const { StartChatComponent } = await import(
      /* webpackPrefetch: true */
      "../start-chat/start-chat.component"
      );
     
    if (this.appRepository.isMobileScreen) {
      style = {
        width: "100vw",
        minWidth: "100vw",
        height: "100vh",
        borderRadius: "0"
      };
    }
    const dialogRef = this.matDialog.open(StartChatComponent, Object.assign({
      autoFocus: true,
      panelClass: "commander__dialog"
    }, style));
    dialogRef.afterClosed().subscribe((res: any) => {
    });
  }

  async startGroupChat() {
    this.closeDialog();
    let style: any = {
      width: "520px",
      height: "560px"
    };
    const { StartGroupChatComponent } = await import(
      /* webpackPrefetch: true */
      "../start-group-chat/start-group-chat.component"
    );
    if (this.appRepository.isMobileScreen) {
      style = {
        width: "100vw",
        minWidth: "100vw",
        height: "100vh",
        borderRadius: "0"
      };
    }
    const dialogRef = this.matDialog.open(StartGroupChatComponent, Object.assign({
      autoFocus: true,
      panelClass: "commander__dialog"
    }, style));
    dialogRef.afterClosed().subscribe((res: any) => {
    });
  }
}
