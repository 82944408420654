/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ENTER, FF_SEMICOLON, SEMICOLON, SPACE } from "@angular/cdk/keycodes";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from "rxjs/operators";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { of, Subject } from "rxjs";
// import * as _ from "lodash";
import findIndex from "lodash/findIndex";
import { CommonUtils } from "src/app/common/utils/common-util";
import { getAllUserContacts, getAllUserGalContacts, RootState } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { EmailInformation } from "src/app/common/models/mail-models/email-information.model";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { DragDropParticipantService } from "src/app/common/providers/drag-drop-participant.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { AppRepository } from "src/app/repositories/app.repository";

@Component({
  selector: "vp-autocomplete",
  templateUrl: "./mail-autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, SEMICOLON, FF_SEMICOLON, SPACE];
  emailCtrl = new UntypedFormControl();
  filteredEmails: string[];
  emails: any[] = [];
  searchedUsers: any[] = [];
  isMobile: boolean = CommonUtils.isMobileDevice();
  private isAlive$ = new Subject<boolean>();

  @Input() place: string;
  @Input() formId: string;
  @Input() isFocused: false;
  @Output() fieldIsFocused = new EventEmitter<any>();
  @ViewChild("emailInput") emailInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  dragSelected: any;
  dragStatus: boolean;
  isOnMobileDevice: boolean = CommonUtils.isOnMobileDevice();
  allUserContacts: any[] = [];
  allUserGalContacts: any[] = [];

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    // private convRepository: ConversationRepository,
    private dragDropService: DragDropParticipantService,
    private mailBroadcaster: Broadcaster,
    private store: Store<RootState>,
    private appRepository: AppRepository,
  ) {
    this.emailCtrl.valueChanges.pipe(
      debounceTime(100)
      , filter(value => value !== null),
      switchMap(value => {
        value = value.trim();
        if (value === "") {
          return of(null);
        }
        return this.appRepository.getAutoCompleteList(value);
      })
      ).subscribe(res => {
        this.searchedUsers = [];
        this.filteredEmails = [];
        if (!!res && res !== null) {
          if (Array.isArray(res) && res.length > 0) {
            if (res.length > 0) {
              res.forEach(item => {
                this.generateTagList(item);
              });
            }
          } else if (res.$) {
            this.generateTagList(res);
          }
        }
        // this._filter(query);
        this.filteredEmails = this.searchedUsers;
       
        this.changeDetectionRef.markForCheck();
      }, err => {
        if (CommonUtils.isJson(err._body)) {
          // this.mailService.openSnackBar(JSON.parse(err._body).msg);
        }
      });
  }

  loadEmailSuggestion(query) {
    if (query === "" || /\s/g.test(query)) {
      this.filteredEmails = [];
      this.changeDetectionRef.markForCheck();
      return;
    }
    this.appRepository.getAutoCompleteList(query).subscribe(
      res => {
        this.searchedUsers = [];
        if (Array.isArray(res) && res.length > 0) {
          if (res.length > 0) {
            res.forEach(item => {
              this.generateTagList(item);
            });
          } else if (CommonUtils.validateEmail(query)) {
            this.searchedUsers.push({ title: query, name: query, email: query, image: "", checked: false });
          }
        } else if (res.$) {
          this.generateTagList(res);
        }
        this._filter(query);
        this.changeDetectionRef.markForCheck();
      },
      err => {
        if (CommonUtils.isJson(err._body)) {
          this.appRepository.showMessage(JSON.parse(err._body).msg);
          // this.mailService.openSnackBar(JSON.parse(err._body).msg);
        }
      }
    );
  }

  ngOnChanges(changes) {
    if (changes && changes.isFocused) {
      if (this.isFocused) {
        setTimeout(() => {
          if (this.emailInput) {
            this.emailInput.nativeElement.focus();
          }
        }, 200);
      }
    }
  }

  onFocusEvent() {
    this.fieldIsFocused.emit(this.formId);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      if (CommonUtils.validateEmail(value)) {
        const item = { title: value, name: value, email: value, image: "", checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  generateTagList(data) {

    const user = {
      title: "",
      name: "",
      email: "",
      image: "",
      checked: false,
      isGroup: false,
      id: "",
      display: false
    };
    if (!data.isGroup) {
      user.title = data.email ? data.email.replace(/"/g, "").trim() : "";
      user.name = data.email ? data.email.substring(data.email.indexOf(""), data.email.indexOf("<") - 1) : "";
      user.name = user.name ? user.name.replace(/"/g, " ").trim() : "";
      user.email = data.email ? data.email.substring(data.email.indexOf("<") + 1, data.email.indexOf(">")) : "";
      user.email = user.email ? user.email.replace(/"/g, " ").trim() : "";
    }
    if (data.isGroup && data.display) {
      user.name = data.display ? data.display.trim() : "";
      user.title = data.display ? data.display.trim() : "";
      user.email = data.display ? data.display.trim() : "";
      user.display = true;
    }
    if (data.isGroup && data.email) {
      user.title = data.email ? data.email.replace(/"/g, "").trim() : "";
      user.name = data.email ? data.email.substring(data.email.indexOf(""), data.email.indexOf("<") - 1) : "";
      user.name = user.name ? user.name.replace(/"/g, " ").trim() : "";
      user.email = data.email ? data.email.substring(data.email.indexOf("<") + 1, data.email.indexOf(">")) : "";
      user.email = user.email ? user.email.replace(/"/g, " ").trim() : "";
    }
    if (data.id) {
      user.id = data.id;
    }
    if (!!data.isGroup) {
      user.isGroup = data.isGroup;
    }
    if (user.name === "") {
      user.name = user.email;
    }
    if (user.name.length > 20) {
      user.name = user.name.substring(0, 20) + "...";
    }
    this.searchedUsers.push(user);
    return user;
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.changeDetectionRef.markForCheck();
      this.mailBroadcaster.broadcast("AUTO_COMPLETE_REMOVE_ITEM");
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value.isGroup) {
      if (!this.isEmailExists(event.option.value.email)) {
        this.emails.push(event.option.value);
      }
    } else if (event.option.value.isGroup) {
      if (event.option.value.display) {
        this.addEmailFromContactGroup(event.option.value);
      } else if (!event.option.value.display) {
        this.emails.push(event.option.value);
      }
    }
    this.emailInput.nativeElement.value = "";
    // this.mailBroadcaster.broadcast(MailConstants.BROADCAST_OVERFLOW_REDIRECT_DIALOG);
    this.emailCtrl.setValue(null);
    this.filteredEmails = [];
    this.changeDetectionRef.markForCheck();
  }

  private _filter(value: string): void {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      this.filteredEmails = this.searchedUsers.filter(user => user.email.toLowerCase().includes(filterValue));
      this.changeDetectionRef.markForCheck();
    }
  }

  ngOnInit() {
    this.mailBroadcaster.on<any>(MailConstants.CLOSE_AUTO_COMPLETE).pipe(takeUntil(this.isAlive$))
      .subscribe(presence => {
        if (this.autocompleteTrigger.openPanel) {
          this.autocompleteTrigger.closePanel();
          this.changeDetectionRef.markForCheck();
        }
      });
    this.dragDropService.getDraggableData().pipe(distinctUntilChanged(), takeUntil(this.isAlive$))
      .subscribe(res => {
        
        if (res.event === "start") {
          this.dragStatus = true;
          this.dragSelected = res.data;
          this.filteredEmails = [];
        } else {
          if  (this.dragDropService.isValid && !!this.dragDropService.target) {
            const data = res.data.item;
            const index = findIndex(this.emails, {email: this.dragDropService.target.item.email});
            if (res.data.id === this.formId) {
              this.emails = this.emails.filter(v => v.email !== data.email);
            }
            if (this.dragDropService.target && this.dragDropService.target.id === this.formId) {
              let newPosition = index;
              if (this.dragDropService.target.item.email) {
                if (res.data.id !== this.formId) {
                  newPosition = index + 1;
                  if (this.dragDropService.target.position === "left") {
                    newPosition = index - 1;
                  }
                }
                newPosition = newPosition > 0 ? newPosition : 0;
                this.emails.splice(newPosition, 0, data);
              } else {
                this.emails.push(data);
              }
            }
          }
          this.changeDetectionRef.markForCheck();
          this.dragSelected = null;
          this.dragStatus = false;
          if (res.event === "end") {
            setTimeout(() => {
              if (this.emailInput) {
                this.emailInput.nativeElement.blur();
              }
            }, 10);
          }
        }
      });
    this.store.select(getAllUserContacts).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.allUserContacts = res;
      
      this.changeDetectionRef.markForCheck();
    });
    this.store.select(getAllUserGalContacts).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.allUserGalContacts = res;
      this.changeDetectionRef.markForCheck();
    });
      
  }

  getSelectedEmail(): string[] {
    return this.emails;
  }

  setEmailField(emailInfo: EmailInformation): void {
    const item = { title: emailInfo.a, name: emailInfo.p ? emailInfo.p : emailInfo.d, email: emailInfo.a, image: "", checked: false };
    if (!this.isEmailExists(item.email)) {
      this.emails.push(item);
    }
    this.changeDetectionRef.markForCheck();
  }

  getAvatar(email) {
    // return this.convRepository.getAvatar(email);
  }

  resetEmail(): void {
    this.emails = [];
    this.changeDetectionRef.markForCheck();
  }

  onInputEvent(ev): void {
    if (typeof cordova !== "undefined") {
      if (ev.data === ";") {
        const input = ev.target;
        const value = ev.target.value.replace(/;/g, "");
        this.addEmailToChips(input, value);
      }
    }
  }

  addEmailToChips(input: any, value: string) {
    if ((value || "").trim()) {
      if (CommonUtils.validateEmail(value)) {
        const item = { title: value, name: value, email: value, image: "", checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  onKeyDown(ev) {
    if (typeof cordova !== "undefined") {
      if (ev.key === "Backspace" && ev.target.value === "") {
        this.emails.pop();
        setTimeout(() => {
          if (this.emailInput) {
            this.emailInput.nativeElement.focus();
          }
          this.autocompleteTrigger.closePanel();
        }, 50);
      }
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  isEmailExists(value: string): boolean {
    const existsEmail = this.emails.filter(item => item.email === value);
    return existsEmail.length > 0 ? true : false;
  }

  autoCompleteClick(): void {
    if (typeof cordova !== "undefined") {
      this.mailBroadcaster.broadcast("AUTO_COMPLETE_FIELD_PRESS");
    }
  }

  dragStarted($event) {
 
    this.dragDropService.dragStarted({item: $event.source.data, id: this.formId});
  }

  dragEnded($event) {
    
    this.dragDropService.dragEnded({item: $event.source.data, id: this.formId});
    this.filteredEmails = [];
    this.changeDetectionRef.markForCheck();
  }

  mouseOver(emailItem, $event) {
    if (this.dragStatus && $event.relatedTarget) {
      
      const body = document.querySelector("body");
      let position = "right";
      const className = $event.relatedTarget.className;
      if (className === "" || className.indexOf("profile-avtar") !== -1 || className.indexOf("mail-chip-avatar") !== -1
      || className.indexOf("autocomplete-participant") !== -1) {
        position = "left";
      }
      this.dragDropService.target = {item: emailItem, id: this.formId, position: position};
      if (this.dragSelected.item.email === emailItem.email
         || this.dragSelected.id !== this.formId && this.emails.find(v => v.email === this.dragSelected.item.email)) {
        body.classList.add("drop-inactive");
        body.classList.remove("drop-active");
        this.dragDropService.isValid = false;
      } else {
        if ((!emailItem.email && this.emails.length > 0 && this.emails[this.emails.length - 1].email !== this.dragSelected.item.email)
        || emailItem.email || this.emails.length === 0) {
          body.classList.remove("drop-inactive");
          body.classList.remove("add-to-left");
          body.classList.remove("add-to-right");
          body.classList.add("drop-active");
          body.classList.add("add-to-" + position);
          this.dragDropService.isValid = true;
        }
      }
    }
  }

  mouseOut(emailItem, $event) {
    
    this.dragDropService.isValid = false;
    this.dragDropService.target = null;
    const body = document.querySelector("body");
    body.classList.remove("drop-inactive");
    body.classList.remove("drop-active");
  }

  setValue(value) {
    if (CommonUtils.validateEmail(value)) {
      const item = { title: value, name: value, email: value, image: "", checked: false };
      if (!this.isEmailExists(value)) {
        this.emails.push(item);
      }
      this.autocompleteTrigger.closePanel();
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  addEmailFromContactGroup(item: any): void {
    const contact = this.allUserContacts.filter(c => !!c && c.id === item.id)[0];
    if (!!contact && contact !== null) {
      if (!!contact.m && contact.m !== null) {
        const mails = contact.m;
        mails.map( dataItem => {
          if (dataItem.type === "C") {
            const id = dataItem.value;
            if (!!id && id !== null) {
              const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.id === id)[0];
              if (!!contactItem && contactItem !== null) {
                const email = contactItem._attrs.email;
                const fullName = contactItem._attrs.fullName;
                const body = {
                  email: email,
                  name: !!fullName && fullName !== null ? fullName : email
                };
                const user = this.getUserItem(body);
                this.emails.push(user);
              }
            }
          } else if (dataItem.type === "I") {
            const body = {
              email: dataItem.value,
              name: dataItem.value
            };
            const user = this.getUserItem(body);
            this.emails.push(user);
          } else if (dataItem.type === "G") {
            const id = dataItem.value;
            if (!!id && id !== null) {
              const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.value && c.value === id)[0];
              if (!!contactItem && contactItem !== null) {
                const email = contactItem._attrs.email;
                const fullName = contactItem._attrs.fullName;
                const body = {
                  email: email,
                  name: !!fullName && fullName !== null ? fullName : email
                };
                const user = this.getUserItem(body);
                this.emails.push(user);
              }
            }
          }
        });
      }
    }
  }

  getUserItem(contactItem: any): any {
    const user = {
      title: "",
      name: "",
      email: "",
      image: "",
      checked: false,
      isGroup: false,
      id: ""
    };
    user.title = contactItem.name;
    user.name = contactItem.name;
    user.email = contactItem.email;
    return user;
  }

}
