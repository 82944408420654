
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc-input" [ngClass]="required ? 'required' : ''">
  <label *ngIf="label" for="input" class="vnc-input__label">{{label}}</label>
  <div class="vnc-input__body" [ngClass]="classes">
    <mat-icon *ngIf="leadingIcon" class="leading-icon" fontSet="mdi" [fontIcon]="leadingIcon"></mat-icon>
    <input id="input"
           [type]="type"
           [formControl]="control"
    />
    <span *ngIf="trailingText" class="trailing-text">{{trailingText}}</span>
    <mat-icon *ngIf="trailingIcon" class="trailing-icon"
              fontSet="mdi" [fontIcon]="trailingIcon"
              (click)="onTrailingIconClick.emit('')"
    ></mat-icon>
  </div>
  <span *ngIf="error" class="vnc-input__error">{{error}}</span>
  <span *ngIf="helperText" class="vnc-input__helper">{{helperText}}</span>
</div>
