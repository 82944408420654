
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export interface LDAPData {
    co?: string[];
    company?: string[];
    displayName?: string[];
    givenName?: string[];
    l?: string[];
    mail?: string[];
    postCode?: string[];
    sn?: string[];
    street?: string[];
    telephoneNumber?: string[];
    uid?: string[];
    title?: string;
}

export interface SearchUser {
    name: string;
    email: string;
    companyName?: string;
    isExternalUser?: boolean;
    ldapData?: LDAPData;
}

export interface Recipient {
    target: string;
    title: string;
    type: string;
    ldapData?: LDAPData;
    companyName?: string;
    additionalInfo?: string;
    isExternalUser?: boolean;
    isMutedSound?: boolean;
    isMutedNotification?: boolean;
}

export enum MediaType {
    IMAGE = "image",
    VOICE_MESSAGE = "voice-messages",
    ATTACHMENTS = "attachments",
    VIDEOS = "videos",
    ALL = "all",
    PDF = "pdf",
    DOC = "doc",
    TEXT = "txt",
    EXCEL = "excel",
    PPT = "ppt",
    RAR = "rar",
    ZIP = "zip"
  }
