
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<!--<button [ngStyle]="{-->
<!-- height: shape === 'circle' ?  circularButtonDiameter[diameter] : buttonHeight[height],-->
<!-- width: shape === 'circle' ? circularButtonDiameter[diameter] : isFixedSize ? fixedSize + 'px' : '',-->
<!-- paddingLeft: (shape === 'rectangle' && !isFixedSize) ? buttonPadding[padding]: '',-->
<!-- paddingRight: (shape === 'rectangle' && !isFixedSize) ? buttonPadding[padding]: ''-->
<!--}" [ngClass]="shape === 'circle' ? 'vnc-round-button ' + roundButtonType + '-round-button': 'vnc-button ' + type + '-button'">-->
<!--  <ng-content></ng-content>-->
<!--</button>-->

<ng-container *ngIf="shape === 'circle'">
  <button [ngStyle]="{ height: circularButtonDiameter[diameter], width: circularButtonDiameter[diameter]}"
          ngClass="vnc-round-button {{roundButtonType}}-round-button">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-container>


<ng-container *ngIf="shape === 'rectangle'">
  <ng-container *ngIf="!isFixedSize">
    <button [ngStyle]="{height: buttonHeight[height], paddingLeft: buttonPadding[padding], paddingRight: buttonPadding[padding]}"
            ngClass="vnc-button {{type}}-button">
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="isFixedSize">
    <button [ngStyle]="{height: buttonHeight[height], width: fixedSize + 'px'}" ngClass="vnc-button {{type}}-button">
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  </ng-container>
</ng-container>


<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>
