<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<article *ngIf = "document" class = "doc-preview" (click) = "openQuickPreview()"  [class.is-read] = "!document.unread">
  <header  class = "doc-header">
    <div class = "user-avatar">
      <vp-avatar [type] = "'article-avatar'"
                 [chatType]="document?.chatType"
                 [user] = "{'email': document?.from }">
      </vp-avatar>
      <img class="app-logo-mini" src="/assets/icon/new-icons/vnctalk.svg" />
    </div>

    <div class="doc-details">
      <div class="doc-details__heading">
        <span class = "doc-title" *ngIf="documentTitle">{{ documentTitle }}</span>
        <span class = "doc-title" *ngIf="!documentTitle && authorName">{{ authorName }}</span>
        <span class = "doc-title non-capital" *ngIf="!documentTitle && !authorName">{{ this.document.from }}</span>
        <div class = "doc-star">
          <button *ngIf = "document.metadata && !document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();addStar(document)">
            <mat-icon class = "unstarred-icon">star_border</mat-icon>
          </button>
          <button *ngIf = "document.metadata && document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();removeStar(document)">
            <mat-icon class = "starred-icon">star</mat-icon>
          </button>
        </div>
      </div>
      <div class="user-info">
        <span *ngIf="documentTitle && authorName" class = "doc-title">{{ authorName }}</span>
        <span *ngIf="documentTitle && !authorName" class = "doc-title non-capital">{{ document.from }}</span>
        <span *ngIf="documentTitle" class="divider-dot">.</span>
        <div class = "doc-time">
          {{ document?.createdDt | vpDisplayTime : "MM/DD hh:mm" }}
        </div>
      </div>
    </div>

    <div class = "doc-actions">
      <button mat-icon-button (click) = "$event.stopPropagation();replyMessage()" class = "reply-button">
        <mat-icon>reply</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();copyToClipboard()" mat-icon-button class = "copy-button">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();forwardMessage()" mat-icon-button class = "forward-button">
        <mat-icon>forward</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();underDevelopment()" mat-icon-button class = "reply-button">
        <mat-icon>email</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();" mat-icon-button class = "more-action"
              [matMenuTriggerFor] = "talkOptionMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </header>

  <div  class = "doc-body" *ngIf = "document.shortContent">
    <div>
<!--      <div *ngIf = "!showMore" class = "doc-content show-less"-->
<!--           [innerHTML] = "renderContent(document.shortContent) | vpSafeHtml">-->
<!--      </div>-->
      <div class = "doc-content" [ngClass]="!showMore ? 'show-less-talk-message' : 'talk-message'"
           [innerHTML] = "document.parsedContent | vpSafeHtml">
      </div>
      <a class = "show-more-button" href = "javascript:void(0)"
         *ngIf = "!showMore && ((document.parsedContent.length > limitedLength) || hasMoreLines)"
         (click) = "$event.stopPropagation();this.showMore = true">{{ 'SHOW_MORE' | translate }}</a>
      <a class = "show-more-button" href = "javascript:void(0)"
         *ngIf = "showMore && ((document.parsedContent.length > limitedLength)  || hasMoreLines)"
         (click) = "$event.stopPropagation();showMore = false">{{ 'SHOW_LESS' | translate }}</a>
    </div>
    <div class = "quick-mail-loading" *ngIf = "isLoading">
      <vp-custom-spinner></vp-custom-spinner>
    </div>
  </div>

  <footer class = "doc-footer">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" (click)="$event.stopPropagation()">
      <div class="doc-footer__content">
        <!-- <div class = "doc-emoji">
          <mat-icon fontSet="mdi" fontIcon="mdi-emoticon-outline" (click)="$event.stopPropagation();underDevelopment()"></mat-icon>
        </div>
        <span class="divider-dot">.</span> -->
        <a class = "doc-reply" href = "javascript:void(0)" (click)="$event.stopPropagation();showReplyForm()">{{ 'STICKY_NOTES' | translate }}</a>
      </div>

      <div class="doc-reply-count" (click)="$event.stopPropagation();toggleShowReplies()">
        <span>
          {{commentsCount}}
        </span>
        <span>
         {{ (commentsCount === 1 ?  'REPLY' : 'REPLIES') | translate}}
        </span>
        <mat-icon *ngIf="showReplies" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
        <mat-icon *ngIf="!showReplies" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
      </div>
    </div>
  </footer>

  <footer class = "doc-footer--mobile">
    <button mat-icon-button (click) = "$event.stopPropagation();replyMessage()" class = "reply-button">
      <mat-icon>reply</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();copyToClipboard()" mat-icon-button class = "copy-button">
      <mat-icon>content_copy</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();forwardMessage()" mat-icon-button class = "forward-button">
      <mat-icon>forward</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();underDevelopment()" mat-icon-button class = "reply-button">
      <mat-icon>email</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();" mat-icon-button class = "more-action"
            [matMenuTriggerFor] = "talkOptionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </footer>

  <footer class = "doc-footer--mobile">
    <div class="doc-reply-count" (click)="$event.stopPropagation();toggleShowReplies()">
      <mat-icon fontSet="mdi" fontIcon="mdi-comment-outline" class="comment-icon"></mat-icon>
      <span>{{ 'COMMENT' | translate }} {{commentsCount}}</span>
      <mat-icon *ngIf="showReplies" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
      <mat-icon *ngIf="!showReplies" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
    </div>

    <div class = "doc-emoji">
      <mat-icon fontSet="mdi" (click)="$event.stopPropagation();underDevelopment()" fontIcon="mdi-emoticon-outline"></mat-icon>
    </div>
  </footer>

  <mat-menu #talkOptionMenu = "matMenu">
    <button (click) = "$event.stopPropagation();showReplyForm()" mat-menu-item class = "comment-button">
      <mat-icon>comment</mat-icon>
      <span>{{ 'REPLY' | translate}}</span>
    </button>
  </mat-menu>
</article>

<vp-doc-replies [document]="document" [isCollapsed]="!showReplies" (commentsCount)="setCommentsCount($event)"></vp-doc-replies>
