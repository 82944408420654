/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { Message, MessageStatus } from "../common/models/message.model";

export class MessageActionTypes {
  static MESSAGE_ADD = "[MESSAGE] Add";
  static MULTI_CONVERSATION_MESSAGE_ADD = "[MESSAGE] Multi Conversation Add";
  static MESSAGE_STATUS_UPDATE = "[MESSAGE] Status Update";
  static MESSAGE_UPDATE = "[MESSAGE] Update";
  static MESSAGE_STATUS_UPDATE_DELIVERED = "[MESSAGE] Delivered Status Update";
  static MESSAGE_DELETED_STATUS_UPDATE = "[MESSAGE] Deleted Status Update";
  static MULTI_CONVERSATION_MESSAGE_DELETED_STATUS_UPDATE = "[MESSAGE] Multi Conversation Deleted Status Update";
  static MESSAGE_BULK_APPEND = "[MESSAGE] Bulk Append";
  static MESSAGE_BULK_APPEND_MULTI_CONVERSATION = "[MESSAGE] Bulk Append MultiConversation";
  static MESSAGE_BULK_LOADING = "[MESSAGE] Bulk Loading";
  static MESSAGE_BULK_LOADED = "[MESSAGE] Bulk Loaded";
  static MESSAGE_BULK_LOADING_FAILED = "[MESSAGE] Bulk Loading Failed";
  static MESSAGE_DELETE_ACTION = "[MESSAGE] Delete";
  static MESSAGE_LAST_PAGE_LOADED = "[MESSAGE] Last Page Loaded";
  static SELECT_MESSAGE = "[MESSAGE] Select Message";
  static UNSELECT_MESSAGE = "[MESSAGE] Unselect Message";
  static RESET_MESSAGES = "[MESSAGE] Reset Messages";
  static MESSAGE_FAVORITE_UPDATE = "[MESSAGE] update favorite";
  static MESSAGE_BULK_DELETE = "[MESSAGE] delete bulk messages";
  static RESET_UNREAD_COUNT = "[MESSAGE] Reset unread count";
  static SET_MESSAGE_TO_REPLY = "[MESSAGE] Set message to reply";

}

export class MessageAdd implements Action {
  readonly type = MessageActionTypes.MESSAGE_ADD;

  constructor(public payload: { conversationTarget: string, message: Message, incoming?: boolean }) {
  }
}

export class MultiConversationMessageAdd implements Action {
  readonly type = MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD;

  constructor(public payload: { conversationTarget: string, message: Message, incoming?: boolean }[]) {
  }
}

export class MessageBulkAppendMultiConversation implements Action {
  readonly type = MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION;

  constructor(public payload: {
    conversationTarget: string,
    messages: Message[]
  }[]) {
  }
}

export class MessageBulkAppend implements Action {
  readonly type = MessageActionTypes.MESSAGE_BULK_APPEND;

  constructor(public payload: {
    conversationTarget: string,
    messages: Message[]
  }) {
  }
}

export class MessageLastPageLoaded implements Action {
  readonly type = MessageActionTypes.MESSAGE_LAST_PAGE_LOADED;

  // payload is conversationTarget
  constructor(public payload: string) {
  }
}

export class MessageBulkLoading implements Action {
  readonly type = MessageActionTypes.MESSAGE_BULK_LOADING;

  // payload is conversationTarget
  constructor(public payload: string) {
  }
}

export class MessageBulkLoaded implements Action {
  readonly type = MessageActionTypes.MESSAGE_BULK_LOADED;

  // payload is conversationTarget
  constructor(public payload: string) {
  }
}


export class MessageBulkLoadingFailed implements Action {
  readonly type = MessageActionTypes.MESSAGE_BULK_LOADING_FAILED;

  // payload is conversationTarget
  constructor(public payload: string) {
  }
}

export class MessageDeleteAction implements Action {
  readonly type = MessageActionTypes.MESSAGE_DELETE_ACTION;

  // payload is message id
  constructor(public payload: string) {
  }
}

export class MessageStatusUpdateAction implements Action {
  readonly type = MessageActionTypes.MESSAGE_STATUS_UPDATE;

  constructor(public payload: { id: string, status: MessageStatus }) {
  }
}

export class MessageUpdateAction implements Action {
  readonly type = MessageActionTypes.MESSAGE_UPDATE;

  constructor(public payload: { id: string, changes: any }) {
  }
}

export class MessageStatusDeliveredBulkUpdateAction implements Action {
  readonly type = MessageActionTypes.MESSAGE_STATUS_UPDATE_DELIVERED;

  constructor(public payload: string[]) {
  }
}

export class MessageDeletedStatusUpdateAction implements Action {
  readonly type = MessageActionTypes.MESSAGE_DELETED_STATUS_UPDATE;

  constructor(public payload: { id: string, isDeleted: boolean, convTarget: string }) {
  }
}

export class MultiConversationMessageDeletedStatusUpdateAction implements Action {
  readonly type = MessageActionTypes.MULTI_CONVERSATION_MESSAGE_DELETED_STATUS_UPDATE;

  constructor(public payload: { id: string, isDeleted: boolean }[]) {
  }
}

export class MessageFavoriteUpdateAction implements Action {
  readonly type = MessageActionTypes.MESSAGE_FAVORITE_UPDATE;

  constructor(public payload: { id: string, isStarred: boolean }) {
  }
}


export class SelectMessage implements Action {
  readonly type = MessageActionTypes.SELECT_MESSAGE;

  constructor(public payload: string) {
  }
}

export class UnselectMessage implements Action {
  readonly type = MessageActionTypes.UNSELECT_MESSAGE;

  constructor(public payload: string) {
  }
}

export class ResetMessages implements Action {
  readonly type = MessageActionTypes.RESET_MESSAGES;
}

export class ResetUnreadCount implements Action {
  readonly type = MessageActionTypes.RESET_UNREAD_COUNT;
}

export class DeleteMessages implements Action {
  readonly type = MessageActionTypes.MESSAGE_BULK_DELETE;

  constructor(public payload: { messageIds: string[], persistentConversationIds: string[], nonPersistentConversationIds: string[] }) {
  }

}
