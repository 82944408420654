<main class="doc-preview tile-view" [ngClass]="{'read': !document.unread}" (click) = "quickPreview('view')">
    <header class="tile-header">
      <section class="flex-cove" style="height: 45px;">
        <div class="flex-child tile-avatar-img">
          <div class="status-indicator"></div>
          <vp-avatar [type] = "'article-avatar'" [chatType]="document?.chatType" [user] = "{'email': document?.from }">
          </vp-avatar>
          <img class="app-logo-mini" src="assets/icon/new-icons/vncmail.svg" *ngIf="document.unread" />
          <img class="app-logo-mini" src="assets/icon/new-icons/vncmail-grayscale.svg" *ngIf="!document.unread"/>
        </div>
        <div class="flex-child">
          <h5 class="tile-header-title" *ngIf="documentTitle">{{document.title}}</h5>
          <h5 class="tile-header-title" *ngIf="!documentTitle && authorName">{{authorName}}</h5>
          <h5 class="tile-header-title" *ngIf="!documentTitle && !authorName">{{this.document.from}}</h5>
        </div>
        <div class="flex-child" *ngIf = "document.metadata && !document.metadata.isStarred">
          <vnc-icon type='product' name='favorites-new' width='12px' height='12px' (click) = "$event.stopPropagation();addStar(document)" class="icon-style"></vnc-icon>
        </div>
        <div class="flex-child" *ngIf = "document.metadata && document.metadata.isStarred">
          <vnc-icon type='product' name='favorites-filled-new' width='12px' height='12px' (click) = "$event.stopPropagation();removeStar(document)" style="color: #ffa800;" class="icon-style"></vnc-icon>
        </div>        
        <div class="flex-child"> 
          <button (click) = "$event.stopPropagation();" mat-icon-button class = "more-action" [matMenuTriggerFor] = "mailOptionMenu">
            <vnc-icon type='product' name='more' width='18px' height='18px' class="icon-style"></vnc-icon>
          </button>
  
          <mat-menu #mailOptionMenu = "matMenu" class="mat-menu-cover">
            <button mat-menu-item (click) = "$event.stopPropagation();openApp()" mat-menu-item>
              <vnc-icon type='product' name='open-in-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'OPEN_IN_APP' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();openReplyAllMessage()">
              <vnc-icon type='product' name='reply-all-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'REPLY_ALL_LBL' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();openForwardMessage()">
              <vnc-icon type='product' name='forward' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'FORWARD_LBL' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='stickynote-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'STICKY_NOTE' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='attachment' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'BROWSE_ATTACHES' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='block' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'MOVE_TO_SPAM' | translate}}</span>
            </button>
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='print' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'PRINT' | translate}}</span>
            </button>             
            <button mat-menu-item (click) = "$event.stopPropagation();underDevelopment()">
              <vnc-icon type='product' name='delete-new' width='18px' height='18px' class = "tile-mat-icon"></vnc-icon>
              <span class="action-text">{{ 'DELETE' | translate}}</span>
            </button>          
          </mat-menu>     
        </div>  
      </section>
      <section class="flex-cove">
        <div class="head-details flex-cove tile-header-title">
          <div class="user-txt" *ngIf="authorName">{{authorName}}</div>
          <div class="user-txt" *ngIf="!authorName">{{document.from}}</div>
          <div class="head-dt">{{ document?.createdDt | vpDisplayTime : "MMM DD, YYYY hh:mm" }}</div>
        </div>
      </section>
    </header>
  
    <section class="tile-body">
      <div [innerHTML] = "renderContent(document.shortContent) | vpSafeHtml" [ngClass]="document.type === 'mail' ? 'show-less' : ''" class="mail-body"></div>
    </section>
  
    <footer class="tile-footer">
      <div class="flex-cove">
        <vnc-icon type='product' name='tag-new' width='18px' height='18px' style="margin-top: 7px; color: #606b76;"></vnc-icon>
        <vnc-chips size="s" type="static" text="VNCmail" class="chip-pos"></vnc-chips>
      </div>

      <section class="tile-icon-container" [ngClass]="{'read': !document.unread}">
        <div class="flex-cove user-ac" *ngIf ="document.unread" (click) = "$event.stopPropagation();markAsRead()" title="mark as read">
          <vnc-icon type='product' name='markasread-new' width='18px' height='18px' class="act-icon"></vnc-icon> <p class="icon-wrap">mark as read</p>
        </div>
        <div class="flex-cove user-ac" *ngIf ="!document.unread" (click) = "$event.stopPropagation();markAsUnRead()" title="mark as unread" [ngClass]="{'add-space': !document.unread}">
            <vnc-icon type='product' name='markasread-new' width='18px' height='18px' class="act-icon"></vnc-icon> <p class="icon-wrap">mark as unread</p>
          </div>
        <div class="flex-cove user-ac" *ngIf ="document.unread" (click) = "$event.stopPropagation();openReplyMessage()" title="reply">
          <vnc-icon type='product' name='reply-new' width='18px' height='18px' class="act-icon"></vnc-icon> <p class="icon-wrap">reply</p>
        </div>
        <div class="flex-cove user-ac" (click) = "$event.stopPropagation();underDevelopment()" title="replies"> 
          <p class="icon-wrap">3 replies</p><vnc-icon type='product' name='keyboard-arrow-down-new' width='18px' height='18px' class="act-icon"></vnc-icon>
        </div>
      </section>
    </footer>
  </main>