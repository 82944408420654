
<!--
~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
~
~ This program is free software: you can redistribute it and/or modify
~ it under the terms of the GNU Affero General Public License as published by
~ the Free Software Foundation, version 3 of the License.
~
~ This program is distributed in the hope that it will be useful,
~ but WITHOUT ANY WARRANTY; without even the implied warranty of
~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
~ GNU Affero General Public License for more details.
~
~ You should have received a copy of the GNU Affero General Public License
~ along with this program. Look for COPYING file in the top folder.
~ If not, see http://www.gnu.org/licenses/.
-->

<div class="legal_notice_dialog commander-dialog">
  <div class="commander__dialog-header mobile_header">
    <div>
      <button id="mobile_sidebar_legal_notice_backbtn" mat-button (click)="close()">
        <mat-icon class="disable-select">arrow_back</mat-icon>
      </button>
    </div>
    <div id="mobile_sidebar_header_header" class="header_lbl disable-select">
      {{ 'LEGAL_NOTICE' | translate }}
    </div>
    <div class="submit"></div>
  </div>
  <div class="commander__dialog-header desktop_view">
    <div>
      <button mat-button>
        <mat-icon class="disable-select">info</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{ 'LEGAL_NOTICE' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="commander__dialog-body commander__dialog-body--pad">
    <div class="content">
      <mat-list>
        <mat-list-item id="mobile_sidebar_help_user_manual">
            <mat-icon class="disable-select">assignment</mat-icon>
            <a target="_blank" class="open-new-window disable-select non-decoration" href="{{termsOfUse}}">{{ 'TERMS_OF_USE' | translate }}</a>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_help_service_desk">
            <mat-icon class="disable-select">lock</mat-icon>
            <a target="_blank" class="open-new-window disable-select non-decoration" href="{{dataPrivacy}}">{{ 'DATA_PRIVACY' | translate }}</a>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
