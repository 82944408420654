/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AppRepository } from "../repositories/app.repository";
import { AppModel } from "../common/models/app.model";
import { filter, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
// import { CreateNewDialogComponent } from "../create-new-dialog/create-new-dialog.component";
import { Tag } from "../common/models/tag.model";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "vp-sidebar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnInit {
  @Output() sidebarOpened = new EventEmitter();
  isSidebarOpened = true;
  availableApps: AppModel[] = [];
  activeApps: string[] = [];
  tags: Tag[] = [];
  hideTags = false;
  smartSearches: any[] = [];
  hideSearches = false;
  hideCalendar = false;
  expandFull = "";
  isSearchView: boolean;
  viewDate: Date = new Date();
  currentLocale: string = "en";
  showWeekNumberInMiniCalendar: boolean = false;
  firstDayOfWeek: number = 1;
  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private appRepository: AppRepository,
    private matDialog: MatDialog) { }

  ngOnInit() {
    this.appRepository.getFederatedApps().subscribe(apps => {
      this.availableApps = apps;
      this.changeDetectorRef.markForCheck();
    });


    this.isSearchView = this.router.url.startsWith("/search");
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val) => {

      this.isSearchView = this.router.url.startsWith("/search");
      this.changeDetectorRef.markForCheck();
    });

    this.appRepository.getActiveApps().subscribe(apps => {
      console.log("sidebar.getActiveApps: ", apps);
      this.activeApps = apps;
      this.changeDetectorRef.markForCheck();
    });

    // this.appRepository.getTags().subscribe(tags => {

    //   this.tags = tags;
    //   this.changeDetectorRef.markForCheck();
    // });

    this.appRepository.getGlobalTags().subscribe(tags => {
      this.tags = tags;
      this.changeDetectorRef.markForCheck();
    });

    // this.appRepository.getAppsCount().subscribe(apps => {
    //   const data = [];
    //   this.appRepository.getFederatedApps().pipe(filter(v => !!v && v.length > 0), take(1)).subscribe(availableApps => {
    //     availableApps.forEach(val => {
    //       let appsCount: any = {};
    //       const total = apps[val.appKey] || 0;
    //       appsCount.total = total;
    //       appsCount.displayTotal = total > 99 ? "99+" : total.toString();
    //       val = {...val, ...appsCount};
    //       data.push(val);

    //     });
    //     this.availableApps = data;

    //     this.changeDetectorRef.markForCheck();
    //   });
    // });

    this.appRepository.getAppsUnreadCount().subscribe(apps => {
      const data = [];
      this.appRepository.getFederatedApps().pipe(filter(v => !!v && v.length > 0), take(1)).subscribe(availableApps => {
        availableApps.forEach(val => {
          let appsCount: any = {};
          const unreadCount = apps[val.appKey] || 0;
          appsCount.unreadCount = unreadCount;
          appsCount.displayUnreadCount = unreadCount > 99 ? "99+" : unreadCount.toString();
          val = {...val, ...appsCount};
          data.push(val);
        });
        this.availableApps = data;
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  setAppsCount() {
    this.appRepository.getAppsCount().pipe(take(1)).subscribe(apps => {
      this.availableApps.forEach(val => {
        let appsCount: any = {};
        const total = apps[val.appKey] || 0;
        appsCount.total = total;
        appsCount.displayTotal = total > 99 ? "99+" : total.toString();
        val = {...val, ...appsCount};
      });

      this.changeDetectorRef.markForCheck();
    });
  }

  toggleSidebar() {
    this.isSidebarOpened = !this.isSidebarOpened;
    this.changeDetectorRef.markForCheck();
    this.sidebarOpened.emit(this.isSidebarOpened);

  }

  toggleApp(app) {
    console.log("sidebar.toggleApp: ", app);
    this.appRepository.toggleActiveApp(app.appKey);
  }

  async createNewDialog() {
    const { CreateNewDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../create-new-dialog/create-new-dialog.component"
      );
    this.matDialog.open(CreateNewDialogComponent, {
      width: "100vw",
      minWidth: "100vw",
      height: "100vh",
      autoFocus: true,
      panelClass: ["commander__full_dialog", "create__app"]
    });
  }

  filterByTag(tag) {
    this.appRepository.underDevelopment();
  }

  calendarTimeChangedOnDatePicker(event: any): void {
    this.viewDate = event.day.date;
    this.changeDetectorRef.markForCheck();
  }

  filterBySearch(search) {
    this.appRepository.underDevelopment();
  }
}
