<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<ng-container *ngIf="document.type === 'mail'">
  <vp-doc-mail-preview [document]="document"></vp-doc-mail-preview>
</ng-container>

<ng-container *ngIf="document.type === 'talk'">
  <vp-doc-talk-preview [document]="document"></vp-doc-talk-preview>
</ng-container>

<ng-container *ngIf="document.type === 'issue'">
  <vp-doc-task-preview [document]="document"></vp-doc-task-preview>
</ng-container>
