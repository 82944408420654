
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<a  class="wdt-emoji-popup-mobile-closer"> × </a>
<div class="wdt-emoji-menu-content">
  <div id="wdt-emoji-menu-header">
    <a class="wdt-emoji-tab active" data-group-name="Recent"></a>
    <a class="wdt-emoji-tab" data-group-name="People"></a>
    <a class="wdt-emoji-tab" data-group-name="Nature"></a>
    <a class="wdt-emoji-tab" data-group-name="Foods"></a>
    <a class="wdt-emoji-tab" data-group-name="Activity"></a>
    <a class="wdt-emoji-tab" data-group-name="Places"></a>
    <a class="wdt-emoji-tab" data-group-name="Objects"></a>
    <a class="wdt-emoji-tab" data-group-name="Symbols"></a>
    <a class="wdt-emoji-tab" data-group-name="Flags"></a>
  </div>
  <div class="wdt-emoji-scroll-wrapper">
    <div id="wdt-emoji-menu-items">
      <div class="wdt-emoji-sections"></div>
    </div>
  </div>
  <div id="wdt-emoji-footer">
    <div id="wdt-emoji-preview">
      <span id="wdt-emoji-preview-img"></span>
      <div id="wdt-emoji-preview-text">
        <span id="wdt-emoji-preview-name"></span><br>
        <span id="wdt-emoji-preview-aliases"></span>
      </div>
    </div>
  </div>
</div>
