
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class MailConstants {
  static SENT_SUCCESS_MSG = "SENT_SUCCESS_MSG";
  static SAVE_TO_DRAFT_MSG = "SAVE_TO_DRAFT_MSG";
  static SUBJECT_REQUIRE_MSG = "SUBJECT_REQUIRE_MSG";
  static MAIL_INFO_REQUIRE = "MAIL_INFO_REQUIRE";
  static TEXT_EDITOR_PLACEHOLDER = "TEXT_EDITOR_PLACEHOLDER";
  static ATTACHMENT_NAME = "attachment";
  static SERVER_URL = "serverURL";
  static BROADCAST_OVERFLOW_REDIRECT_DIALOG = "BROADCAST_OVERFLOW_REDIRECT_DIALOG";
  static CLOSE_AUTO_COMPLETE = "CLOSE_AUTO_COMPLETE";
  static BOLD_LBL = "BOLD_LBL";
  static ITALIC_LBL = "ITALIC_LBL";
  static UNDERLINE_LBL = "UNDERLINE_LBL";
  static STRIKE_LBL = "STRIKE_LBL";
  static TEXT_COLOR_LBL = "TEXT_COLOR_LBL";
  static BACKGROUND_COLOR_LBL = "BACKGROUND_COLOR_LBL";
  static BULLETS_LIST_LBL = "BULLETS_LIST_LBL";
  static NUMBERED_LIST_LBL = "NUMBERED_LIST_LBL";
  static LINK_UNLINK_LBL = "LINK_UNLINK_LBL";
  static INLINE_IMAGE_LBL = "INLINE_IMAGE_LBL";
  static FONT_FAMILY_LBL = "FONT_FAMILY_LBL";
  static BLOCK_QUOTE_LBL = "BLOCK_QUOTE_LBL";
  static BLOCK_CODE_LBL = "BLOCK_CODE_LBL";
  static HEADER_1_LBL = "HEADER_1_LBL";
  static HEADER_2_LBL = "HEADER_2_LBL";
  static SUB_LBL = "SUB_LBL";
  static SUPER_LBL = "SUPER_LBL";
  static DECREASE_INDENT_LBL = "DECREASE_INDENT_LBL";
  static INCREASE_INDENT_LBL = "INCREASE_INDENT_LBL";
  static CLEAN_FORMATTING = "CLEAN_FORMATTING";
  static MAIL_COMPOSE_REPLY = "Reply";
  static MAIL_COMPOSE_REPLY_ALL = "ReplyAll";
  static REPLY_PREFIX_LBL = "Re:";
  static ORIGINAL_MSG_LBL = "ORIGINAL_MSG_LBL";
  static SHARE_ITEM_LBL = "SHARE_ITEM_LBL";
  static OWNER_LBL = "OWNER_LBL";
  static ROLE_LBL = "ROLE_LBL";
  static SUBJECT_MAIL_LABEL = "SUBJECT_MAIL_LABEL";
  static FROM_MAIL_LABEL = "FROM_MAIL_LABEL";
  static VIEWER_LBL = "VIEWER_LBL";
  static DICLINED_LBL = "DICLINED_LBL";
  static DICLINED_SHARING_OF = "DICLINED_SHARING_OF";
  static HAS_SHARED = "HAS_SHARED";
  static WITH_LBL = "WITH_LBL";
  static HAS_MODIFIED = "HAS_MODIFIED";
  static ACCEPTED_LBL = "ACCEPTED_LBL";
  static HAS_ACCEPTED_SHARING = "HAS_ACCEPTED_SHARING";
  static SENT_FOLDER_TITLE = "Sent";
  static TO_MAIL_LABLE = "TO_MAIL_LABLE";
  static MAIL_COMPOSE_FORWARD = "forward";
  static FORWARD_MSG_LBL = "FORWARD_MSG_LBL";
  static FORWARD_PREFIX_LBL = "Fwd:";
  static ALLOWED_ACTIONS = "ALLOWED_ACTIONS";
  static CC_MAIL_LABLE = "CC_MAIL_LABLE";
  static BCC_MAIL_LABLE = "BCC_MAIL_LABLE";
  static SYSTEM_FOLDERS = ["INBOX_FOLDER", "SENT_FOLDER", "DRAFTS_FOLDER", "STARRED_FOLDER", "SPAM_FOLDER", "TRASH_FOLDER",
  "BRIEFCASE_FOLDER"];
  static ATTACHMENT_EXCEED_LIMIT_MSG = "ATTACHMENT_EXCEED_LIMIT_MSG";
}
