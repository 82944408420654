/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, NgZone, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MobileHelpDialogComponent } from "src/app/shared/components/mobile-help-dialog/mobile-help-dialog.component";
import { GeneralSettingsDialogComponent } from "src/app/shared/components/general-settings-dialog/general-settings-dialog.component";
import { MobilePreferencesDialogComponent } from "src/app/shared/components/mobile-preferences-dialog/mobile-preferences-dialog.component";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AppRepository } from "../../repositories/app.repository";
import { take } from "rxjs/operators";
import { Broadcaster } from "../../common/providers/broadcaster.service";

@Component({
  selector: "vp-mobile-filters-dialog",
  templateUrl: "./mobile-filters-dialog.component.html"
})
export class MobileFiltersDialogComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  availableApps: any[] = [];
  includedApps = {};

  controls = {
    appsToInclude: new UntypedFormControl([]),
    groupBy: new UntypedFormControl("none"),
    orderBy: new UntypedFormControl("created_dt desc"),
    viewBy: new UntypedFormControl("condense"),
    showOnlyUnread: new UntypedFormControl(false),
    hideAllComments: new UntypedFormControl(false),
  };

  filtersForm = new UntypedFormGroup(this.controls);

  constructor(
    public dialogRef: MatDialogRef<MobileFiltersDialogComponent>,
    private appRepository: AppRepository,
    private broadcaster: Broadcaster,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // const filters = this.data.filters;
    // this.filtersForm.setValue(filters);
  }

  ngOnInit() {
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }

    this.appRepository.getFederatedApps().subscribe(apps => {
      this.availableApps = apps;
    });

    this.appRepository.getFilters().pipe(take(1)).subscribe(v => {
      if (!!v) {
        if (v.filters) {
          this.filtersForm.patchValue({
            groupBy: v.filters.groupBy,
            viewBy: v.filters.viewBy,
            orderBy: v.filters.orderBy,
            showOnlyUnread: v.filters.showOnlyUnread,
            hideAllComments: v.filters.hideAllComments
          });
        }

        let includedApps = {};
        if (v.apps) {
          v.apps.forEach(app => {
            includedApps[app] = true;
          });
          this.includedApps = includedApps;
        }
      }
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  applyFilters() {
    const apps = Object.keys(this.includedApps).filter(app => this.includedApps[app]);
    this.controls.appsToInclude.setValue(apps);
    const filters = this.filtersForm.getRawValue();
    
    this.appRepository.setViewBy(filters.viewBy);
    this.appRepository.setUnreadOnly(filters.showOnlyUnread);
    this.appRepository.setOrderBy(filters.orderBy);
    this.appRepository.setGroupBy(filters.groupBy);
    this.appRepository.setActiveApps(apps);
    this.appRepository.setFilters({apps, filters});
    this.broadcaster.broadcast("triggerSearch");
    this.dialogRef.close(this.filtersForm.value);
  }
}
