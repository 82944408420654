/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { MetaComment } from "../common/models/comment.model";

export class CommentActionTypes {
  static COMMENT_ADD = "[COMMENT] Add";
  static MULTI_CONVERSATION_COMMENT_ADD = "[COMMENT] Multi Conversation Add";
  static COMMENT_STATUS_UPDATE = "[COMMENT] Status Update";
  static COMMENT_UPDATE = "[COMMENT] Update";
  static COMMENT_STATUS_UPDATE_DELIVERED = "[COMMENT] Delivered Status Update";
  static COMMENT_DELETED_STATUS_UPDATE = "[COMMENT] Deleted Status Update";
  static COMMENT_BULK_APPEND = "[COMMENT] Bulk Append";
  static COMMENT_BULK_APPEND_MULTI_CONVERSATION = "[COMMENT] Bulk Append MultiConversation";
  static COMMENT_BULK_LOADING = "[COMMENT] Bulk Loading";
  static COMMENT_BULK_LOADED = "[COMMENT] Bulk Loaded";
  static COMMENT_BULK_LOADING_FAILED = "[COMMENT] Bulk Loading Failed";
  static COMMENT_DELETE_ACTION = "[COMMENT] Delete";
  static COMMENT_LAST_PAGE_LOADED = "[COMMENT] Last Page Loaded";
  static SELECT_COMMENT = "[COMMENT] Select comment";
  static UNSELECT_COMMENT = "[COMMENT] Unselect comment";
  static RESET_COMMENTS = "[COMMENT] Reset comments";
  static COMMENT_BULK_DELETE = "[COMMENT] Delete bulk comments";
  static SET_COMMENT_TO_REPLY = "[COMMENT] Set Comment to reply";

}

export class CommentAdd implements Action {
  readonly type = CommentActionTypes.COMMENT_ADD;

  constructor(public payload: { objectId: string, comment: MetaComment }) {
  }
}

export class MultiConversationCommentAdd implements Action {
  readonly type = CommentActionTypes.MULTI_CONVERSATION_COMMENT_ADD;

  constructor(public payload: { objectId: string, comment: MetaComment, incoming?: boolean }[]) {
  }
}

export class CommentBulkAppendMultiConversation implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_APPEND_MULTI_CONVERSATION;

  constructor(public payload: {
    objectId: string,
    comments: MetaComment[]
  }[]) {
  }
}

export class CommentBulkAppend implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_APPEND;

  constructor(public payload: {
    objectId: string,
    comments: MetaComment[]
  }) {
  }
}

export class CommentLastPageLoaded implements Action {
  readonly type = CommentActionTypes.COMMENT_LAST_PAGE_LOADED;

  // payload is objectId
  constructor(public payload: string) {
  }
}

export class CommentBulkLoading implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_LOADING;

  // payload is objectId
  constructor(public payload: string) {
  }
}

export class CommentBulkLoaded implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_LOADED;

  // payload is objectId
  constructor(public payload: string) {
  }
}


export class CommentBulkLoadingFailed implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_LOADING_FAILED;

  // payload is objectId
  constructor(public payload: string) {
  }
}

export class CommentDeleteAction implements Action {
  readonly type = CommentActionTypes.COMMENT_DELETE_ACTION;

  // payload is Comment id
  constructor(public payload: string) {
  }
}

export class CommentUpdateAction implements Action {
  readonly type = CommentActionTypes.COMMENT_UPDATE;

  constructor(public payload: { id: string, changes: any }) {
  }
}

export class DeleteComments implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_DELETE;
  constructor(public payload: { commentIds: string[] }) {
  }

}
