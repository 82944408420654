/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

// import * as _ from "lodash";
import uniq from "lodash/uniq";
import { Action } from "../actions";
import { MessageActionTypes } from "../actions/message";
import { Message } from "../common/models/message.model";
import { ConversationActionTypes } from "../actions/conversation";
import { AppActionTypes } from "../actions/app";
import { Conversation } from "../common/models/conversation.model";

export interface ConversationMessageState {
  ids: { [conversationTarget: string]: string[] };
  loading: { [conversationTarget: string]: boolean };
  loaded: { [conversationTarget: string]: boolean };

  isOnLastPage: { [conversationTarget: string]: boolean };

  unreadCount: { [conversationTarget: string]: string[] };
}

const initialState: ConversationMessageState = {
  ids: {},
  loading: {},
  loaded: {},
  isOnLastPage: {},
  unreadCount: {}
};

export function conversationMessageReducer(state = initialState, action: Action): ConversationMessageState {
  switch (action.type) {
    case MessageActionTypes.MESSAGE_ADD: {
      const payload = action.payload;
      const conversationTarget = payload.conversationTarget;
      const message = payload.message as Message;

      // If receiving new message on new conversation
      const oldMessages = state.ids[conversationTarget] ? state.ids[conversationTarget] : [];

      const messageIds = uniq([...oldMessages, message.id]);

      return {
        ...state,
        ids: {
          ...state.ids,
          [conversationTarget]: messageIds
        }
      };
    }

    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD: {
      const data = action.payload;

      let localState = { ...state };
      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const message = item.message as Message;

        // If receiving new message on new conversation
        const oldMessages = localState.ids[conversationTarget] ? localState.ids[conversationTarget] : [];

        const messageIds = uniq([...oldMessages, message.id]);

        localState = {
          ...localState,
          ids: {
            ...localState.ids,
            [conversationTarget]: messageIds
          }
        };
      });

      return localState;
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND: {
      const payload = action.payload;
      const conversationTarget = payload.conversationTarget;

      const messages = payload.messages as Message[];
      const oldMessageIds = state.ids[conversationTarget] ? state.ids[conversationTarget] : [];

      const messageIds = uniq([...oldMessageIds, ...messages.map(m => m.id)]);

      return {
        ...state,
        ids: {
          ...state.ids,
          [conversationTarget]: messageIds
        },
        loading: {
          ...state.loading,
          [conversationTarget]: false
        },
        loaded: {
          ...state.loaded,
          [conversationTarget]: true
        }
      };
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let localState = { ...state };
      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const oldMessages = localState.ids[conversationTarget] ? localState.ids[conversationTarget] : [];

        const messages = item.messages || [];
        const newMessageIds = messages.map(m => m.id);
        const messageIds = uniq([
          ...oldMessages,
          ...newMessageIds
        ]);

        localState = {
          ...localState,
          ids: {
            ...localState.ids,
            [conversationTarget]: messageIds
          },
          loading: {
            ...localState.loading,
            [conversationTarget]: false
          }
        };
      });
      return localState;
    }

    case MessageActionTypes.MESSAGE_BULK_LOADING: {
      const conversationTarget = action.payload;

      return {
        ...state,
        loading: {
          ...state.loading,
          [conversationTarget]: true
        }
      };
    }

    case MessageActionTypes.MESSAGE_BULK_LOADED: {
      const conversationTarget = action.payload;

      return {
        ...state,
        loading: {
          ...state.loading,
          [conversationTarget]: false
        }
      };
    }

    case MessageActionTypes.MESSAGE_BULK_LOADING_FAILED: {
      const conversationTarget = action.payload;


      return {
        ...state,
        loading: {
          ...state.loading,
          [conversationTarget]: false
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UNREAD_COUNT_INCREMENT: {
      const conversationTarget = action.payload.conversationTarget;
      const messageIds = action.payload.messageIds;
      const oldMessageIds = state.unreadCount[conversationTarget] ? state.unreadCount[conversationTarget] : [];
      const newMessageIds = uniq([...oldMessageIds, ...messageIds]);

      return {
        ...state,
        unreadCount: {
          ...state.unreadCount,
          [conversationTarget]: newMessageIds
        }
      };
    }

    case MessageActionTypes.RESET_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: {}
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_UNREAD_COUNT: {
      const conversationTarget = action.payload.conversationTarget;
      const messageIds = action.payload.messageIds;
      return {
        ...state,
        unreadCount: {
          ...state.unreadCount,
          [conversationTarget]: messageIds
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_BULK_UNREAD_COUNT_INCREMENT: {

      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const messageIds = item.messageIds;
        const oldMessageIds = localState.unreadCount[conversationTarget] ? localState.unreadCount[conversationTarget] : [];
        const newMessageIds = uniq([...oldMessageIds, ...messageIds]);

        localState = {
          ...localState,
          unreadCount: {
            ...localState.unreadCount,
            [conversationTarget]: newMessageIds
          }
        };
      });

      return { ...localState };
    }

    case ConversationActionTypes.CONVERSATION_SELECT: {
      const conversationTarget = action.payload;
      const oldUnreadCount = state.unreadCount[conversationTarget] ? state.unreadCount[conversationTarget] : 0;

      if (oldUnreadCount === 0) {
        return state;
      }

      return {
        ...state,
        unreadCount: {
          ...state.unreadCount,
          [conversationTarget]: []
        }
      };
    }

    case MessageActionTypes.MESSAGE_LAST_PAGE_LOADED: {
      return {
        ...state,
        isOnLastPage: {
          ...state.isOnLastPage,
          [action.payload]: true
        },
        loading: {
          ...state.loading,
          [action.payload]: false
        }
      };
    }

    case ConversationActionTypes.RESET_LAST_PAGE_LOADED: {
      return {
        ...state,
        isOnLastPage: {
          ...state.isOnLastPage,
          [action.payload]: false
        }
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.conversationMessageState;
      return savedState ? { ...state, ...savedState } : state;
    }


    case ConversationActionTypes.RESET_UNREAD_MESSAGE_COUNT: {
      const conversationTarget = action.payload;
      const messageIds = state.unreadCount[conversationTarget] || [];

      if (messageIds.length === 0) {
        return state;
      }

      return {
        ...state,
        unreadCount: {
          ...state.unreadCount,
          [conversationTarget]: []
        }
      };
    }

    case ConversationActionTypes.RESET_UNREAD_MESSAGE_COUNT_FORCE: {
      const conversationTarget = action.payload;

     

      return {
        ...state,
        unreadCount: {
          ...state.unreadCount,
          [conversationTarget]: []
        }
      };
    }

    case MessageActionTypes.MESSAGE_BULK_DELETE: {
      const persistentConversationIds = action.payload.persistentConversationIds;
      const nonPersistentConversationIds = action.payload.nonPersistentConversationIds;
      const messageIds = action.payload.messageIds;
      let ids = { ...state.ids };

      nonPersistentConversationIds.forEach(conv => {
        ids[conv] = [];
      });

      persistentConversationIds.forEach(conv => {
        if (ids[conv] && ids[conv].length > 0) {
          ids[conv] = ids[conv].filter(id => !messageIds.includes(id));
        }
      });

      return {
        ...state,
        ids: { ...ids }
      };
    }

    case ConversationActionTypes.CONVERSATION_UNREAD_COUNTS: {
      return {
        ...state,
        unreadCount: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getConversationMessageIds = (state: ConversationMessageState, target: string) => {
  return state.ids && state.ids[target] ? state.ids[target] : [];
};

export const _getIsConversationMessagesLoaded = (state: ConversationMessageState, conversation: Conversation) => {
  return conversation && state.loaded && state.loaded[conversation.Target];
};

export const _getIsConversationMessagesLoading = (state: ConversationMessageState, conversation: Conversation) => {
  return conversation && state.loading && state.loading[conversation.Target];
};

export const _getIsConversationMessagesOnLastPage = (state: ConversationMessageState, conversation: Conversation) => {
  if (!conversation) {
    return true;
  }

  return state.isOnLastPage[conversation.Target] ? state.isOnLastPage[conversation.Target] : false;
};


export const _getAllConversationsUnreadCount = (state: ConversationMessageState) => {
  const unreadCount = {};
  for (let key of Object.keys(state.unreadCount)) {
    unreadCount[key] = state.unreadCount[key] ? state.unreadCount[key].length : 0;
  }
  return unreadCount;
};

export const _getConversationUnreadCount = (state: ConversationMessageState, conversation: Conversation) => {
  return conversation && state.unreadCount && state.unreadCount[conversation.Target] ? state.unreadCount[conversation.Target].length : 0;
};

export const _getUnreadCount = (state: ConversationMessageState) => {
  return state.unreadCount;
};
