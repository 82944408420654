/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ConfigService } from "src/app/config.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { AppRepository } from "src/app/repositories/app.repository";
import { LegalNoticeDialogComponent } from "src/app/shared/components/legal-notice-dialog/legal-notice-dialog.component";
// import { VersionDialogComponent } from "src/app/shared/components/version-dialog/version-dialog.component";

@Component({
  selector: "vp-mobile-help-dialog",
  templateUrl: "./mobile-help-dialog.component.html"
})
export class MobileHelpDialogComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  userManualUrl = "";
  faqUrl = "";
  serviceDeskUrl = "";

  constructor(
    public dialogRef: MatDialogRef<MobileHelpDialogComponent>,
    private matDialog: MatDialog,
    // private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private appRepository: AppRepository
  ) {
    // this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ABOUT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
    //   this.ngZone.run(() => {
    //     this.close();
    //   });
    // });
    this.appRepository.getLanguage().subscribe(lang => {
     
      this.setUrls(lang);
    });
  }

  setUrls(browserLang) {
    if (this.config.URLS.serviceDesk) {
      this.serviceDeskUrl = this.config.URLS.serviceDesk;
    } else {
      this.serviceDeskUrl = "https://redmine.vnc.biz/helpdesk/incidents/new";
    }
    if (browserLang === "en") {
      if (this.config.URLS.faq) {
        this.faqUrl = this.config.URLS.faq;
      } else {
        this.faqUrl = "https://portal.vnc.biz/product-area/faq/vnccommander-faq";
      }

      if (this.config.URLS.userManual) {
        this.userManualUrl = this.config.URLS.userManual;
      } else {
        this.userManualUrl = "https://en.docs.vnc.biz/vnccommander/usermanual/";
      }

    } else {
      if (this.config.URLS.faq_de) {
        this.faqUrl = this.config.URLS.faq_de;
      } else {
        this.faqUrl = "https://portal.vnc.biz/product-area/faq/vnccommander-faq-de";
      }

      if (this.config.URLS.userManual_de) {
        this.userManualUrl = this.config.URLS.userManual;
      } else {
        this.userManualUrl = "https://de.docs.vnc.biz/vnccommander/usermanual/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  async versionInfoDialog() {
    const { VersionDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/version-dialog/version-dialog.component"
      );
    this.matDialog.open(VersionDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async legalNoticeDialog() {
    const { LegalNoticeDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/legal-notice-dialog/legal-notice-dialog.component"
      );
    this.matDialog.open(LegalNoticeDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }
}
