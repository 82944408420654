/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ConfigService } from "src/app/config.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { AppRepository } from "src/app/repositories/app.repository";

@Component({
  selector: "vp-legal-notice-dialog",
  templateUrl: "./legal-notice-dialog.component.html"
})
export class LegalNoticeDialogComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  termsOfUse = "";
  dataPrivacy = "";

  constructor(
    public dialogRef: MatDialogRef<LegalNoticeDialogComponent>,
    private matDialog: MatDialog,
    // private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private appRepository: AppRepository
  ) {
    // this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ABOUT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
    //   this.ngZone.run(() => {
    //     this.close();
    //   });
    // });
    this.appRepository.getLanguage().subscribe(lang => {
     
      this.setUrls(lang);
    });
  }

  setUrls(browserLang) {
    if (browserLang === "en") {
      if (this.config.URLS.LegalNoticeTermOfUse) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }

    } else {
      if (this.config.URLS.LegalNoticeTermOfUse_de) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse_de;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/Nutzungsbedingungen/";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy_de) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy_de;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }
}
