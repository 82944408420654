
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="attachment_list">
  <div *ngFor="let attachment of attachmentList ; let i= index; trackBy: trackByIndex" class=" attachment_item">
    <div class="extension">{{getFileTypeExtension(attachment.ct)}}</div>
    <div class="name_size" (click)="openAttachment(attachment)">
      <div class="name">{{ attachment.filename }}</div>
      <div class="size">({{ (attachment.s && attachment.s !== 0 ) ? (attachment.s | vpFormatFileSize : false) : "0 kb" }})</div>
    </div>
    <div class="close" (click)="removeAttachment(i)">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
