
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="side-bar-menu">
  <vp-filters-sidebar *ngIf="isSearchView"></vp-filters-sidebar>
  <ng-container *ngIf="!isSearchView">
    <div class="create-new-object" (click)="createNewDialog()">
      <button mat-fab id="createNewButton">
        <mat-icon class="mat-18">add</mat-icon>
      </button>
      <div class="object-action">
        <div class="action-name">{{ 'CREATE_NEW' | translate }}</div>
        <div class="action-description">{{ 'SUPPORTED_APPS' | translate }}</div>
      </div>
    </div>
    <!-- <div class="sidebar-main-block"> -->
    <div class="sidebar-block" [class.expand-full]="expandFull && expandFull === 'apps'" [class.hide-block]="expandFull && expandFull !== 'apps'">
      <div class="block-heading">
        {{ 'FILTER' | translate }}
      </div>
      <div class="block-subheading">
        {{ 'APPS_TO_INCLUDE' | translate }}
      </div>
      <div class="block-content">
        <mat-list role="list" class="app-list">
          <ng-container *ngFor="let app of availableApps">
            <mat-list-item (click)="toggleApp(app)" [class.selected]="activeApps.indexOf(app.appKey) !== -1" role="listitem"  [ngClass]="app.appNameLowercase"
              *ngIf="app.appName !== 'VNCcontacts'">
              <img src="/assets/icon/new-icons/{{ app.appName | lowercase }}.svg" /> {{ app.appName }}
              <span *ngIf="app.unreadCount && activeApps.indexOf(app.appKey) !== -1" class="app-total-count" title="{{ app.unreadCount }}">{{ app.unreadCount }}</span>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
    </div>
    <div class="sidebar-block" [class.expand-full]="expandFull && expandFull === 'tags'" [class.hide-block]="expandFull && expandFull !== 'tags'">
      <div class="block-heading">
        {{ 'TAGS' | translate }}
        <mat-icon *ngIf="!expandFull" (click)="expandFull='tags'" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-unfold-more-horizontal"></mat-icon>
        <div class="slide-up-down">
          <mat-icon *ngIf="!expandFull && !hideTags" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-up" (click)="hideTags=true"></mat-icon>
          <mat-icon *ngIf="!expandFull && hideTags" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-down" (click)="hideTags=false"></mat-icon>
          <mat-icon *ngIf="expandFull === 'tags'" class="mat-18 close-section-icon" fontSet="mdi" fontIcon="mdi-close" (click)="expandFull=''"></mat-icon>
        </div>
      </div>
      <div class="block-content" *ngIf="!hideTags">
        <mat-list role="list" class="tags-list">
          <mat-list-item (click)="filterByTag(tag)"
                         role="listitem" *ngFor="let tag of tags">
            <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
            <span class="tag-name">
                        {{ tag.name }}
                    </span>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <!-- <div class="sidebar-block" [class.expand-full]="expandFull && expandFull === 'searches'" [class.hide-block]="expandFull && expandFull !== 'searches'">
      <div class="block-heading">
        {{ 'SMART_SEARCH' | translate }}
        <mat-icon *ngIf="!expandFull" (click)="expandFull='tags'" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-unfold-more-horizontal"></mat-icon>
        <div class="slide-up-down">
          <mat-icon *ngIf="!expandFull && !hideSearches" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-up" (click)="hideSearches=true"></mat-icon>
          <mat-icon *ngIf="!expandFull && hideSearches" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-down" (click)="hideSearches=false"></mat-icon>
          <mat-icon *ngIf="expandFull === 'searches'" class="mat-18 close-section-icon" fontSet="mdi" fontIcon="mdi-close" (click)="expandFull=''"></mat-icon>
        </div>
      </div>
      <div class="block-content" *ngIf="!hideSearches">
        <mat-list role="list" class="tags-list">
          <mat-list-item (click)="filterBySearch(search)"
                         role="listitem" *ngFor="let search of smartSearches">
            <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-search"></mat-icon>
            <span class="tag-name">
                          {{ search.name }}
                      </span>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div class="sidebar-block mini-cal-content" [class.expand-full]="expandFull && expandFull === 'calendar'" [class.hide-block]="expandFull && expandFull !== 'calendar'">
      <div class="block-heading">
        {{ 'CALENDAR_HEADER_LBL' | translate }}
       
        <div class="slide-up-down">
          <mat-icon *ngIf="!hideCalendar" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-up" (click)="hideCalendar=true"></mat-icon>
          <mat-icon *ngIf="hideCalendar" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-down" (click)="hideCalendar=false"></mat-icon>
          
        </div>
      </div>
      <div class="block-content" *ngIf="!hideCalendar">
  
        <vp-sidebar-calendar-mini-view
          #calendarDatePicker
          [viewDate]="viewDate"
          [locale]="currentLocale"
          (dayClicked)="calendarTimeChangedOnDatePicker($event)"
          [showWeekNumberInMiniCalendar]="showWeekNumberInMiniCalendar"
          [weekStartsOn]="firstDayOfWeek">
        </vp-sidebar-calendar-mini-view>
       
      </div>
    </div> -->
    <!-- <div class="sidebar-block" [class.expand-full]="expandFull && expandFull === 'calendar'" [class.hide-block]="expandFull && expandFull !== 'calendar'">
      <div class="block-heading">
        {{ 'CALENDAR' | translate }}
        <div class="slide-up-down">
          <mat-icon *ngIf="!expandFull && !hideCalendar" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-up" (click)="hideCalendar=true"></mat-icon>
          <mat-icon *ngIf="!expandFull && hideCalendar" class="mat-18 expand-section-icon" fontSet="mdi" fontIcon="mdi-chevron-down" (click)="hideCalendar=false"></mat-icon>
          <mat-icon *ngIf="expandFull === 'calendar'" class="mat-18 close-section-icon" fontSet="mdi" fontIcon="mdi-close" (click)="expandFull=''"></mat-icon>
        </div>
      </div>
      <div class="block-content" *ngIf="!hideCalendar">
        <mat-calendar></mat-calendar>
      </div>
    </div> -->
  </ng-container>
</div>
