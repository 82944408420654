/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { CommentActionTypes } from "../actions/comment";
import { MetaComment } from "../common/models/comment.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AppActionTypes } from "../actions/app";
import { Action } from "../actions";

export interface CommentState extends EntityState<MetaComment> {
  commentToReply: MetaComment;
}

export const commentAdapter: EntityAdapter<MetaComment> = createEntityAdapter<MetaComment>({
  selectId: (comment: MetaComment) => comment.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(comment1: MetaComment, comment2: MetaComment): number {
  return new Date(comment1.created_on).getTime() - new Date(comment2.created_on).getTime();
}

export const initialState: CommentState = commentAdapter.getInitialState({
  commentToReply: null
});

export function commentReducer(state: CommentState = initialState, action: Action): CommentState {
  switch (action.type) {

    case CommentActionTypes.COMMENT_ADD: {
      const comment = action.payload.comment;
      const newState = commentAdapter.addOne(comment, state);
      return commentAdapter.updateOne({ id: comment.id, changes: comment }, newState);
    }

    case CommentActionTypes.MULTI_CONVERSATION_COMMENT_ADD: {
      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const comment = item.comment;
        localState = commentAdapter.addOne(comment, localState);
        localState = commentAdapter.updateOne({ id: comment.id, changes: comment }, localState);
      });

      return localState;
    }

    case CommentActionTypes.COMMENT_BULK_APPEND: {
      const comments = action.payload.comments
        .map(comment => {
          return {
            ...comment
          };
        });
      return commentAdapter.addMany(comments, state);
    }

    case CommentActionTypes.COMMENT_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let combinedComments = [];
      data.forEach(item => {
        const comments = item.comments
          .map(comment => {
            return {
              ...comment
            };
          });
        combinedComments.push(...comments);
      });

      return commentAdapter.addMany(combinedComments, state);

    }

    case CommentActionTypes.COMMENT_DELETE_ACTION: {
      return commentAdapter.removeOne(action.payload, state);
    }

    case CommentActionTypes.COMMENT_UPDATE: {
      return commentAdapter.updateOne({ id: action.payload.id, changes: action.payload.changes }, state);
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.commentState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case CommentActionTypes.COMMENT_BULK_DELETE: {
      return commentAdapter.removeMany(action.payload.commentIds, state);
    }

    default: {
      return state;
    }
  }
}
