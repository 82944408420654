
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc-authentication" [style.backgroundImage]="backgroundImage">

  <form novalidate>
    <h5>SERVER URL</h5>
    <div class="form-control">
      <input required type="url" #urlInput pattern="(http|https)://[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?" placeholder="Server URL" #url="ngModel" name="serverURL" value="https://commander.vnc.biz" [(ngModel)]="serverURL">
    </div>
    <div class="form-control">
      <button [disabled]="!url.valid" class="login-server" (click)="loginNewServer()">LOGIN</button>
    </div>
  </form>
</div>
