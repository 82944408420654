
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<mat-form-field>
  <mat-select [formControl]="multiCtrl" [multiple]="true"
              (opened)="filterOptionsOpened($event)"
              #multiSelect
              panelClass="expanded__app__filters__option__panel">
    <mat-option class="option-search">
      <ngx-mat-select-search placeholderLabel="search" [formControl]="multiFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <mat-option class="default-option" [value]="-1">{{ allValueLabel | translate }}</mat-option>
    <mat-option *ngFor="let option of filteredOptionsMulti | async" [value]="option">
      {{option.name}}
    </mat-option>
    <span class="filler"></span>
    <div class="option-footer">
      <button mat-button class="save-button" (click)="$event.stopPropagation();applyFilters()">{{ 'APPLY' | translate }}</button>
      <button mat-button class="cancel-button" (click)="$event.stopPropagation();savePreviousFilters()">{{ 'CANCEL' | translate }}</button>
    </div>
  </mat-select>
</mat-form-field>
