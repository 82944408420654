import { ChangeDetectorRef, Component, EventEmitter, Input, Output, OnInit, OnChanges } from "@angular/core";
import { SearchItem } from "../../common/models/mail-models/search-item";
import { ConfigService } from "src/app/config.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MatDialog } from "@angular/material/dialog";
import { AppRepository } from "../../repositories/app.repository";
import { AppService } from "src/app/services/app.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { filter, take, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-tile-view-mail",
  templateUrl: "./tile-view-mail.component.html",
  styleUrls: ["./tile-view-mail.component.scss"]
})
export class TileViewMailComponent implements OnInit, OnChanges {
  @Input() document: SearchItem;
  @Output() closeSwitch = new EventEmitter();
  userFullName$: any;
  authorName: string;
  documentTitle: string;

  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private appRepository: AppRepository,
    private broadcaster: Broadcaster,
    private appService: AppService) {

      const routes = [];
      routes.push({
        title: "VNCmail",
        icon: CommonUtils.getIcon("VNCmail"),
        nativeLink: `${"VNCmail".toLowerCase()}://main`,
        path: "",
        active: true,
        enabled: true
      });
    }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.document) {
      // this.authorName = this.document.from;
      this.documentTitle = this.document.title;

      if (!this.userFullName$) {
        this.userFullName$ = this.appRepository.getContactByEmail(this.document.from)
        .pipe(filter(v => !!v), take(1)).subscribe(res => {
          if (res.name) {
            this.authorName = res.name;
            this.changeDetectionRef.markForCheck();
          }
        });
      }
    }
  }

  markAsRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "read" };
      this.appService.messageAction(body).subscribe(res => {
        this.document.unread = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  markAsUnRead() {
    if (this.document.type === "mail") {
      const body = { id: this.document.id, op: "!read" };
      this.appService.messageAction(body).subscribe(res => {
        this.document.unread = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  addStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  removeStar(doc) {
    if (doc.type === "mail") {

      const body = { id: doc.id, op: "!flag" };

      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = false;

        this.changeDetectionRef.markForCheck();
      });
    }
  }

  renderContent(content) {
    return wdtEmojiBundle.render(content).replace(/\r?\n/g, "\\n")
      .replace(/\\n/g, "<br />").replace(/[<]br[^>]*[>]/gi, "");
  }

  checkInstalledApp(uri: string, appName: string): Observable<boolean> {
    const subject = new BehaviorSubject<boolean>(false);
    subject.next(true);
    return subject.asObservable();
  }
  openApp(): void {
    this.underDevelopment();
  }
  underDevelopment() {
    this.appRepository.underDevelopment();
  }
  async openReplyMessage() {
    const { MailOperationComposeComponent } = await import(
      "src/app/mail-operation-compose/mail-operation-compose.component"
      );
    const dialogRef = this.matDialog.open(MailOperationComposeComponent, {
      autoFocus: true,
      data: { document: this.document, action: MailConstants.MAIL_COMPOSE_REPLY },
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {});
    this.changeDetectionRef.markForCheck();
  }
  async openReplyAllMessage() {
    const { MailOperationComposeComponent } = await import(
      "src/app/mail-operation-compose/mail-operation-compose.component"
      );
    const dialogRef = this.matDialog.open(MailOperationComposeComponent, {
      autoFocus: true,
      data: { document: this.document, action: MailConstants.MAIL_COMPOSE_REPLY_ALL },
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });
    this.changeDetectionRef.markForCheck();
  }
  async openForwardMessage() {
    const { MailOperationComposeComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/mail-operation-compose/mail-operation-compose.component"
      );
    const dialogRef = this.matDialog.open(MailOperationComposeComponent, {
      autoFocus: true,
      data: { document: this.document, action: MailConstants.MAIL_COMPOSE_FORWARD },
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {
    });
    this.changeDetectionRef.markForCheck();
  }
  async quickPreview(action: string) {
    const { QuickPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../../quick-preview/quick-preview.component"
      );
    const dialogRef = this.matDialog.open(QuickPreviewComponent, {
      width: "660px",
      height: "625px",
      autoFocus: true,
      panelClass: ["quick_preview_dialog", "mail_preview"],
      data: { document: this.document, action: action }
    });
    const detectChange = this.broadcaster.on("documentChange").subscribe((document: any) => {
      this.document = document;
      this.changeDetectionRef.markForCheck();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      detectChange.unsubscribe();

    });
  }


}
