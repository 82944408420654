/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { DateAdapter } from "../date-adapters/date-adapter";

/**
   * Change the view date to the current day. For example:
   *
   * ```typescript
   * <button
   *  vpCalendarToday
   *  [(viewDate)]="viewDate">
   *  Today
   * </button>
   * ```
   */
  @Directive({
    selector: "[vpCalendarToday]"
  })
  export class CalendarTodayDirective {
    /**
     * The current view date
     */
    @Input() viewDate: Date;

    /**
     * Called when the view date is changed
     */
    @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

    constructor(private dateAdapter: DateAdapter) {}

    /**
     * @hidden
     */
    @HostListener("click")
    onClick(): void {
      this.viewDateChange.emit(this.dateAdapter.startOfDay(new Date()));
    }
  }
