/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "src/app/actions";
import { SearchItem } from "../common/models/mail-models/search-item";

export interface DocumentState extends EntityState<SearchItem> {
    isLoading: boolean;
    isLoaded: boolean;
    hasMore: boolean;
}

export const documentAdapter: EntityAdapter<SearchItem> = createEntityAdapter<SearchItem>({
    selectId: (document: SearchItem) => document.id
});

export const initialState: DocumentState = documentAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    hasMore: false
});

export function documentReducer(
    state = initialState,
    action: Action
): DocumentState {
    switch (action.type) {

        case "IS_CONVERSATION_LOADING": {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case "LOAD_CONVERSATION_SUCCESS": {
            const newState = {
                ...state,
                isLoading: false,
                isLoaded: true
            };
            return documentAdapter.addMany(action.payload, newState);
        }

        default: {
            return state;
        }
    }
}

export const _getIsDocumentLoading = (state: DocumentState) => state.isLoading;
export const _getHasMoreDocuments = (state: DocumentState) => state.hasMore;
export const _getIsDocumentLoaded = (state: DocumentState) => state.isLoaded;
