/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "textarea[vpAutoSize]"
})

export class AutoSizeDirective implements AfterViewInit {

    private el: HTMLElement;
    private _minHeight: string;
    private _maxHeight: string;
    private _lastHeight: number;
    private _clientWidth: number;

    @Input("minHeight")
    get minHeight(): string {
        return this._minHeight;
    }
    set minHeight(val: string) {
        this._minHeight = val;
        this.updateMinHeight();
    }

    @Input("maxHeight")
    get maxHeight(): string {
        return this._maxHeight;
    }
    set maxHeight(val: string) {
        this._maxHeight = val;
        this.updateMaxHeight();
    }

    @HostListener("window:resize", ["$event.target"])
    onResize(textArea: HTMLTextAreaElement): void {
        // Only apply adjustment if element width had changed.
        if (this.el.clientWidth === this._clientWidth) {
            return;
        }
        this._clientWidth = this.element.nativeElement.clientWidth;
        this.adjust();
    }

    @HostListener("input", ["$event.target"])
    onInput(textArea: HTMLTextAreaElement): void {
        this.adjust();
    }

    constructor(public element: ElementRef) {
        this.el = element.nativeElement;
        this._clientWidth = this.el.clientWidth;
    }

    ngAfterViewInit(): void {
        // set element resize allowed manually by user
        const style = window.getComputedStyle(this.el, null);
        if (style.resize === "both") {
            this.el.style.resize = "horizontal";
        } else if (style.resize === "vertical") {
            this.el.style.resize = "none";
        }
        // run first adjust
        this.adjust();
    }

    adjust(): void {
        // perform height adjustments after input changes, if height is different
        if (this.el.style.height === this.element.nativeElement.scrollHeight + "px") {
            return;
        }
        this.el.style.overflow = "hidden";
        this.el.style.height = "auto";
        this.el.style.height = this.el.scrollHeight + "px";
    }

    updateMinHeight(): void {
        // Set textarea min height if input defined
        this.el.style.minHeight = this._minHeight + "px";
    }

    updateMaxHeight(): void {
        // Set textarea max height if input defined
        this.el.style.maxHeight = this._maxHeight + "px";
    }

}
