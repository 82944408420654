/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "src/environments/environment";
// import * as _ from "lodash";
import uniqBy from "lodash/uniqBy";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MultiPart } from "src/app/common/models/mail-models/multipart.model";
import { EmailInformation } from "src/app/common/models/mail-models/email-information.model";
import { Message } from "src/app/common/models/mail-models/mail-message.model";
import { MailFolder } from "src/app/common/models/mail-models/mail-folder.model";

export class MailUtils {
    static messageId = "";
    static inlineMailBody = "";
    static mailBody = "";
    static hexcase = 0;  /* hex output format. 0 - lowercase; 1 - uppercase        */
    static chrsz = 8;  /* bits per input character. 8 - ASCII; 16 - Unicode      */
    static _NON_WHITESPACE = /\S+/;
    static ORIG_HEADER = "HEADER";
    static ORIG_LINE = "LINE";
    static ORIG_UNKNOWN = "UNKNOWN";
    static ORIG_WROTE_STRONG = "WROTE_STRONG";
    static ORIG_EMAIL_RE = /[^@\s]+@[A-Za-z0-9\-]{2,}(\.[A-Za-z0-9\-]{2,})+/;
    static ORIG_DATE_RE = /\d+\s*(\/|\-|, )20\d\d/; // matches "03/07/2014" or "March 3, 2014" by looking for year 20xx
    static ORIG_INTRO_DE_RE = new RegExp("^(-{2,}|" + "auf" + "\\s+)", "i");
    static ORIG_INTRO_RE = new RegExp("^(-{2,}|" + "on" + "\\s+)", "i");
    static TRIM_RE = /^\s+|\s+$/g;
    static COMPRESS_RE = /\s+/g;
    static ORIG_WROTE_WEAK = "WROTE_WEAK";
    static ORIG_QUOTED = "QUOTED";
    static ORIG_SEP_STRONG = "SEP_STRONG";
    static HTML_SEP_ID = "zwchr";
    static NOTES_SEPARATOR = "*~*~*~*~*~*~*~*~*~*";
    // Regexes for finding stuff in msg content
    static SIG_RE = /^(- ?-+)|(__+)\r?$/;
    static SPLIT_RE = /\r\n|\r|\n/;
    static HDR_RE = /^\s*\w+:/;
    static COLON_RE = /\S+:$/;
    static MSG_REGEXES = [
        {
            type: MailUtils.ORIG_QUOTED,
            regex: /^\s*(>|\|)/
        },
        {
            type: MailUtils.ORIG_SEP_STRONG,
            regex: new RegExp("^\\s*--+\\s*(" + "Original Message" + "|" +
                "Originalnachricht" + "|" + "Weitergeleitete Nachricht" + "|" +
                "Forwarded Message" + "|" + "Original Appointment" + ")\\s*--+\\s*$", "i")
        },
        {
            type: MailUtils.ORIG_SEP_STRONG,
            regex: new RegExp("^" + "Begin forwarded message:" + "$", "i")
        },
        {
            type: MailUtils.ORIG_HEADER,
            regex: new RegExp("^\\s*(" + ["from:", "to:", "subject:", "date:", "sent:", "cc:"].join("|") + ")")
        },
        {
            type: MailUtils.ORIG_LINE,
            regex: /^\s*_{5,}\s*$/
        }
    ];
    static IGNORE_NODE = { "#comment": true, br: true, script: true, select: true, style: true };
    static SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    static getMailTextBodyWithoutSignature(mailBody: string): string {
        if (mailBody !== undefined && mailBody !== "" && mailBody !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(mailBody, "text/html");
            const htmlBody = htmlDoc.getElementsByTagName("body")[0];
            if (htmlBody.getElementsByTagName("signature")[0] !== null &&
                htmlDoc.getElementsByTagName("signature")[0] !== undefined) {
                htmlBody.removeChild(htmlDoc.getElementsByTagName("signature")[0]);
            }
            return this.HTMLToPlainText(htmlBody.innerHTML);
        }
        return "";
    }

    static getMailSigantureFromBody(mailBody: string): string {
        if (mailBody !== undefined && mailBody !== "" && mailBody !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(mailBody, "text/html");
            const signatureTag = htmlDoc.getElementsByTagName("signature")[0];
            if (signatureTag !== null && signatureTag !== undefined) {
                return "---\n" + this.HTMLToPlainText(signatureTag.innerHTML);
            }
            return "";
        }
        return "";
    }

    static HTMLToPlainText(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/<\/div>/ig, "\n").replace(/<\/p>/ig, "\n").replace(/<[^>]+>/ig, "");
        } else {
            return "";
        }
    }

    static getPlainText(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/<\/div>/ig, "\n")
            .replace(/<\/p>/ig, "\n")
            .replace(/<br \/>/ig, "\n")
            .replace(/<br>/ig, "\n")
            .replace(/<[^>]+>/ig, "");
        } else {
            return "";
        }
    }

    static plainTextToHTML(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
        } else {
            return "";
        }
    }

    static getAttachmentToSend(attachmentList: any[], id: string): any[] {
        const attachMentSave: any = [];
        attachmentList.forEach(item => {
            attachMentSave.push(
                {
                    mid: id,
                    part: item.part
                }
            );
        });
        return attachMentSave;
    }

    static setInlineImageToMail(content: string, zimbraUrl?: string): string {
        const electronService = new ElectronService;
        const isCordovaOrElectron = environment.isCordova || environment.isElectron;
        const parser = new DOMParser();
        if (content !== undefined && content !== null) {
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                
                    if (img.getAttribute("alt") !== null) {
                        const alt = img.getAttribute("alt");
                        if (alt.indexOf("cid:") !== -1) {
                            img.removeAttribute("src");
                            img.setAttribute("src", alt);
                            img.setAttribute("data-mce-src", alt);
                            img.removeAttribute("alt");
                        }
                    }
                    if (!!img.getAttribute("src") && img.getAttribute("src").indexOf("/api/getAttachment") !== -1) {
                        const src = img.getAttribute("src").split("cid=")[1];
                        if (isCordovaOrElectron) {
                            const newSrc = src.substr(0, src.indexOf("&token="));
                            img.setAttribute("src", newSrc);
                        } else {
                            const newSrc = `cid:${src}`;
                            img.setAttribute("src", newSrc);
                        }
                    }
                    if (!!img.getAttribute("src") && img.getAttribute("src").indexOf("api/printDocument?url") !== -1) {
                        if (!isCordovaOrElectron) {
                            let url = img.getAttribute("src");
                            url = url.replace(location.origin + "/api/printDocument?url=", zimbraUrl + "/");
                            img.setAttribute("src", url);
                        } else {
                            const configURL =  localStorage.getItem(MailConstants.SERVER_URL).trim();
                            let url = img.getAttribute("src");
                            url = url.replace(configURL + "/api/printDocument?url=", zimbraUrl + "/");
                            img.setAttribute("src", url);
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
            return content;
        }
    }

    static getAttachments(mailParts): MultiPart[] {
        let results: MultiPart[] = [];
        if (mailParts) {
            if (Array.isArray(mailParts)) {
                for (const part of mailParts) {
                    const data = this.getAttachments(part);
                    data.forEach(item => {
                        if (item.filename) {
                            results.push(item);
                        }
                    });
                }
            } else {
                if (mailParts && (mailParts.filename)) {
                    results.push(mailParts);
                } else if (mailParts.mp) {
                    results = results.concat(this.getAttachments(mailParts.mp));
                }
            }
        }
        return results;
    }

    static getFileAndIcon(contentType: string): any {
        let icon = "";
        let isImage = false;
        switch (contentType) {
            case "application/pdf":
                icon = "mdi-file-pdf-box";
                break;
            case "application/zip":
                icon = "mdi-zip-box";
                break;
            case "application/msword":
                icon = "mdi-file-word-box";
                break;
            case "application/vnd.ms-excel":
                icon = "mdi-file-excel-box";
                break;
            case "image/png":
            case "image/jpg":
            case "image/jpeg":
                isImage = true;
                break;
            case "image/gif":
                icon = "mdi-file";
                break;
            case "text/plain":
                icon = "mdi-file-document-box";
                break;
            case "application/vnd.ms-powerpoint":
                icon = "mdi-file-powerpoint-box";
                break;
            case "video/x-flv":
            case "video/mp4":
            case "application/x-mpegURL":
            case "video/MP2T":
            case "video/3gpp":
            case "video/quicktime":
            case "video/x-msvideo":
            case "video/x-ms-wmv":
            case "video/ogg":
                icon = "mdi-play";
                break;
            default:
                icon = "mdi-file";
        }
        return { icon: icon, isImage: isImage };
    }

    static getToolbarOptions(): any[] {
        return [
            { "font": [] },
            { "size": [false, "12px", "14px", "18px", "24px"] },
            "bold",
            "italic",
            "underline",
            "strike",
            { "color": [] },
            { "script": "super" },
            { "script": "sub"},
            { "header": 1 },
            { "header": 2 },
            "blockquote",
            "code-block",
            { "list": "ordered" },
            { "list": "bullet" },
            { "indent": "-1"},
            { "indent": "+1" },
            { "direction": "rtl" },
            { "header": [1, 2, 3, 4, 5, 6, false] },
            { "background": [] },
            "link",
            "image",
            "clean"
        ];
    }

    static getEmailFrom(message: Message): string {
        const from: EmailInformation = message.e.filter(e => e.t === "f")[0];
        if ( from.d === undefined) {
            const email = MailUtils.getEmailFromStorage();
            from.d = email;
            from.a = email;
            from.p = email;
        }
        return from.d;
    }

    static getEmailFromStorage(): string {
        const profile = MailUtils.getProfileFromStorage();
        if (!!profile) {
            return this.checkEmailArray(profile.email);
        }
        return "";
    }

    static getProfileFromStorage(): any {
        if (!!localStorage.profileUser) {
            return JSON.parse(localStorage.profileUser);
        } else {
            return {};
        }
    }

    static checkEmailArray(emails): string {
        if (Array.isArray(emails)) {
          return emails[0];
        } else if (emails) {
          return emails;
        }
    }

    static stripSubjectPrefixes(subject: string): string {
        const regex = /^\s*(Re|Fw|Fwd|Re|Fwd|Fw):\s*/i;
        while (regex.test(subject)) {
            subject = subject.replace(regex, "");
        }
        return subject;
    }

    static getEmailBody(message: Message, toSendOnly?: boolean): string {
        this.messageId = "";
        let mailBody = "";
        this.mailBody = "";
        this.inlineMailBody = "";
        this.messageId = message.id;
        mailBody = this.getMailBodyFromMultiPart(message.mp);
        mailBody = mailBody + this.getMailInlineBodyFromMultiPart(message.mp);
        return this.getInlineImage(mailBody, message, toSendOnly);
    }

    static getMailBodyFromMultiPart(mp): string {
        if (Array.isArray(mp)) {
            mp.forEach(item => {
                if (item && item.ct === "multipart/mixed" && item.mp) {
                    this.getMailBodyFromMultiPart(item.mp);
                } else if (item && item.ct === "multipart/alternative" && item.mp) {
                    this.getMailBodyFromMultiPart(item.mp);
                } else if (item && item.ct === "text/html" && item.content && item.body === true) {
                    if (item.part && item.part.indexOf(".") === -1) {
                        this.mailBody = item.content;
                        return this.mailBody;
                    } else {
                        return this.mailBody += item.content;
                    }
                } else if (item.ct && item.ct === "multipart/report" && item.content && item.body === true) {
                    this.mailBody = this.plainTextToHTML(item.mp[0].content);
                } else if (item.part && item.part.indexOf(".") === -1 && item.cd && item.cd === "inline" && !item.ci) {
                    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
                    let serverURL = "";
                    if (isCordovaOrElectron) {
                        serverURL = localStorage.getItem(MailConstants.SERVER_URL).trim();
                    }
                    this.mailBody += "<img src='" + serverURL + "/api/getAttachment?id=" + this.messageId + "&part=" + item.part
                    + "&cid='>";
                } else if (item.ct && item.ct === "text/plain" && item.content && item.body === true && !item.cd) {
                    if (item.part && item.part.indexOf(".") === -1) {
                        this.mailBody += item.content;
                        return;
                    } else {
                        this.mailBody = item.content;
                    }
                } else if (item.mp) {
                    this.getMailBodyFromMultiPart(item.mp);
                }
            });
        } else if (!!mp) {
            if (mp.ct === "text/html" && mp.content && mp && mp.content && mp.body === true) {
                this.mailBody = mp.content;
            } else if (mp.ct === "text/plain" && mp.content && mp.body === true) {
                this.mailBody = mp.content;
            } else if (mp.mp) {
                this.getMailBodyFromMultiPart(mp.mp);
            }
        }
        return this.mailBody;
    }
    static getMailInlineBodyFromMultiPart(mp): string {
        if (Array.isArray(mp)) {
            mp.forEach(item => {
                if (item && item.ct === "multipart/mixed" && item.mp) {
                    this.getMailInlineBodyFromMultiPart(item.mp);
                } else if (item && item.ct === "multipart/alternative" && item.mp) {
                    this.getMailInlineBodyFromMultiPart(item.mp);
                } else if (item.ct && item.ct === "text/plain" && item.content && item.body === true && item.cd && item.cd === "inline") {
                    this.inlineMailBody = item.content;
                } else if (item.mp) {
                    this.getMailInlineBodyFromMultiPart(item.mp);
                }
            });
        } else if (!!mp) {
            if (mp.ct === "text/plain" && mp.content && mp.body === true && mp.cd && mp.cd === "inline") {
                this.inlineMailBody = mp.content;
            } else if (mp.mp) {
                this.getMailInlineBodyFromMultiPart(mp.mp);
            }
        }
        return this.inlineMailBody;
    }

    static getInlineImage(content: string, message: Message, toSendOnly?: boolean): string {
        const electronService = new ElectronService;
        if (content !== undefined && content !== null) {
            const attachMent: MultiPart[] = this.getAttachments(message.mp).filter(attachment => attachment.ci);
            content = content.replace(/dfsrc/g, "src");
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            const isCordovaOrElectron = environment.isCordova || environment.isElectron;
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    const src = img.getAttribute("src");
                    if (src !== null && src.indexOf("cid:") !== -1) {
                        img.setAttribute("pnsrc", src);
                        const cid = src.split("cid:")[1];
                        const multiPart: MultiPart = attachMent.filter(
                            attach => attach.ci.replace("<", "").
                                replace(">", "").trim() === cid.trim()
                        )[0];

                        let queryParams = "";
                        let serverURL = localStorage.getItem("mailAppUrl");
                        if (serverURL.endsWith("/")) {
                            serverURL = serverURL.substring(0, serverURL.lastIndexOf("/")).trim();
                        }
                        img.removeAttribute("style");
                        if (!!multiPart) {
                            let link = serverURL + "/api/getAttachment?id=" + message.id + "&part=" + multiPart.part +
                            "&cid=" + multiPart.ci.replace("<", "").replace(">", "");
                            link = CommonUtils.addTokenToLink(link);
                            img.setAttribute("src", link);
                            img.removeAttribute("data-mce-src");
                            img.removeAttribute("pnsrc");
                        } else {
                            img.removeAttribute("src");
                        }
                    } else {
                        if (img !== null) {
                            const src = img.getAttribute("src");
                            if (src !== null) {
                                if (src.indexOf("/Briefcase/") !== -1) {
                                    if (!isCordovaOrElectron) {
                                        const url = src.substr(src.indexOf("home"));
                                        img.setAttribute("src", location.origin + "/api/printDocument?url=" + url.replace(" ", "%20"));
                                    } else {
                                        const configURL =  localStorage.getItem(MailConstants.SERVER_URL).trim();
                                        const url = src.substr(src.indexOf("home"));
                                        img.setAttribute("src", configURL + "/api/printDocument?url=" + url.replace(" ", "%20"));
                                    }
                                }
                            }
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
            return content;
        }
    }
    static getShareData(share: any): any {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(share, "text/xml");

        const shareAction = xmlDoc.getElementsByTagName("share")[0].getAttribute("action");
        const grantorName = xmlDoc.getElementsByTagName("grantor")[0].getAttribute("name");
        const grantorEmail = xmlDoc.getElementsByTagName("grantor")[0].getAttribute("email");
        const grantorId = xmlDoc.getElementsByTagName("grantor")[0].getAttribute("id");

        const granteeName = xmlDoc.getElementsByTagName("grantee")[0].getAttribute("name");
        const granteeEmail = xmlDoc.getElementsByTagName("grantee")[0].getAttribute("email");
        const granteeId = xmlDoc.getElementsByTagName("grantee")[0].getAttribute("id");

        const rid = xmlDoc.getElementsByTagName("link")[0].getAttribute("id");
        const perm = xmlDoc.getElementsByTagName("link")[0].getAttribute("perm");
        const view = xmlDoc.getElementsByTagName("link")[0].getAttribute("view");
        const name = xmlDoc.getElementsByTagName("link")[0].getAttribute("name");
        let notes = "";
        if (shareAction === "accept") {
            const noteEle = xmlDoc.getElementsByTagName("notes");
            if (noteEle !== null) {
                notes = this.plainTextToHTML(noteEle[0].innerHTML);
            }
        }
        let role = "";
        let roleName = "NONE";
        let allowedAction = "NONE";
        if (perm === "r" || perm === "rp") {
            role = "ROLE_VIEW";
            roleName = "ROLENAME_VIEWER";
            allowedAction = "ALLOWEDACTION_VIEW";
        } else if (perm === "rwidx" || perm === "rwidxp") {
            role = "ROLE_MANAGER";
            roleName = "ROLENAME_MANAGER";
            allowedAction = "ALLOWEDACTION_MANAGER";
        } else if (perm === "rwidxa" || perm === "rwidxap") {
            role = "ROLE_ADMIN";
            roleName = "ROLENAME_ADMIN";
            allowedAction = "ALLOWEDACTION_ADMIN";
        }

        return {
            shareAction: shareAction,
            grantorName: grantorName,
            grantorEmail: grantorEmail,
            grantorId: grantorId,
            granteeName: granteeName,
            granteeEmail: granteeEmail,
            granteeId: granteeId,
            rid: rid,
            perm: perm,
            view: view,
            name: name,
            role: role,
            roleName: roleName,
            allowedAction: allowedAction,
            notes: notes
        };
    }

    static getSharedNote(message: Message): string {
        const shareContent = this.getEmailBody(message);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(shareContent, "text/xml");
        if (xmlDoc.getElementsByTagName("p")[1] !== undefined && xmlDoc.getElementsByTagName("p")[1].innerHTML !== undefined) {
            return xmlDoc.getElementsByTagName("p")[1].innerHTML;
        }
        return "";
    }

    static removeAttachmentURL(content: string): string {
        const parser = new DOMParser();
        const isCordovaOrElectron = environment.isCordova || environment.isElectron;
        if (!!content) {
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    if (!!img.getAttribute("src") && img.getAttribute("src").indexOf("/api/getAttachment") !== -1) {
                        if (isCordovaOrElectron) {
                            let src = img.getAttribute("src").split("cid=")[1];
                            src = src.substr(0, src.indexOf("&token="));
                            const newSrc = `cid:${src}`;
                            img.setAttribute("src", newSrc);
                        } else {
                            const src = img.getAttribute("src").split("cid=")[1];
                            const newSrc = `cid:${src}`;
                            img.setAttribute("src", newSrc);
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
        }
        return content;
    }

    static getFileTypeExtension(contentType: string): string {
        const extensionMap = {
            "application/pdf": "PDF",
            "application/zip": "ZIP",
            "application/msword": "DOC",
            "image/png": "PNG",
            "image/jpg": "JPG",
            "image/jpeg": "JPG",
            "image/gif": "GIF",
            "text/plain": "TXT",
            "application/vnd.ms-powerpoint": "PPT"
        };
        if (Object.keys(extensionMap).includes(contentType)) {
            return extensionMap[contentType];
        } else {
            return "FILE";
        }
    }

    static getEmailFromAddressName(message: Message): string {
        const from: EmailInformation = message.e.filter(e => e.t === "f")[0];
        if ( from.d === undefined) {
            const email = MailUtils.getEmailFromStorage();
            from.d = email;
            from.a = email;
            from.p = email;
        }
        let addressName: string = from.d;
        if (from.p) {
            addressName = from.p;
        }
        return addressName;
    }

    static getEmailAddressFrom(message: Message): string {
        const from: EmailInformation = message.e.filter(e => e.t === "f")[0];
        return from.a;
    }

    static getLastMessageText(message: Message, toSendOnly?: boolean): string {
        const body = MailUtils.addClassToAnchor(MailUtils.replaceLinkToAnchor(MailUtils.getEmailBody(message)));
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(body, "text/html");
        const htmlBody = htmlDoc.body.innerHTML;
        if (/\s*--+\s*Original Message\s*--+\s*/ig.test(htmlBody)) {
            const zimbraQuoted = htmlBody.split(/\s*--+\s*Original Message\s*--+\s*/ig);
            htmlDoc.body.innerHTML = "";
                const div = htmlDoc.createElement("div");
                div.innerHTML = zimbraQuoted[0];
                htmlDoc.body.appendChild(div);
            return htmlDoc.body.innerHTML;
        } else if (/\s*--+\s*Originalnachricht\s*--+\s*/ig.test(htmlBody)) {
            const zimbraQuoted = htmlBody.split(/\s*--+\s*Originalnachricht\s*--+\s*/ig);
            htmlDoc.body.innerHTML = "";
            zimbraQuoted.splice(1).forEach(item => {
                const div = htmlDoc.createElement("div");
                div.innerHTML = item;
                htmlDoc.body.appendChild(div);
            });
            return htmlDoc.body.innerHTML;
        } else {
            return MailUtils.getEmailBody(message, toSendOnly);
        }
    }

    static addClassToAnchor(content: string): string {
        if (content === "" || content === undefined) {
            return "";
        }
        const parser = new DOMParser();
        return content.replace(/<a [^>]+>([^<]+)<\/a>/ig, (aHref) => {
            const htmlDoc = parser.parseFromString(aHref, "text/html");
            const aTag = htmlDoc.getElementsByTagName("a")[0];
            aTag.setAttribute("class", "open-new-window");
            if (aTag.getAttribute("href") !== null ) {
                const url = aTag.getAttribute("href");
                if ( !url.startsWith("http") && !url.startsWith("https")) {
                    const href = "http://" + url;
                    aTag.setAttribute("href", href);
                }
            }
            return htmlDoc.body.innerHTML;
        });
    }

    static replaceLinkToAnchor(content: string): string {
        if (content === "" || content === undefined) {
            return "";
        }
        return content.replace(/(?:https?\:\/\/|www\.)+(?![^\s]*?")([\w.,@?!^=%&amp;:()\/~+#-]*[\w@?!^=%&amp;()\/~+#-])?/ig, (url) => {
            const wrap = document.createElement("div");
            const anchor = document.createElement("a");
            let href = url.replace(/&amp;/g, "&");
            if ( !url.startsWith("http") && !url.startsWith("https")) {
                href = "http://" + url;
            }
            anchor.href = href.replace(/&#64;/g, "@").replace(/&#61;/g, "=");
            anchor.target = "_blank";
            anchor.classList.add("open-new-window");
            anchor.innerHTML = url;
            wrap.appendChild(anchor);
            return wrap.innerHTML;
        });
    }

    static processList(delta) {
        let i = 0;
        let j = 0;
        const listToRemove = [];
        const finalList = [];
        delta.ops.forEach(op => {
            if (op.insert && delta.ops[i + 1] && delta.ops[i + 1].attributes
                && delta.ops[i + 1].attributes.list && !delta.ops[i + 1].attributes.link) {
                op.insert = op.insert.replace(/\n$/g, "");
         
            }
            if (op.attributes && op.attributes.list && !op.insert.trim()) {
                listToRemove.push(i);
            
            }
            i++;
        });
        delta.ops.forEach(op => {
            if (!listToRemove.includes(j)) {
                finalList.push(op);
            }
            j++;
        });
       
        return {ops: finalList};
    }

    static setInlineImage(content: string, message: Message): string {
        if (content !== undefined && content !== null) {
            // content = content.replace(/dfsrc/g, "src");
            const attachMent: MultiPart[] = this.getAttachments(message.mp).filter(attach => attach.ci);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    const src = img.getAttribute("src");
                    if (src.indexOf("/api/getAttachment") !== -1) {
                        const cid = src.split("cid=")[1];
                        const multiPart: MultiPart = attachMent.
                            filter(attach => attach.ci.replace("<", "").replace(">", "").trim() === cid)[0];
                        if (multiPart !== undefined && multiPart) {
                            const link = "cid:" + multiPart.ci.replace(">", "").replace("<", "");
                         
                            img.setAttribute("src", link);
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
            return content;
        }
    }

    static parseFolders(folders, isSingleNode?: boolean): MailFolder[] {
        if (isSingleNode) {
            return this.parseSingleFolder(folders);
        }
        if (folders.folder[0].folder && folders.folder[0].link) {
            folders = {
                folder: {
                    folder: folders.folder[0].folder,
                    link: folders.folder[0].link
                }
            };
        } else if (folders.folder[0].folder) {
            folders = {
                folder: {
                    folder: folders.folder[0].folder
                }
            };
        } else if (folders.folder[0].link) {
            folders = {
                folder: {
                    link: folders.folder[0].link
                }
            };
        }
        const mailFolderResponse = this.parseMailFoders(folders);
        return mailFolderResponse;
    }

    static parseMailFoders(res: any, type = "mail"): MailFolder[] {
        const mailSubFolder: MailFolder[] = [];
        if (res.folder) {
            if (res.folder.folder) {
                res.folder.folder.map(contact => {
                    let c: any = {};
                    c = contact;
                    if (c) {
                        const newFolder: MailFolder = c as MailFolder;
                        this.checkHasChildFolder(c, newFolder);
                        mailSubFolder.push(newFolder);
                    }
                });
            } else {
                let sf: any = {};
                sf = res.folder;
                const newFolder: MailFolder = sf as MailFolder;
                this.checkHasChildFolder(sf, newFolder);
                mailSubFolder.push(newFolder);
            }
            if (res.folder.link) {
                if (Array.isArray(res.folder.link)) {
                    res.folder.link.map(contact => {
                        let s: any = {};
                        s = contact;
                        if (s) {
                            const newFolder: MailFolder = s as MailFolder;
                            this.checkHasChildFolder(s, newFolder);
                            mailSubFolder.push(newFolder);
                        }
                    });
                } else {
                    let s: any = {};
                    s = res.folder.link;
                    const newFolder: MailFolder = s as MailFolder;
                    this.checkHasChildFolder(s, newFolder);
                    mailSubFolder.push(newFolder);
                }
            }
        }
        mailSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return this.adjustFolderSequence(mailSubFolder, type);
    }

    static parseSingleFolder(res) {
        const mailSubFolder: MailFolder[] = [];
        if (res.folder) {
            let c: any = {};
            c = res.folder[0];
            if (c) {
                const newFolder: MailFolder = c as MailFolder;
                this.checkHasChildFolder(c, newFolder);
                mailSubFolder.push(newFolder);
            }
        } else if (res.link) {
            let c: any = {};
            c = res.link;
            if (c) {
                const newFolder: MailFolder = c as MailFolder;
                this.checkHasChildFolder(c, newFolder);
                if (newFolder.children) {
                    newFolder.children = uniqBy(newFolder.children, "id");
                }
                mailSubFolder.push(newFolder);
            }
        }
        mailSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return mailSubFolder;
    }

    static checkHasChildFolder(c: any, folder: MailFolder) {
        if (c.hasOwnProperty("folder") && Array.isArray(c.folder)) {
            folder.children = [];
            for (let i = 0; i < c.folder.length; i++) {
                folder.children.push(this.generateTreeStructure(c.folder[i], folder));
            }
        } else if (c.hasOwnProperty("folder") && c.folder) {
            folder.children = [];
            folder.children.push(this.generateTreeStructure(c.folder));
        }
    }

    static setFolderColor(mailFolder: any): string {
        if (mailFolder.color) {
            if (mailFolder.color === 0) {
                return "#c8c8c8";
            } if (mailFolder.color === 1) {
                return "#0000FF";
            } if (mailFolder.color === 2) {
                return "#00FFFF";
            } if (mailFolder.color === 3) {
                return "#008000";
            } if (mailFolder.color === 4) {
                return "#800080";
            } if (mailFolder.color === 5) {
                return "#FF0000";
            } if (mailFolder.color === 6) {
                return "#FFFF00";
            } if (mailFolder.color === 7) {
                return "#FFC0CB";
            } if (mailFolder.color === 8) {
                return "#808080";
            } if (mailFolder.color === 9) {
                return "#FFA500";
            }
            return "#808080";
        } else if (mailFolder.rgb) {
            return mailFolder.rgb;
        } else {
            return "#808080";
        }
    }

    static generateTreeStructure(c: any, parentFolder?: MailFolder): MailFolder {
        const folder: MailFolder = c as MailFolder;
        folder.rgb = this.setFolderColor(folder);
        if (folder.id.indexOf(":") !== -1) {
            const absPath = folder.absFolderPath;
            if (!!absPath && !!folder) {
                folder.shareFolderSearchPath = parentFolder.name + absPath.substr(absPath.indexOf("/", 2), absPath.length);
            }
        }
        if (c.hasOwnProperty("folder") && Array.isArray(c.folder)) {
            folder.children = [];
            for (let i = 0; i < c.folder.length; i++) {
                folder.children.push(this.generateTreeStructure(c.folder[i], parentFolder));
            }
        } else if (c.hasOwnProperty("folder") && c.folder) {
            folder.children = [];
            folder.children.push(this.generateTreeStructure(c.folder, parentFolder));
        }
        return folder;
    }

    static adjustFolderSequence(folders: MailFolder[], type: string): MailFolder[] {
        const tempFolders: MailFolder[] = [];

        if (type === "mail") {
            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Inbox") {
                        a.icon = "inbox";
                        return true;
                    } else {
                        return false;
                    }
                })
            );

            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Drafts") {
                        a.icon = "drafts";
                        return true;
                    }
                })
            );

            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Sent") {
                        a.icon = "send";
                        return true;
                    }
                })
            );

            const newFolder: MailFolder = {
                id: Math.random()
                    .toString(36)
                    .substr(2, 9),
                name: "Starred",
                icon: "star",
                absFolderPath: "/starred",
                originalFolder: null
            };
            tempFolders.push(newFolder);
         
            // Update junk to spam folder name
            const jukFolder = folders.find(a => a.name === "Junk");
            if (jukFolder) {
                jukFolder.name = "Spam";
                jukFolder.icon = "report";
                tempFolders.push(jukFolder);
            }
        } else {
            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Briefcase") {
                        a.icon = "folder";
                        return true;
                    }
                })
            );
        }
        tempFolders.push(
            folders.find(a => {
                if (a.name === "Trash") {
                    a.icon = "delete";
                    return true;
                }
            })
        );

        tempFolders.push(folders.find(a => a.name === "Chats"));

        folders.forEach(f => {
            if (
                f.name !== "Inbox" &&
                f.name !== "Drafts" &&
                f.name !== "Sent" &&
                f.name !== "Junk" &&
                f.name !== "Trash" &&
                f.name !== "Spam" &&
                f.name !== "Chats"
            ) {
                tempFolders.push(f);
            }
        });
        return tempFolders;
    }

    static hex_sha1(s) {
        return this.binb2hex(
            this.core_sha1(
                this.str2binb(s),
                s.length * this.chrsz
            )
        ) + "@zimbra";
    }

    /*
       * Convert an array of big-endian words to a hex string.
       */
      static binb2hex(binarray) {
        /* eslint-disable */
        const hex_tab = this.hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
        let str = "";
        for (let i = 0; i < binarray.length * 4; i++) {
            str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
                hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
        }
        return str;
    }
    /*
     * Calculate the SHA-1 of an array of big-endian words, and a bit length
     */
    static core_sha1(x, len) {
        /* append padding */
        /* eslint-disable */
        x[len >> 5] |= 0x80 << (24 - len % 32);
        x[((len + 64 >> 9) << 4) + 15] = len;

        const w = Array(80);
        let a = 1732584193;
        let b = -271733879;
        let c = -1732584194;
        let d = 271733878;
        let e = -1009589776;

        for (let i = 0; i < x.length; i += 16) {
            const olda = a;
            const oldb = b;
            const oldc = c;
            const oldd = d;
            const olde = e;
            for (let j = 0; j < 80; j++) {
                if (j < 16) {
                    w[j] = x[i + j];
                } else {
                    w[j] = this.rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
                }
                const t = this.safe_add(this.safe_add(this.rol(a, 5), this.sha1_ft(j, b, c, d)),
                    this.safe_add(this.safe_add(e, w[j]), this.sha1_kt(j)));
                e = d;
                d = c;
                c = this.rol(b, 30);
                b = a;
                a = t;
            }

            a = this.safe_add(a, olda);
            b = this.safe_add(b, oldb);
            c = this.safe_add(c, oldc);
            d = this.safe_add(d, oldd);
            e = this.safe_add(e, olde);
        }
        return Array(a, b, c, d, e);
    }

    /*
         * Add integers, wrapping at 2^32. This uses 16-bit operations internally
         * to work around bugs in some JS interpreters.
    */
    static safe_add(x, y) {
        /* eslint-disable */
        const lsw = (x & 0xFFFF) + (y & 0xFFFF);
        const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
    }

    /*
        * Bitwise rotate a 32-bit number to the left.
    */
    static rol(num, cnt) {
        return (num << cnt) | (num >>> (32 - cnt));
    }

    static str2binb(str) {
        const bin = Array();
        const mask = (1 << this.chrsz) - 1;
        for (let i = 0; i < str.length * this.chrsz; i += this.chrsz) {
            bin[i >> 5] |= (str.charCodeAt(i / this.chrsz) & mask) << (32 - this.chrsz - i % 32);
        }
        return bin;
    }

    /*
         * Determine the appropriate additive constant for the current iteration
    */
    static sha1_kt(t) {
        return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 :
        (t < 60) ? -1894007588 : -899497514;
    }

    /*
         * Perform the appropriate triplet combination function for the current
         * iteration
    */
    static sha1_ft(t, b, c, d) {
        if (t < 20) {
            return (b & c) | ((~b) & d);
        }
        if (t < 40) {
            return b ^ c ^ d;
        }
        if (t < 60) {
            return (b & c) | (b & d) | (c & d);
        }
        return b ^ c ^ d;
    }

    static hideShowExternalImage(content: string, display: string): string {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, "text/html");
        const images = htmlDoc.getElementsByTagName("img");
        for (let i=0;i<images.length;i++) {
            if (images[i].src.indexOf("/api/getAttachment") === -1) {
                images[i].style.display =  display;
            }
        }
        return htmlDoc.body.innerHTML;
    }

    static getOriginalContent(text, isHtml?: boolean) {
        if (!text) { return ""; }
        if (isHtml) {
            return this._getOriginalHtmlContent(text);
        }

        let results = [];
        let lines = text.split(this.SPLIT_RE);

        let curType, curBlock = [], count = {}, isMerged, unknownBlock, isBugzilla = false;
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            let testLine = this.trim(line);

            // blank lines are just added to the current block
            if (!this._NON_WHITESPACE.test(testLine)) {
                curBlock.push(line);
                continue;
            }

            // Bugzilla summary looks like QUOTED; it should be treated as UNKNOWN
            if ((testLine.indexOf("| DO NOT REPLY") === 0) && (lines[i + 2].indexOf("bugzilla") !== -1)) {
                isBugzilla = true;
            }

            let type = this._getLineType(testLine);
            if (type === this.ORIG_QUOTED) {
                type = isBugzilla ? this.ORIG_UNKNOWN : type;
            }
            else {
                isBugzilla = false;
            }

            // WROTE can stretch over two lines; if so, join them into one line
            let nextLine = lines[i + 1];
            isMerged = false;

            // if (nextLine && (type === this.ORIG_UNKNOWN) && (this.ORIG_INTRO_RE.test(testLine) || this.ORIG_INTRO_DE_RE.test(testLine))) {
            //     return this.getBodyOnly(lines, i);
            // }

            if (nextLine && (type === this.ORIG_UNKNOWN) && (this.ORIG_INTRO_RE.test(testLine) || this.ORIG_INTRO_DE_RE.test(testLine)) && nextLine.match(/\w+:$/)) {
                testLine = [testLine, nextLine].join(" ");
                type = this._getLineType(testLine);
                isMerged = true;
            }

            // LINE sometimes used as delimiter; if HEADER follows, lump it in with them
            if (type === this.ORIG_LINE) {
                let j = i + 1;
                nextLine = lines[j];
                while (!this._NON_WHITESPACE.test(nextLine) && j < lines.length) {
                    nextLine = lines[++j];
                }
                let nextType = nextLine && this._getLineType(nextLine);
                if (nextType === this.ORIG_HEADER) {
                    type = this.ORIG_HEADER;
                }
                else {
                    type = this.ORIG_UNKNOWN;
                }
            }

            // see if we're switching to a new type; if so, package up what we have so far
            if (curType) {
                if (curType !== type) {
                    results.push({type:curType, block:curBlock});
                    unknownBlock = (curType === this.ORIG_UNKNOWN) ? curBlock : unknownBlock;
                    count[curType] = count[curType] ? count[curType] + 1 : 1;
                    curBlock = [];
                    curType = type;
                }
            }
            else {
                curType = type;
            }

            if (isMerged && (type === this.ORIG_WROTE_WEAK || type === this.ORIG_WROTE_STRONG)) {
                curBlock.push(line);
                curBlock.push(nextLine);
                i++;
                isMerged = false;
            }
            else {
                curBlock.push(line);
            }
        }

        // Handle remaining content
        if (curBlock.length) {
            results.push({type:curType, block:curBlock});
            unknownBlock = (curType === this.ORIG_UNKNOWN) ? curBlock : unknownBlock;
            count[curType] = count[curType] ? count[curType] + 1 : 1;
        }

        // Now it's time to analyze all these blocks that we've classified

        // Check for UNKNOWN followed by HEADER
        let first = results[0], second = results[1];
        if (first && first.type === this.ORIG_UNKNOWN && second && (second.type === this.ORIG_HEADER || second.type === this.ORIG_WROTE_STRONG)) {
            let originalText = this._getTextFromBlock(first.block);
            if (originalText) {
                let third = results[2];
                if (third && third.type === this.ORIG_UNKNOWN) {
                    let originalThirdText = this._getTextFromBlock(third.block);
                    if (originalThirdText && originalThirdText.indexOf(this.NOTES_SEPARATOR) !== -1) {
                        return originalText + originalThirdText;
                    }
                }
                return originalText;
            }
        }

        // check for special case of WROTE preceded by UNKNOWN, followed by mix of UNKNOWN and QUOTED (inline reply)
        let originalText = this._checkInlineWrote(count, results);
        if (originalText) {
            return originalText;
        }

        // If we found quoted content and there's exactly one UNKNOWN block, return it.
        if (count[this.ORIG_UNKNOWN] === 1 && count[this.ORIG_QUOTED] > 0) {
            originalText = this._getTextFromBlock(unknownBlock);
            if (originalText) {
                return originalText;
            }
        }

        // If we have a STRONG separator (eg "--- Original Message ---"), consider it authoritative and return the text that precedes it
        if (count[this.ORIG_SEP_STRONG] > 0) {
            let block = [];
            for (let i = 0; i < results.length; i++) {
                let result = results[i];
                if (result.type === this.ORIG_SEP_STRONG) {
                    break;
                }
                block = block.concat(result.block);
            }
            originalText = this._getTextFromBlock(block);
            if (originalText) {
                return originalText;
            }
        }

        return text;
    }

    static hasExternalImages(content: string): boolean {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, "text/html");
        const images = htmlDoc.getElementsByTagName("img");
        let isExternalImage = false;
        for (let i=0;i<images.length;i++) {
            if (images[i].src.indexOf("/api/getAttachment") === -1 &&
                (images[i].src.toLowerCase().startsWith("http") || images[i].src.toLowerCase().startsWith("https"))
            ) {
                isExternalImage = true;
                break;
            }
        }
        return isExternalImage;
    }

    static getContentType(mp): string {
        let contentType = "text/html";
        const bodyParts = this.getBodyParts(mp, []);
        if (!!bodyParts && bodyParts.length > 0) {
            for (let i = 0 ; i < bodyParts.length ; i++) {
                if (bodyParts[i].ct && bodyParts[i].ct === "multipart/alternative" && bodyParts[i].mp) {
                    bodyParts[i].mp.map(c => {
                        if (c.body && c.content && c.ct) {
                            contentType = c.ct;
                        }
                    });
                    break;
                } else if (bodyParts[i].body && bodyParts[i].content && bodyParts[i].ct) {
                    contentType = bodyParts[i].ct;
                }
            }
        }
        return contentType;
    }

    static getBodyParts(mp, bodyParts) {
        if (Array.isArray(mp)) {
            mp.forEach(item => {
                bodyParts.push(item);
                if (item.mp) {
                    this.getBodyParts(item.mp, bodyParts);
                }
            });
        } else if (!!mp) {
            if (mp.mp) {
                this.getBodyParts(mp.mp, bodyParts);
            }
        }
        return bodyParts;
    }

    static _getTextFromBlock(block) {
        if (!(block && block.length)) { return null; }
        var originalText = block.join("\n") + "\n";
        originalText = originalText.replace(/\s+$/, "\n");
        return (this._NON_WHITESPACE.test(originalText)) ? originalText : null;
    }
    static _getOriginalHtmlContent(text) {
        let htmlNode = document.createElement("div");
        htmlNode.innerHTML = text;
        while (this.SCRIPT_REGEX.test(text)) {
            text = text.replace(this.SCRIPT_REGEX, "");
        }
        let done = false, nodeList = [];
        this._flatten(htmlNode, nodeList);
        let ln = nodeList.length, i, results = [], count = {}, el, prevEl, nodeName, type, prevType, sepNode;
        for (i = 0; i < ln; i++) {
            el = nodeList[i];
            if (el.nodeType === 1) {
                el.normalize();
            }
            nodeName = el.nodeName.toLowerCase();
            type = this._checkNode(nodeList[i]);
            if (type === this.ORIG_UNKNOWN && el.nodeName === "#text" &&
                (this.ORIG_DATE_RE.test(el.nodeValue) || this.ORIG_INTRO_RE.test(el.nodeValue)  || this.ORIG_INTRO_DE_RE.test(el.nodeValue))) {
                let str = el.nodeValue;
                for (let j = 1; j < 10; j++) {
                    const el1 = nodeList[i + j];
                    if (el1 && el1.nodeName === "#text") {
                        str += el1.nodeValue;
                        if (/:$/.test(str)) {
                            type = this._getLineType(this.trim(str));
                            if (type === this.ORIG_WROTE_STRONG) {
                                i = i + j;
                                break;
                            }
                        }
                    }
                }
            }
            if (type !== null) {
                results.push({ type: type, node: el, nodeName: nodeName });
                count[type] = count[type] ? count[type] + 1 : 1;
                if (type === this.ORIG_SEP_STRONG || type === this.ORIG_WROTE_STRONG) {
                    sepNode = el;
                    done = true;
                    break;
                }
                if (type === this.ORIG_HEADER && prevType === this.ORIG_LINE) {
                    sepNode = prevEl;
                    done = true;
                    break;
                }
                prevEl = el;
                prevType = type;
            }
        }
        if (sepNode) {
            this._prune(sepNode, true);
        }
        const result = done && htmlNode.textContent ? htmlNode.innerHTML : text;
        return result;
    }

    static _prune(node, clipNode) {
        const p = node && node.parentNode;
        while (p && p.lastChild && p.lastChild !== node) {
            p.removeChild(p.lastChild);
        }
        if (clipNode && p && p.lastChild === node) {
            p.removeChild(p.lastChild);
        }
        const nodeName = p && p.nodeName.toLowerCase();
        if (p && nodeName !== "body" && nodeName !== "html") {
            this._prune(p, false);
        }
    }
    static _getLineType(testLine) {
        let type = this.ORIG_UNKNOWN;
        for (let j = 0; j < this.MSG_REGEXES.length; j++) {
            const msgTest = this.MSG_REGEXES[j];
            const regex = msgTest.regex;
            if (regex.test(testLine.toLowerCase())) {
                if (msgTest.type == this.ORIG_QUOTED && /^\s*\|.*\|\s*$/.test(testLine)) {
                    continue;
                }
                type = msgTest.type;
                break;
            }
        }
        if (type === this.ORIG_UNKNOWN) {
            const m = testLine.match(/(\w+):$/);
            const verb = m && m[1] && m[1].toLowerCase();
            if (verb) {
                let points = 0;
                points = points ? 5 : (verb === "changed") ? 0 : 2;
                if (this.ORIG_EMAIL_RE.test(testLine)) {
                    points += 4;
                }
                if (this.ORIG_DATE_RE.test(testLine)) {
                    points += 3;
                }
                if (points >= 7) {
                    type = this.ORIG_WROTE_STRONG;
                }
                if (points >= 5) {
                    type = this.ORIG_WROTE_WEAK;
                }
            }
        }
        return type;
    }

    static trim(str, compress?, space?) {
        if (!str) { return ""; }
        let trim_re = this.TRIM_RE;
        let compress_re = this.COMPRESS_RE;
        if (space) {
            trim_re = new RegExp("^" + space + "+|" + space + "+$", "g");
            compress_re = new RegExp(space + "+", "g");
        } else {
            space = " ";
        }
        str = str.replace(trim_re, "");
        if (compress) {
            str = str.replace(compress_re, space);
        }
        return str;
    }

    /**
     * A "... wrote:" separator is not quite as authoritative, since the user might be replying inline. If we have
     * a single UNKNOWN block before the WROTE separator, return it unless there is a mix of QUOTED and UNKNOWN
     * following the separator, except if there's only a single unknown block after the separator and it comes last.
     *
     * @private
     */
    static _checkInlineWrote (count, results) {
        if (count[this.ORIG_WROTE_STRONG] > 0) {
            let unknownBlock, foundSep = false, afterSep = {};
            for (let i = 0; i < results.length; i++) {
                let result = results[i], type = result.type;
                if (type === this.ORIG_WROTE_STRONG) {
                    foundSep = true;
                }
                else if (type === this.ORIG_UNKNOWN && !foundSep) {
                    if (unknownBlock) {
                        return null;
                    }
                    else {
                        unknownBlock = result.block;
                    }
                }
                else if (foundSep) {
                    afterSep[type] = true;
                }
            }

            let mixed = (afterSep[this.ORIG_UNKNOWN] && afterSep[this.ORIG_QUOTED]);
            const endsWithUnknown = (count[this.ORIG_UNKNOWN] === 2 && results[results.length - 1].type === this.ORIG_UNKNOWN);
            if (unknownBlock && (!mixed || endsWithUnknown)) {
                const originalText = this._getTextFromBlock(unknownBlock);
                if (originalText) {
                    return originalText;
                }
            }
        }
    }
    static _checkNode(el) {
        if (!el) { return null; }
        const nodeName = el.nodeName.toLowerCase();
        let type = null;
        if (nodeName === "#text") {
            const content = this.trim(el.nodeValue);
            if (this._NON_WHITESPACE.test(content)) {
                type = this._getLineType(content);
            }
        } else if (nodeName === "hr") {
            if (el.id === this.HTML_SEP_ID || (el.size === "2" && el.width === "100%" && el.align === "center")) {
                type = this.ORIG_SEP_STRONG;
            } else {
                type = this.ORIG_LINE;
            }
        } else if (nodeName === "pre") {
            type = this._checkNodeContent(el);
        } else if (nodeName === "div") {
            if (el.className === "OutlookMessageHeader" || el.className === "gmail_quote") {
                type = this.ORIG_SEP_STRONG;
            }
            type = type || this._checkNodeContent(el);
        } else if (nodeName === "span") {
            type = type || this._checkNodeContent(el);
        } else if (nodeName === "img") {
            type = this.ORIG_UNKNOWN;
        } else if (nodeName === "blockquote") {
            type = this.ORIG_QUOTED;
        }
        return type;
    }

    static _checkNodeContent(node) {
        const content = node.textContent || "";
        if (!this._NON_WHITESPACE.test(content) || content.length > 200) {
            return null;
        }

        const type = this._getLineType(content);
        return (type === this.ORIG_SEP_STRONG || type === this.ORIG_WROTE_STRONG) ? type : null;
    }

    static _flatten(node, list) {
        const nodeName = node && node.nodeName.toLowerCase();
        if (this.IGNORE_NODE[nodeName]) {
            return;
        }
        list.push(node);
        const children = node.childNodes || [];
        for (let i = 0; i < children.length; i++) {
            this._flatten(children[i], list);
        }
    }

    static renderEmoji(text: string): string {
        if (wdtEmojiBundle && wdtEmojiBundle.emoji) {
            wdtEmojiBundle.emoji.replace_mode = "unified";
            wdtEmojiBundle.emoji.allow_native = true;
            return wdtEmojiBundle.emoji.replace_emoticons(text);
        }
        return text;
    }

    static isAttachmentExceedLimit(filesSize: number, limitToUpload: number): boolean {
        if (filesSize > limitToUpload) {
            return true;
        }
        return false;
    }

    static getAttachmentIds(attachmentList: any[]): string[] {
        const attchmentIds: string[] = [];
        attachmentList.forEach(attachment => {
            attchmentIds.push(attachment.part);
        });
        return attchmentIds;
    }
}
