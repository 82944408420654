/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Store } from "@ngrx/store";
import { filter, take, takeUntil } from "rxjs/operators";
import { getOnlineStatus, getUserProfile, RootState } from "src/app/reducers";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { UntypedFormControl, Validators } from "@angular/forms";
import * as moment from "moment";
import { MetaComment } from "../../common/models/comment.model";
import { AppRepository } from "../../repositories/app.repository";
import { SearchItem } from "src/app/common/models/mail-models/search-item";

@Component({
  selector: "vp-doc-replies",
  templateUrl: "./doc-replies.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocRepliesComponent implements OnDestroy, OnInit {
  @Input() document: SearchItem;
  showMore: boolean = false;
  limitedLength = 500;
  userFullName$: any;
  authorName: string;
  comments: MetaComment[];
  commentControl: UntypedFormControl;
  @ViewChild("commentInput") commentInput: ElementRef;
  commentToReply: any;
  parentComment: any;
  lang: string = "en";
  // isCollapsed = true;
  isReplyCollapsed = {};
  currentUser: any;
  private isAlive$ = new Subject<boolean>();

  _isCollapsed: boolean = false;

  @Input() set isCollapsed(isCollapsed: boolean) {
    this._isCollapsed = isCollapsed;

    if (!isCollapsed && !this.comments) {
      this.getComments();
    }
  }

  get isCollapsed() {
    return this._isCollapsed;
  }

  @Output() commentsCount = new EventEmitter<number>();

  constructor(
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<RootState>,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private appRepository: AppRepository,
  ) {
    this.commentControl = new UntypedFormControl("", [Validators.required]);
  }

  ngOnInit() {
    this.appRepository.getLanguage().pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
      this.lang = lang;
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(profile => {
      this.currentUser = profile;
      this.changeDetectorRef.markForCheck();
    });
  }

  getComments() {
    const param = {
      object_id: this.document.objectId,
      object_type: this.document.type,
      product: this.document.type,
    };
    this.store.select(getOnlineStatus).pipe(filter(v => !!v), take(1)).subscribe(v => {
      this.appRepository.getComments(param).subscribe(comments => {

        this.comments = comments;
        this.commentsCount.emit(comments.length);
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  reply(comment, parent) {

    this.commentToReply = comment;
    this.parentComment = parent;
    this.commentInput.nativeElement.focus();
  }

  handleEnterOnMessage($event: KeyboardEvent) {
    if (!this.commentControl.value || this.commentControl.value.trim() === "") {
      return;
    }

    if (!$event) {
      $event = window.event as KeyboardEvent; // Fix for mozilla
    }
    let isMac = navigator.platform.toUpperCase().indexOf("MAC") !== -1;
    if ((!isMac && $event.ctrlKey) || (isMac && $event.metaKey)) {
      $event.preventDefault();
      this.addComment();
    } else {
      $event.preventDefault();
      this.addComment();
    }
  }

  displayTime(timestamp, type?: string, dateFormat?: string): string {
    let time = "";
    let format = "LT";
    if (type !== "today") {
      if (this.lang === "de") {
        format = "DD.MM.YYYY LT";
      } else {
        format = "DD/MM/YYYY LT";
      }
    }
    if (dateFormat === "short") {
      const currentYear = new Date().getFullYear();
      const convYear = new Date(timestamp).getFullYear();
      format = "MMM DD";
      if (type === "today") {
        format = "LT";
      } else if (convYear !== currentYear) {
        format = "MMM DD, YYYY";
      }
    }
    time = moment(timestamp).locale(this.lang).format(format);
    return time;
  }

  getHTML(text) {
    return text.replace(/\n/g, "<br>");
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  private addComment() {
    const param = {
      object_id: this.document.objectId,
      object_type: this.document.type,
      product: this.document.type,
    };

    this.appRepository.addComment(param, this.commentControl.value, this.commentToReply ? this.commentToReply.id : null)
      .subscribe(comment => {

        this.getComments();
        this.parentComment = null;
        this.commentToReply = null;
        this.changeDetectorRef.markForCheck();
      });
    this.commentToReply = null;
    this.commentControl.patchValue("");
  }
}
