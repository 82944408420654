
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contacts-list-component">
    <div class="contacts-heading">
        {{ tabs[selectedIndex] | translate }}
    </div>
    <mat-tab-group disablePagination="true" [selectedIndex]="selectedIndex"
        (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="searchForm"></ng-container>
                <ng-container *ngFor="let group of groups">
                    <ng-container *ngTemplateOutlet="groupsTemplate;context:{group: group, contactList: groupContacts[group.id].all, action: startConversation}">
                    </ng-container>
                </ng-container>
            </ng-template>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon fontSet="mdi" fontIcon="mdi-account"></mat-icon>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="searchForm"></ng-container>
                <ng-container *ngFor="let group of groups">
                    <ng-container *ngTemplateOutlet="groupsTemplate;context:{group: group, contactList: groupContacts[group.id].online, action: startConversation}">
                    </ng-container>
                </ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon fontSet="mdi" fontIcon="mdi-star"></mat-icon>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="searchForm"></ng-container>
                <ng-container *ngTemplateOutlet="contactsTemplate;context:{contacts: favoriteContacts, action: startConversation}">
                </ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon fontSet="mdi" fontIcon="mdi-block-helper"></mat-icon>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="searchForm"></ng-container>
                <ng-container *ngTemplateOutlet="contactsTemplate;context:{contacts: blockedContacts, action: startConversation}">
                </ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>


</div>

<ng-template #searchForm>
    <div class="search-form">
        <input type="text" name="search" placeholder="{{ 'SEARCH' | translate }}" [formControl]="searchControl"
            id="searchText">
        <mat-icon class="search-user-icon" *ngIf="!searchControl.value">search</mat-icon>
        <mat-icon class="search-user-icon clear-search" (click)="resetForm()" *ngIf="searchControl.value">close</mat-icon>
    </div>
</ng-template>

<ng-template #contactsTemplate let-contactsList="contacts" let-action="action">
    <div class="contacts-list" *ngIf="contactsList">
        <ul class="all-contacts">
            <li *ngFor="let contact of contactsList" (click)="action(contact)">
                <div class="contact-avatar">
                    <vp-avatar [user]="contact" type='user'></vp-avatar>
                </div>
                <div class="contact-name">{{ contact.name }}</div>
                <mat-icon *ngIf="favoriteIds.includes(contact.defaultMail)" class="favorite-status" fontSet="mdi" fontIcon="mdi-star"></mat-icon>
            </li>
        </ul>
    </div>
</ng-template>
<ng-template #groupsTemplate let-group="group" let-contactList="contactList">
    <div class="contacts-list" *ngIf="contactList.length">
        <div class="group-info">
            <div class="group-name">{{ group.name || ('GENERAL' | translate) }}</div>
            <div class="group-contacts">
                {{ groupContacts[group.id].online.length }}/{{ groupContacts[group.id].all.length }}</div>
            <mat-icon *ngIf="!hideList[group.id]" class="mat-18 expand-list-icon" fontSet="mdi"
                fontIcon="mdi-chevron-up" (click)="hideList[group.id]=true"></mat-icon>
            <mat-icon *ngIf="hideList[group.id]" class="mat-18 expand-list-icon" fontSet="mdi"
                fontIcon="mdi-chevron-down" (click)="hideList[group.id]=false"></mat-icon>
        </div>
        <ul class="all-contacts" *ngIf="!hideList[group.id]">
            <li *ngFor="let contact of contactList" (click)="startConversation(contact)">
                <div class="contact-avatar">
                    <vp-avatar [user]="contact" type='user'></vp-avatar>
                    <mat-icon *ngIf="onlineContactsBareIds.includes(contact.defaultMail)" class="online-status" fontSet="mdi" fontIcon="mdi-checkbox-blank-circle"></mat-icon>
                </div>
                <div class="contact-name">{{ contact.name }}</div>
                <mat-icon *ngIf="favoriteIds.includes(contact.defaultMail)" class="favorite-status" fontSet="mdi" fontIcon="mdi-star"></mat-icon>

            </li>
        </ul>
    </div>
</ng-template>