/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Injectable } from "@angular/core";
import { App, BrowserWindow, DesktopCapturer, IpcRenderer, Shell } from "electron";
import { Subject } from "rxjs";

@Injectable()
export class ElectronService {
  app: App;
  remote: any;
  shell: Shell;
  win: BrowserWindow;
  ipcRenderer: IpcRenderer;
  desktopCapturer: DesktopCapturer;
  store: any;
  onFocused$ = new Subject<any>();
  onBlurred$ = new Subject<any>();
  static downloadFile(url: string, fileName?: string) {
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
  }

  constructor() {
    if (this.isElectron) {
      this.remote = window.require("electron").remote;
      this.ipcRenderer = window.require("electron").ipcRenderer;
      this.desktopCapturer = window.require("electron").desktopCapturer;
      this.shell = window.require("electron").shell;
      this.app = this.remote.app;
      this.win = this.remote.getCurrentWindow();
      this.md5 = window.require("blueimp-md5");
      const Store = window.require("electron-store");
      this.store = new Store();

      // https://electronjs.org/docs/api/web-contents#contentssendchannel-arg1-arg2-
      this.ipcRenderer.on("app_cycle", (event, message) => {

        if (message === "focus") {

          this.onFocused$.next(new Date().getSeconds());
        } else if (message === "blur") {

          this.onBlurred$.next(new Date().getSeconds());
        }
      });
    }
  }

  get isElectron(): boolean {
    return window && window.process && window.process.type;
  }

  get isWindows(): boolean {
    return window && window.process && window.process.platform === "win32";
  }

  get isMacOS(): boolean {
    return window && window.process && window.process.platform === "darwin";
  }

  get appVersion(): string {
    return this.app.getVersion();
  }

  setBadge(count: number): void {

    if (this.isWindows) {
      this.ipcRenderer.sendSync("update-badge", count ? count : null);
    } else {
      this.app.setBadgeCount(count);

      if (this.isMacOS && count) {
        this.app.dock.bounce();
      }
    }
  }

  openExternalUrl(url: string): void {
    this.shell.openExternal(url);
  }

  showApp(): void {
    this.win.show();
  }

  md5(str: string): string {
    return this.md5(str);
  }

  setToStorage(key: string, value: any): void {
    this.store.set(key, value);
  }

  getFromStorage(key: string): any {
    return this.store.get(key);
  }

  deleteFromStorage(key: string): void {
    this.store.delete(key);
  }

  clearStorage(): void {
    this.store.clear();
  }
}
