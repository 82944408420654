/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ElementRef, HostBinding, Input, OnInit } from "@angular/core";
import { CommonUtils } from "src/app/common/utils/common-util";


@Component({
  selector: "vp-emoji",
  templateUrl: "./emoji.html"
})
export class VNCEmojiComponent implements OnInit {
  @Input() chatWindow: ElementRef;
  @Input() inputSelector: string;

  @HostBinding("class") emojiPopup = "wdt-emoji-popup";

  constructor() {
  }

  ngOnInit() {
    if (!CommonUtils.isOnMobileDevice()) {
      setTimeout(() => {
        wdtEmojiBundle.init(this.inputSelector, this.chatWindow);
        setTimeout(() => {
          if (document.querySelector(".wdt-emoji-picker") !== null) {
            document.querySelector(".wdt-emoji-picker").setAttribute("title", "Emoji");
            document.querySelector(".wdt-emoji-picker").innerHTML = "mood";
            document.querySelector(".wdt-emoji-picker").classList.add("material-icons");
          }
        }, 2000);
      }, 500);
    }
  }

}
