/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { filter, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AppRepository } from "../../repositories/app.repository";
// import { ChatPreviewComponent } from "../../chat-preview/chat-preview.component";
import { AppService } from "src/app/services/app.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { Subject } from "rxjs";
// import { ForwardMessageComponent } from "../../forward-message/forward-message.component";
import { SearchItem } from "src/app/common/models/mail-models/search-item";

@Component({
  selector: "vp-doc-talk-preview",
  templateUrl: "./doc-talk-preview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocTalkPreviewComponent implements OnChanges, OnDestroy, OnInit {
  @Input() document: SearchItem;
  showMore: boolean = false;
  limitedLength = 500;
  private isAlive$ = new Subject<boolean>();
  userFullName$: any;
  authorName: string;
  documentTitle: string;
  mailURL: string;
  serverURL: string;
  isLoading: boolean = false;
  showReplies: boolean = false;
  commentsCount: number;
  hasMoreLines: boolean = false;

  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private appRepository: AppRepository,
    private broadcaster: Broadcaster,
    private appService: AppService) {
  }

  ngOnInit() {
    this.appRepository.getFederatedApps().pipe(take(1)).subscribe(apps => {

      const mail = apps.find(app => app.appKey === "mail");
      if (!!mail) {
          this.mailURL = mail.appUrl;
          const isCordovaOrElectron = environment.isCordova || environment.isElectron;
          if (this.mailURL.lastIndexOf("/") === this.mailURL.length - 1) {
              this.serverURL = this.mailURL;
          } else {
              this.serverURL = this.mailURL + "/";
          }
          this.changeDetectionRef.markForCheck();
      }
    });
    this.broadcaster.on<any>("changestarvalue").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.changeDetectionRef.markForCheck();
    });

    this.appRepository.getHideAllComments().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      if (!!val) {
        this.showReplies = false;
      }
      this.changeDetectionRef.markForCheck();
    });
    this.hasMoreLines = (this.document.parsedContent.match(/<div class="one-message">/g) || []).length > 3;
  }

  ngOnChanges() {
    if (this.document) {
      // this.authorName = this.document.from;
      if (!this.userFullName$) {
        this.userFullName$ = this.appRepository.getContactByEmail(this.document.from)
        .pipe(filter(v => !!v), take(1)).subscribe(res => {
          if (res.name) {
            this.authorName = res.name;
            this.changeDetectionRef.markForCheck();
          }
        });
      }
      // console.log("[doc-talk-preview][onChanges] ", this.document);
      this.documentTitle = this.document.title;
      if (this.document.title.includes("@conference")) {
        this.documentTitle = this.getTitle(this.document.title);
        // const titleMinusProvider = this.document.title.split("@conference")[0];
        // const titleMinusTalk = titleMinusProvider.split("_talk")[0];
        // const titleMinusTalkToArray = titleMinusTalk.split("_");
        // titleMinusTalkToArray.pop(); // Removes Unique Id
        // this.documentTitle = titleMinusTalkToArray.join(" ");
      }
    }
  }

  getTitle(title) {
    const titleMinusProvider = title.split("@conference")[0];
    let titleNameArray = titleMinusProvider.split("__");
    let titleName: string;
    const lastIndex = titleNameArray.length - 1;
    const lastName = titleNameArray[lastIndex].split("_")[0];
    titleNameArray = [...titleNameArray].filter((a, index) => index !== lastIndex);
    titleNameArray.push(lastName);
    titleName = titleNameArray.join(" ");
    return titleName;
  }

  forwardMessage() {
    const id = this.document.id.replace("." + this.document.owner, "")
    .replace(`talk.${this.document.talkId}.`, "").replace(`talk.`, "");
    const target = this.document.chatType === "chat" ? this.document.to[0] : this.document.title;
    this.appRepository.getAroundMessages({id, target, rows: 4}).subscribe(async (messages) => {

      const message = messages.find(v => v.id === id);
      if (message) {
        const { ForwardMessageComponent } = await import(
          /* webpackPrefetch: true */
          "../../forward-message/forward-message.component"
          );
        const dialogRef = this.matDialog.open(ForwardMessageComponent, {
          width: "340px",
          height: "450px",
          autoFocus: true,
          panelClass: "commander__dialog",
          data: message
        });
        dialogRef.afterClosed().subscribe((res: any) => {

          if (!!res && res.target) {
            this.startConversation(res.target);
          }
        });
      }
    });
  }

  replyMessage() {
    const id = this.document.id.replace("." + this.document.owner, "")
    .replace(`talk.${this.document.talkId}.`, "").replace(`talk.`, "");
    const target = this.document.chatType === "chat" ? this.document.to[0] : this.document.title;
    this.appRepository.getAroundMessages({id, target, rows: 4}).subscribe(messages => {

      const message = messages.find(v => v.id === id);
      if (message) {
        this.appRepository.setMessageToReply(message);
        this.openQuickPreview();
      }
    });
  }

  ngOnDestroy() {
    if (this.userFullName$ && this.userFullName$.destroy) {
      this.userFullName$.destroy();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  copyToClipboard() {
    CommonUtils.copyToClipboard([this.document.parsedContent]);
    this.translate.get("COPIED_TO_CLIPBOARD").pipe(take(1)).subscribe(text => {
      this.snackBar.open(text, "", {duration: 2000});
    });
  }

  addStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = true;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  removeStar(doc) {
    if (doc.type === "mail") {
      const body = { id: doc.id, op: "!flag" };
      this.appService.messageAction(body).subscribe(res => {
        doc.metadata["isStarred"] = false;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  openQuickPreview() {
    if (this.document.chatType === "groupchat") {
      this.startConversation(this.document.title);
    } else {
      if (this.document.from !== this.appRepository.currentUser.defaultMail) {
        this.startConversation(this.document.from);
      } else {
        this.startConversation(this.document.to[0]);
      }
    }
  }

  async startConversation(target) {
    const { ChatPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../../chat-preview/chat-preview.component"
      );
    const dialogRef = this.matDialog.open(ChatPreviewComponent, {
      width: "660px",
      height: "625px",
      autoFocus: true,
      panelClass: "commander__dialog",
      data: target
    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });
  }

  renderContent(content) {
    return wdtEmojiBundle.render(content).replace(/\r?\n/g, "\\n")
    .replace(/\\n/g, "<br />").replace(/[<]br[^>]*[>]/gi, "");
  }

  // hasMoreLines() {
  //   return this.document && this.document.rawTxt.split(/\r?\n/g).length > 5;
  // }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  setCommentsCount(count: number) {
    this.commentsCount = count;
    this.changeDetectionRef.markForCheck();
  }

  toggleShowReplies() {
    this.showReplies = !this.showReplies;

    if (this.showReplies) {
      this.appRepository.setHideAllComments(false);
    }
    this.changeDetectionRef.markForCheck();
  }

  showReplyForm() {

    this.showReplies = true;
    this.changeDetectionRef.markForCheck();
  }
}
