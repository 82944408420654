
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AppRepository } from "../repositories/app.repository";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { getBlockedBareIds, getContacts, getOnlineContactsBareId, RootState } from "../reducers";
// import * as _ from "lodash";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { CommanderConstants } from "../common/utils/commander.constants";
// import { ChatPreviewComponent } from "../chat-preview/chat-preview.component";

@Component({
  selector: "vp-contacts-list",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./contacts-list.component.html"
})
export class ContactListComponent implements OnInit, OnDestroy {
  searchControl = new UntypedFormControl("", []);
  private isAlive$ = new Subject<boolean>();
  blockedBareIds = [];
  allContacts = [];
  onlineContactsBareIds = [];
  blockedContacts = [];
  onlineContacts = [];
  groups: any;
  favoriteGroup: any;
  selectedIndex = 1;
  tabs = ["USERS", "ONLINE_USERS", "FAVORITES", "BLOCKED_USERS"];
  favoriteContacts = [];
  contacts = [];
  onlineGroups: { id: number; name?: string}[] = [];
  groupContacts = {};
  hideList = {};
  favoriteIds: any[];
  constructor(
    private store: Store<RootState>,
    private changeDetectorRef: ChangeDetectorRef,
    private appRepository: AppRepository,
    private matDialog: MatDialog) { }

  ngOnInit() {
    setTimeout(() => {
      this.searchControl.valueChanges
      .pipe(
        takeUntil(this.isAlive$)
        , debounceTime(500))
      .subscribe((contacts: any[]) => {
        this.filterContacts();
      });
    }, 1000);

    const contacts$ = this.store.select(getContacts);
    const onlineContacts$ = this.store.select(getOnlineContactsBareId);
    combineLatest([contacts$ , onlineContacts$, this.store.select(getBlockedBareIds)]).pipe(
      takeUntil(this.isAlive$))
      .subscribe(([contacts , onlineContactsBareIds, bareIds ]) => {
        
        this.blockedBareIds = bareIds;
        this.allContacts = orderBy(contacts, ["name"] , ["asc"]);
        this.onlineContactsBareIds = onlineContactsBareIds;

        this.filterContacts();
    });
  }

  filterContacts() {
    const contacts = this.allContacts.filter(v => !this.searchControl.value
      || v.name.toLowerCase().includes(this.searchControl.value.toLowerCase()));
    let groups = [];
    contacts.map(v => v.groups).forEach(v => {
      if (v) {
        groups = [...groups, ...v];
      }
    });
    this.groups =  uniqBy(groups.filter((group: any) => group.name && group.name !== CommanderConstants.FAVOURITE_LIST_NAME), "id");
    this.groups.sort((a , b) => {
        const groupA = a.name.toLowerCase();
        const groupB = b.name.toLowerCase();
        return groupA < groupB ? -1 : groupA > groupB ? 1 : 0;
    });
    this.groups.unshift({id: 0});

    this.contacts = contacts;
    const groupContacts = {};
    const onlineContacts = contacts.filter(v => this.onlineContactsBareIds.includes(v.defaultMail));
    this.groups.forEach(group => {
      groupContacts[group.id] = groupContacts[group.id] || {};
      groupContacts[group.id].online = onlineContacts.filter(v => v.groups && v.groups.find(g => g.id === group.id));
      groupContacts[group.id].all = contacts.filter(v => v.groups && v.groups.find(g => g.id === group.id));
    });
    this.groupContacts = groupContacts;
    this.favoriteContacts = contacts.filter(v => v.groups && v.groups.find(g => g.name === CommanderConstants.FAVOURITE_LIST_NAME));
    this.favoriteIds = this.favoriteContacts.map(v => v.defaultMail);
    this.blockedContacts = contacts.filter(v => this.blockedBareIds.includes(v.defaultMail));
    
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  selectedIndexChange(data) {
 
    this.searchControl.patchValue("");
    this.hideList = {};
    this.selectedIndex = data;
    this.changeDetectorRef.markForCheck();
  }

  async startConversation(contact) {
  
    const { ChatPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../chat-preview/chat-preview.component"
      );
    const dialogRef = this.matDialog.open(ChatPreviewComponent, {
      width: "80%",
      height: "600px",
      autoFocus: true,
      panelClass: "commander__dialog",
      data: contact.defaultMail
    });
    dialogRef.afterClosed().subscribe((res: any) => {
 
    });
  }

  resetForm() {
    this.searchControl.patchValue("");
  }
}
