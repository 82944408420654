/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from "@angular/core";
import { throttleable } from "../../common/utils/throttle";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";

@Directive({
    selector: "[vpFileDragDrop]"
})
export class DragDropDirective implements AfterViewInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();

    @Output() onFileDropped = new EventEmitter<any>();
    @Input() placeholder: string = "";
    constructor(private renderer: Renderer2, private hostElement: ElementRef, private broadcaster: Broadcaster) {
        renderer.addClass(hostElement.nativeElement, "vp-file-drag-drop");
        this.broadcaster.on<any>("onDragOver").pipe(takeUntil(this.isAlive$)).subscribe(val => {
            renderer.addClass(hostElement.nativeElement, "has-files-drag-over");
        });
        this.broadcaster.on<any>("onDrop").pipe(takeUntil(this.isAlive$)).subscribe(val => {
            renderer.removeClass(hostElement.nativeElement, "on-dragover");
            renderer.removeClass(hostElement.nativeElement, "has-files-drag-over");
        });
    }

    ngAfterViewInit(): void {
        const div = document.createElement("div");
        div.className = "drag-drop-placeholder disable-select";
        div.innerHTML = `<mat-icon class="mdi-18px mat-icon mdi mdi-content-copy mat-icon-no-color"
        role="img" aria-hidden="true"></mat-icon>
        ${this.placeholder}`;
        this.renderer.appendChild(this.hostElement.nativeElement, div);
    }

    @HostListener("dragover", ["$event"])
    onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.addClass(this.hostElement.nativeElement, "on-dragover");
       
        return false;
    }

    @HostListener("dragleave", ["$event"])
    @throttleable(2000)
    onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.removeClass(this.hostElement.nativeElement, "on-dragover");
        this.renderer.removeClass(this.hostElement.nativeElement, "has-files-drag-over");
       
        return false;
    }

    @HostListener("drop", ["$event"])
    onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.removeClass(this.hostElement.nativeElement, "on-dragover");
        this.renderer.removeClass(this.hostElement.nativeElement, "has-files-drag-over");
        let files = event.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }
        
        return false;
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }
}
