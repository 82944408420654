/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { CommonUtils } from "src/app/common/utils/common-util";

@Component({
  selector: "vp-mobile-settings-dialog",
  templateUrl: "./mobile-settings-dialog.component.html"
})
export class MobileSettingsDialogComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  hideFAQMenu: boolean = false;
  hideServiceDeskMenu: boolean = false;
  hideLegalNoticeMenu: boolean = false;
  serviceDeskMenuItemName: string = "VNC Service Desk";
  constructor(
    public dialogRef: MatDialogRef<MobileSettingsDialogComponent>,
    private matDialog: MatDialog,
    private ngZone: NgZone,
  ) {
    // this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ABOUT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
    //   this.ngZone.run(() => {
    //     this.close();
    //   });
    // });
  }

  ngOnInit() {
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  profileDialog(): void {
    // this.matDialog.open(AboutDialogComponent, {
    //   maxWidth: "100%",
    //   autoFocus: false,
    //   panelClass: "commander__dialog"
    // });
  }

  async generalSettingsDialog() {
    const { GeneralSettingsDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/general-settings-dialog/general-settings-dialog.component"
      );
      this.matDialog.open(GeneralSettingsDialogComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "commander__dialog"
      });
  }

  async preferencesDialog() {
    const { MobilePreferencesDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/mobile-preferences-dialog/mobile-preferences-dialog.component"
      );
    this.matDialog.open(MobilePreferencesDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async helpDialog() {
    const { MobileHelpDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/mobile-help-dialog/mobile-help-dialog.component"
      );
    this.matDialog.open(MobileHelpDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }
}
