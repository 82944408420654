
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class CommanderConstants {
    static RESULTS_PER_PAGE = 50;
    static GROUP_LIMIT = 20;
    static VNCTALK_API = "/api/vnctalk";
    static VNCMAIL_API = "/api/vncmail";
    static VNCTASK_API = "/api/vnctask";
    static CONTACTS_REST_PER_PAGE = 100;
    static imagesExtensions = ["jpeg", "jpg", "gif", "png", "bmp", "tiff"];
    static audioExtensions = ["mp3", "wav", "wma", "ogg", "aac", "flac", "aiff", "amr"];
    static videoExtensions = ["mp4", "mov", "wmv", "webm", "wma", "flv", "mov", "mpg", "avi", "3gp"];
    static supportedVideoExtensions = ["mp4", "webm", "mov"];
    static DOWNLOAD_SIZE_LIMIT = 1000 * 1024 * 2;
    static FILE_SERVICE_URL = "https://phpfile.uxf.zimbra-vnc.de";
    static FAVOURITE_LIST_NAME = "favourite__";
}
