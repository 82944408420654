
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mobile_filters_dialog">
  <h3>{{ 'FILTERS' | translate }}</h3>

  <div class="mobile_filters_form">
    <div class="filter-option apps">
      <label class="filter-option__heading">{{ 'APPS_TO_INCLUDE' | translate }}</label>
      <div class="filter-option__apps">
        <mat-checkbox *ngFor="let app of availableApps" [(ngModel)]="includedApps[app.appKey]"
                      [checked]="includedApps[app.appKey]">
          {{ app.appName }}
        </mat-checkbox>
      </div>
    </div>

    <div class="filter-option">
      <label class="filter-option__heading">{{ 'GROUP_BY' | translate }}</label>
      <mat-form-field>
        <mat-select [formControl]="controls.groupBy">
          <mat-option class="group-select" value="none">
            {{ 'NONE' | translate}} </mat-option>
          <mat-option class="group-select" value="application">{{ 'APPLICATION' | translate}}
          </mat-option>
          <mat-option class="group-select" value="user">
            {{ 'USER' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-option">
      <label class="filter-option__heading">{{ 'ORDER_BY_LBL' | translate }}</label>
      <mat-form-field>
        <mat-select [formControl]="controls.orderBy">
          <mat-option class="order-select" value="created_dt desc">
            {{ 'NEWEST_FIRST' | translate}} </mat-option>
          <mat-option class="order-select" value="created_dt asc">
            {{ 'OLDEST_FIRST' | translate}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-option">
      <label class="filter-option__heading">{{ 'VIEW' | translate }}</label>
      <mat-form-field>
        <mat-select [formControl]="controls.viewBy">
          <mat-option class="order-select" value="condense">
            {{ 'CONDENSED_VIEW' | translate}} </mat-option>
          <mat-option class="order-select" value="detail">
            {{ 'DETAILED_VIEW' | translate}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-option">
      <mat-checkbox [formControl]="controls.showOnlyUnread" class="show-only-unread">
        {{ 'SHOW_UNREAD_ONLY' | translate }}
      </mat-checkbox>
    </div>

    <div class="filter-option">
      <mat-checkbox [formControl]="controls.hideAllComments" class="show-only-unread">
        {{ 'HIDE_ALL_COMMENTS' | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div class="mobile_filters_dialog__footer">
    <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
    <button mat-button (click)="applyFilters()">{{ 'APPLY' | translate }}</button>
  </div>
</div>
